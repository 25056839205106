/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {tokens} from "@fluentui/react-theme";
import {Rating, RatingSize } from '@fluentui/react';
import {AauFormField} from "../aauFormField/aauFormField";


export interface AauFormRatingProps {
    label?: string;
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    value?: number;
    validator: object;
    parentFunction?: any;
    required?: boolean | null;
    max: number;
    hint?: string;
}

const defaultProps = {
    readonly: false,
    validator: {},
    parentFunction: null,
    value: 1,
    required: false,
    max: 5,
    hint: null
} as AauFormRatingProps;

export const AauFormRating: FC<AauFormRatingProps> = props => {
    const validateField = (validateValue):void => {
        let isValid=true;

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( props.parentFunction !== null ) {
                props.parentFunction(props.itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': props.value
    });
    if (state['isValid'] === null && props.readonly === false ) {
        validateField(props.value === 0 ? 1 : 0);
    }

    return <AauFormField
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}
        hint={props.hint}
        content={
            props.readonly === true
            ? <Rating
                max={props.max}
                size={RatingSize.Large}
                defaultRating={state['content']}
                style={{
                    color: tokens.colorBrandForeground1
                }}
            /> : <Rating
                id={props.itemKey}
                key={`${props.itemKey}-ctrl`}
                max={props.max}
                size={RatingSize.Large}
                defaultRating={0}
                onChange={ (e, val) => validateField(val)}
                defaultValue={state['content']}
                style={{
                    color: tokens.colorBrandForeground1
                }}
            />
        }
    />;
};
AauFormRating.defaultProps = defaultProps;
