import React from 'react';

import {createRoot} from 'react-dom/client';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import './index.css';
import App from './App';
import Cookies from 'js-cookie';
import reportWebVitals from './reportWebVitals';

import {initializeIcons} from '@fluentui/font-icons-mdl2'; //SONAR
import {initializeFileTypeIcons} from '@fluentui/react-file-type-icons';
import {FluentProvider, createLightTheme, createDarkTheme, Theme} from '@fluentui/react-components';

import {PublicClientApplication} from "@azure/msal-browser";
import {LogLevel} from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: window.knowit.CLIENTID,
        authority: "https://login.microsoftonline.com/" + window.knowit.TENANTID,
        redirectUri: "https://" + window.knowit.BASEPATH + "/sso/microsoft",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

initializeIcons();
initializeFileTypeIcons();

const isLogged = typeof (Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null;
let profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};

const color_luminance = (hex, lum) => {
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00"+c).substr(c.length);
    }

    return rgb;
}

const brandColor = window.knowit.BRANDCOLOR;

const myBrand = {
    "10":  color_luminance(brandColor, -0.7),
    "20":  color_luminance(brandColor, -0.6),
    "30":  color_luminance(brandColor, -0.5),
    "40":  color_luminance(brandColor, -0.4),
    "50":  color_luminance(brandColor, -0.3),
    "60":  color_luminance(brandColor, -0.2),
    "70":  color_luminance(brandColor, -0.1),
    "80":  brandColor,
    "90":  brandColor,
    "100": color_luminance(brandColor, 0.1),
    "110": color_luminance(brandColor, 0.2),
    "120": color_luminance(brandColor, 0.3),
    "130": color_luminance(brandColor, 0.4),
    "140": color_luminance(brandColor, 0.5),
    "150": color_luminance(brandColor, 0.6),
    "160": color_luminance(brandColor, 0.7),
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <HelmetProvider>
        <Helmet>
            <style type='text/css'>
                {`
                    body {
                        background-color: ${(profil.hasOwnProperty('darkMode') ? profil['darkMode'] : false) === true ? '#292929' : '#FFFFFF'}
                    }
                `}
            </style>
        </Helmet>
        <FluentProvider theme={(profil.hasOwnProperty('darkMode') ? profil['darkMode'] : true) === true ? createDarkTheme(myBrand) : createLightTheme(myBrand)}>
            <App instance={new PublicClientApplication(msalConfig)}/>
        </FluentProvider>
    </HelmetProvider>
);

reportWebVitals(console.debug);
