/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import ReactPlayer from 'react-player';
import {confirmAlert} from 'react-confirm-alert';
import dayjs from 'dayjs';
import {AauMixinFilters} from '../../Components/aauMixinFilters/aauMixinFilters';
import {AauMixinFabBar} from "../../Components/aauMixinFabBar/aauMixinFabBar";


export interface AauPageTestFrontProps {
    date_format?: string;
    lang: AauToolsLang;
}

const defaultProps = {
    date_format: 'DD/MM/YYYY H:m:s',
} as AauPageTestFrontProps;

export const AauPageTestFront: FC<AauPageTestFrontProps> = props => {
    const {date_format, lang} = props;
    const {identifier} = useParams();
    const [data, setData] = useState(null);
    const [testsToDisplay, setTestsToDisplay] = useState<string>('all');

    const admin = "admin/";
    const module = 'testfront';
    const action ='viewtestfront';

    const retrieveData = async () => {
        await axios.get(
            `/rest/${admin}${module}/${action}/${identifier}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    let dateStart = null;
    let dateEnd = null;
    let d = null;

    if ( data !== null && data !== false ) {
        d = data['info']['result']['stats'];
        dateStart = new Date(d['start']);
        dateEnd = new Date(d['end']);
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    const handleClickViewError = (type, url) => {
        let content = null;
        if ( type === 'img' ) {
            content = <img alt={url} src={'https://eptestsresult.z16.web.core.windows.net/front/' + url} />;
        } else {
            content = <ReactPlayer width={1000} height={600} url={'https://eptestsresult.z16.web.core.windows.net/front/' + url} playing />;
        }

        confirmAlert({
            title: `${window.knowit.SITENAME}`,
            message: lang['formErrorFix'],
            customUI: ({onClose}) => {
                const closeHandler = () => {
                    onClose();
                };
                return (
                    <div className={`confirm-ui fit-content p-10 bg-l4-grey fg-l3-white`}>
                        <h1 className={'text-center'}>{window.knowit.SITENAME}</h1>
                        <div className='text-center'>
                            {content}
                        </div>
                        <div className='float-clear'></div>
                    </div>
                );
            }
        });
    };

    const optionsTestsToDisplay = [
        {key: 'all', text: `${lang.getSelectOption('nbrLigneToDisplay', 'all')}`},
        {key: 'success', text: `${lang.getSelectOption('nbrLigneToDisplay', 'success')}`},
        {key: 'warn', text: `${lang.getSelectOption('nbrLigneToDisplay', 'warn')}`},
        {key: 'failed', text: `${lang.getSelectOption('nbrLigneToDisplay', 'failed')}`}
    ];

    const numeric2 = 2;
    const maxPercent = 100;

    return (
        <div >
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <AauMixinFilters
                lang={lang}
                filters={[
                    {component: 'select', name: 'nbrLigneToDisplay', 'width': 200, 'options': optionsTestsToDisplay, changeHandler: value => {setTestsToDisplay(value);},
                        value: lang.getSelectOption('nbrLigneToDisplay', testsToDisplay)
                    }
                ]}
            />
            {
                !(data !== null && data !== false)
                ? (
                    <div className='p-5 pl-7 pr-15 fit-content'>
                        <AauMixinLoader />
                    </div>
                ):(
                    <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                        <div className='width-30 pr-4 float-left'>
                            <h2 className='text-center mb-2'>Cat&eacute;gories</h2>
                            <ul style={{listStyle: 'none'}}>
                                <li>
                                    <b>{lang.getText('catTest')}</b>: {d['suite_count']}
                                </li>
                                {/*<li>*/}
                                {/*    <AauMixinIcon*/}
                                {/*        className='mr-2'*/}
                                {/*        size='tiny'*/}
                                {/*        color='l3-green'*/}
                                {/*        icon='folder-check'*/}
                                {/*    />*/}
                                {/*    <b>{lang.getText('catsuccess')}</b>: {d['test_pass']} ({((d['suite_pass'])/d['suite_count']*maxPercent).toFixed(parseInt(localStorage.getItem('precision')))}%)*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <AauMixinIcon*/}
                                {/*        className='mr-2'*/}
                                {/*        size='tiny'*/}
                                {/*        color='l3-red'*/}
                                {/*        icon='folder-cross'*/}
                                {/*    />*/}
                                {/*    <b>{lang.getText('catfail')}</b>: {d['suite_count'] - d['suite_pass']} ({((d['suite_count'] - d['suite_pass'])/d['suite_count']*maxPercent).toFixed(parseInt(localStorage.getItem('precision')))}%)*/}
                                {/*</li>*/}
                            </ul>
                            <h2 className='text-center mb-7'>Tests</h2>
                            <ul style={{listStyle: 'none'}}>
                                <li>
                                    <b>{lang.getText('nbrTest')}</b>: {d['test_count']}
                                </li>
                                <li>
                                    {/*<AauMixinIcon*/}
                                    {/*    className='mr-2'*/}
                                    {/*    size='tiny'*/}
                                    {/*    color='l3-green'*/}
                                    {/*    icon='folder-check'*/}
                                    {/*/>*/}
                                    <b>{lang.getText('testsucess')}</b>: {d['test_pass']} ({(d['test_pass']/d['test_count']*maxPercent).toFixed(parseInt(localStorage.getItem('precision')))}%)
                                </li>
                                <li>
                                    {/*<AauMixinIcon*/}
                                    {/*    className='mr-2'*/}
                                    {/*    size='tiny'*/}
                                    {/*    color='l3-orange'*/}
                                    {/*    icon='folder-cross'*/}
                                    {/*/>*/}
                                    <b>{lang.getText('testwarn')}</b>: {d['test_warn']} ({(d['test_warn']/d['test_count']*maxPercent).toFixed(parseInt(localStorage.getItem('precision')))}%)
                                </li>
                                <li>
                                    {/*<AauMixinIcon*/}
                                    {/*    className='mr-2'*/}
                                    {/*    size='tiny'*/}
                                    {/*    color='l3-red'*/}
                                    {/*    icon='folder-cross'*/}
                                    {/*/>*/}
                                    <b>{lang.getText('testfail')}</b>: {d['test_fail']} ({(d['test_fail']/d['test_count']*maxPercent).toFixed(parseInt(localStorage.getItem('precision')))}%)
                                </li>
                            </ul>
                            <h2 className='text-center mb-7'>Information Générale</h2>
                            <ul style={{listStyle: 'none'}}>
                                <li>
                                    <b>{lang.getText('startat')}</b>: {dayjs(dateStart).format(date_format)}
                                </li>
                                <li>
                                    <b>{lang.getText('endat')}</b>: {dayjs(dateEnd).format(date_format)}
                                </li>
                                <li>
                                    <b>{lang.getText('duration')}</b>: {d['duration']}
                                </li>
                            </ul>
                            {/*<AauMixinIconButton*/}
                            {/*    classContainer='m-0 mr-1 mt-1'*/}
                            {/*    size='tiny'*/}
                            {/*    iconazure='Video'*/}
                            {/*    variant='nooutlined_righticontext'*/}
                            {/*    tooltip={lang.getBtn('viewimgerrorcapture')}*/}
                            {/*    mouseClickHandler={e => handleClickViewError('video', `${data['info']['result']['uuid']}/video.mp4`)}*/}
                            {/*/>*/}
                        </div>
                        <div className='p-5 pr-15 width-60 fit-content float-left bd-1 bd-top-none bd-bottom-none bd-right-none'>
                            <ul style={{listStyle: 'none'}}>
                                {
                                    Object.keys(data['info']['result']['suites']).map((suite, suiteIndex) => {
                                        return <li key={`suite-${suiteIndex}`} className='float-clear pt-2'>
                                            {/*<AauMixinIcon*/}
                                            {/*    className='mr-2'*/}
                                            {/*    size='tiny'*/}
                                            {/*    color={data['info']['result']['suites'][suite]['state'] === true ? 'l3-green' : 'l3-red'}*/}
                                            {/*    icon={data['info']['result']['suites'][suite]['state'] === true ? 'folder-check' : 'folder-cross'}*/}
                                            {/*/>*/}
                                            {data['info']['result']['suites'][suite]['title']}
                                            &nbsp;({data['info']['result']['suites'][suite]['pass']}/{data['info']['result']['suites'][suite]['count']})
                                            &nbsp;{data['info']['result']['suites'][suite]['duration']}
                                            <ul style={{listStyle: 'none'}} className='mb-10'>
                                                {
                                                    Object.keys(data['info']['result']['suites'][suite]['tests']).map((test, testIndex) => {
                                                        let testDisplay = null;

                                                        if ( testsToDisplay === 'all'
                                                            || ( testsToDisplay === 'success' && data['info']['result']['suites'][suite]['tests'][test]['state'] === 1 )
                                                            || ( testsToDisplay === 'warn' && data['info']['result']['suites'][suite]['tests'][test]['state'] === numeric2 )
                                                            || ( testsToDisplay === 'failed' && data['info']['result']['suites'][suite]['tests'][test]['state'] === 0 )
                                                        ) {
                                                            testDisplay = <li key={`suite-${suiteIndex}-test-${testIndex}`} className='pb-1 float-clear mt-1 mb-1'>
                                                                {/*<AauMixinIcon*/}
                                                                {/*    className='mr-2'*/}
                                                                {/*    size='tiny'*/}
                                                                {/*    color={data['info']['result']['suites'][suite]['tests'][test]['state'] === 1 ? 'l3-green' : (data['info']['result']['suites'][suite]['tests'][test]['state'] === numeric2 ? 'l3-orange' : 'l3-red')}*/}
                                                                {/*    icon={data['info']['result']['suites'][suite]['tests'][test]['state'] === 1 ? 'checkmark' : (data['info']['result']['suites'][suite]['tests'][test]['state'] === numeric2 ? 'warning-v2' : 'cross')}*/}
                                                                {/*/>*/}
                                                                {data['info']['result']['suites'][suite]['tests'][test]['title']}&nbsp;({data['info']['result']['suites'][suite]['tests'][test]['duration']})
                                                                {
                                                                    data['info']['result']['suites'][suite]['tests'][test]['err'] !== ''
                                                                    ?
                                                                    <div className='float-clear pl-10'>
                                                                        {/*<AauMixinIconButton*/}
                                                                        {/*    classContainer='m-0 mr-1 mt-1'*/}
                                                                        {/*    size='tiny'*/}
                                                                        {/*    bgColor='l3-red'*/}
                                                                        {/*    icon='eye'*/}
                                                                        {/*    variant='outlined'*/}
                                                                        {/*    tooltip={lang.getBtn('viewimgerrorcapture')}*/}
                                                                        {/*    mouseClickHandler={e => handleClickViewError('img', `${data['info']['result']['uuid']}/${data['info']['result']['suites'][suite]['tests'][test]['uuid']}.png`)}*/}
                                                                        {/*/>*/}
                                                                        <div className='float-left m-0 mr-1 pt-2 width-90 text-bold'>
                                                                            {data['info']['result']['suites'][suite]['tests'][test]['err']}
                                                                        </div>
                                                                        <div className='float-clear'></div>
                                                                    </div>
                                                                    : null
                                                                }
                                                            </li>;
                                                        }

                                                        return testDisplay;
                                                    })
                                                }
                                            </ul>
                                        </li>;
                                    })
                                }
                            </ul>
                            <div className='float-clear' />
                        </div>
                        <div className='float-clear' />
                    </div>
                )
            }
            <AauMixinFabBar
                back={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                lang={props.lang}
                urlBasePath={`${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                fabs={null}
            />
        </div>
    );
};
AauPageTestFront.defaultProps = defaultProps;
