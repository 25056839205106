/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {tokens} from '@fluentui/react-theme';
import {Caption2} from '@fluentui/react-components';

export interface AauMixinBadgeVersionProps {
    text: string;
    state: string;
}

const defaultProps = {
    text: null,
    state: null
} as AauMixinBadgeVersionProps;

export const AauMixinBadgeVersion: FC<AauMixinBadgeVersionProps> = props => {
    let bgColor = tokens.colorPaletteBeigeBorderActive;
    if ( props.state === 'old' ) {
        bgColor = tokens.colorPaletteRedBorderActive;
    } else if ( props.state === 'new' ) {
        bgColor = tokens.colorPaletteGreenBorderActive;
    } else if ( ['dev', 'wip'].includes(props.state) ) {
        bgColor = tokens.colorPaletteSteelBorderActive;
    }

    return <Caption2 key='module_version' className={`ml-1 mr-1`} style={{padding: 2, paddingRight: 4, paddingLeft: 4, backgroundColor: bgColor, color: tokens.colorNeutralForegroundInverted}}>
        {props.text}
    </Caption2>;
};
AauMixinBadgeVersion.defaultProps = defaultProps;