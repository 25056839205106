/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {
    Tooltip,
    Toolbar,
    ToolbarButton,
    ToolbarDivider,
    DialogTrigger,
    DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, Dialog
} from "@fluentui/react-components";
import {
    PaddingDown24Filled,
    PaddingTop24Filled,
    ArrowReset24Filled,
    Add24Filled,
    Dismiss24Regular
} from "@fluentui/react-icons";
import {tokens} from "@fluentui/react-theme";
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";
import axios from 'axios';
import Cookies from 'js-cookie';
import {toast} from "react-toastify";


export interface AauMixinFabBarProps {
    lang: AauToolsLang;
    create?: string;
    back?: string;
    urlBasePath?: string;
    fabs?: object
}

const defaultProps = {
    create: null,
    back: null,
    urlBasePath: '',
    fabs: null
} as AauMixinFabBarProps;

export const AauMixinFabBar: FC<AauMixinFabBarProps> = props => {

    const fabStyles= {
        border: '1px',
        borderColor: tokens.colorNeutralStroke2,
        borderStyle: 'solid'
    }

    console.log(props.fabs);

    return <Toolbar
        key={`toolbar_fab`}
        size={'small'}
        aria-label="Global Fab Toolbar"
        vertical={true}
        style={{
            position: 'fixed',
            right: '10px',
            bottom: '18px',
            zIndex: 1000,
            backgroundColor: tokens.colorNeutralShadowKeyDarker
        }}
    >
        {
            props.fabs !== null ? Object.keys(props.fabs).map((key, i) => {
                var btn = props.fabs[key];

                return (!btn.hasOwnProperty('enabled') || btn.hasOwnProperty('enabled') && btn['enabled'] === true) ? (
                    btn['component']['confirm'] === false ? <Tooltip key={`fab_${key}`} content={btn['label']} relationship="description">
                        <ToolbarButton
                            key={`fab_btn_${key}`}
                            as={'button'}
                            icon={btn['icon'] ? btn['icon'] : <AauMixinIcon icon={btn['component']['iconazure'] } style={`fab`} />}
                            style={fabStyles}
                            onClick={() => {
                                if ( btn['mixin'] === 'btn_link_with_url' ) {
                                    window.open(btn['component']['url'], '_blank')
                                } else if ( btn['mixin'] === 'btn_link_page_id' ) {
                                    window.location.href = `${window.knowit.FRONTURL}/${props.urlBasePath}${ btn['component'].hasOwnProperty('url') ? btn['component']['url'] : `/${key}` }`
                                } else if ( btn['mixin'] === 'btn_link_action_id' ) {
                                    axios.get(
                                    `/rest/${props.urlBasePath}${ btn['component'].hasOwnProperty('url') ? btn['component']['url'] : `/${key}`}`,
                                    {
                                            baseURL: `${window.knowit.BACKURL}`,
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                            }
                                        }
                                    ).then(res => {
                                        const msgToast = res.data.action_return;
                                        if (res.data.success === true) {
                                            toast.success(msgToast.replace('%s', res.data.name));
                                        } else {
                                            toast.error(msgToast.replace('%s', res.data.name));
                                        }
                                    }).catch(err => {
                                        const unauthorizedError = 401;
                                        if (err.response.data['code'] === unauthorizedError) {
                                            Cookies.remove('JWT');
                                            Cookies.remove('user');
                                            Cookies.remove('rank');
                                            Cookies.remove('displayname');
                                            window.location.href = `/login?redirect=${window.location.pathname}`;
                                        } else {
                                            const msgToast = props.lang.getError(err.response.data['code']);
                                            toast.error(msgToast.replace('%s', ''));
                                        }
                                    });
                                }
                            }}
                        />
                    </Tooltip> : <Dialog modalType={`alert`} key={`fab_${key}_confirm`}>
                        <DialogTrigger disableButtonEnhancement>
                            <Tooltip key={`fab_${key}`} content={btn['label']} relationship="description">
                                <ToolbarButton
                                    key={`fab_btn_${key}`}
                                    as={'button'}
                                    icon={btn['icon'] ? btn['icon'] : <AauMixinIcon icon={btn['component']['iconazure'] } style={`fab`} />}
                                    style={fabStyles}
                                />
                            </Tooltip>
                        </DialogTrigger>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle
                                    action={
                                        <DialogTrigger action="close">
                                            <Button
                                                appearance="subtle"
                                                aria-label="close"
                                                icon={<Dismiss24Regular />}
                                            />
                                        </DialogTrigger>
                                    }
                                >
                                    {window.knowit.SITENAME}
                                </DialogTitle>
                                <DialogContent>
                                    {props.lang.getText('dialog_confirm_text')}
                                </DialogContent>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary">{props.lang.getBtn('cancel')}</Button>
                                    </DialogTrigger>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="primary" onClick={() => {
                                            axios.get(
                                            `/rest/${props.urlBasePath}${ btn['component'].hasOwnProperty('url') ? btn['component']['url'] : `/${key}`}`,
                                            {
                                                    baseURL: `${window.knowit.BACKURL}`,
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                                    }
                                                }
                                            ).then(res => {
                                                const msgToast = res.data.action_return;
                                                if (res.data.success === true) {
                                                    toast.success(msgToast.replace('%s', res.data.name));
                                                } else {
                                                    toast.error(msgToast.replace('%s', res.data.name));
                                                }
                                            }).catch(err => {
                                                const unauthorizedError = 401;
                                                if (err.response.data['code'] === unauthorizedError) {
                                                    Cookies.remove('JWT');
                                                    Cookies.remove('user');
                                                    Cookies.remove('rank');
                                                    Cookies.remove('displayname');
                                                    window.location.href = `/login?redirect=${window.location.pathname}`;
                                                } else {
                                                    const msgToast = props.lang.getError(err.response.data['code']);
                                                    toast.error(msgToast.replace('%s', ''));
                                                }
                                            });
                                        }}>
                                            {props.lang.getBtn('confirm')}
                                        </Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                ) : null
            }) : null
        }
        {
            props.create !== null ? <Tooltip key={`fab_create`} content={props.lang.getBtn('create')} relationship="description">
                <ToolbarButton
                    key={`fab_btn_create`}
                    as={'button'}
                    icon={<AauMixinIcon icon='Add24Filled' source={`microsoft` } style={`fab`} />}
                    style={fabStyles}
                    onClick={() => window.location.href = props.create}
                />
            </Tooltip> : null
        }
        {
            props.back !== null ? <Tooltip key={`fab_go_back`} content={props.lang.getBtn('go_back')} relationship="description">
                <ToolbarButton
                    key={`fab_btn_go_back`}
                    as={'button'}
                    icon={<AauMixinIcon icon='ArrowReset24Filled' source={`microsoft` } style={`fab`} />}
                    style={fabStyles}
                    onClick={() => window.location.href = props.back}
                />
            </Tooltip> : null
        }
        <ToolbarDivider />
        <Tooltip key={`fab_go_to_up`} content={props.lang.getBtn('go_to_up')} relationship="description">
            <ToolbarButton
                key={`fab_btn_go_to_up`}
                as={'button'}
                icon={<AauMixinIcon icon='PaddingTop24Filled' source={`microsoft` } style={`fab`} />}
                style={fabStyles}
                onClick={() => window.scrollTo(0, 0)}
            />
        </Tooltip>
        <Tooltip key={`fab_go_to_down`} content={props.lang.getBtn('go_to_down')} relationship="description">
            <ToolbarButton
                key={`fab_btn_go_to_down`}
                as={'button'}
                icon={<AauMixinIcon icon='PaddingDown24Filled' source={`microsoft`} style={`fab`} />}
                style={fabStyles}
                onClick={() => window.scroll(0, document.body.scrollHeight)}
            />
        </Tooltip>

    </Toolbar>
};
AauMixinFabBar.defaultProps = defaultProps;
