/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState} from 'react';

import {Button, Tooltip, Input} from '@fluentui/react-components';
import {Routes, BrowserRouter, Route} from 'react-router-dom';

import Cookies from 'js-cookie';

import './Components/aauToolsColor/aau-colors.scss';
import './Components/aauToolsTypography/typography.scss';
import './Components/aauToolsSizing/sizing.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import axios from 'axios';

import {AauMixinLoader} from './Components/aauMixinLoader/aauMixinLoader';

import {ToastContainer, Slide} from 'react-toastify';

import {AauComponentMenuHorizontal} from './Components/aauComponentMenuHorizontal/aauComponentMenuHorizontal';
import {AauPageForbidden} from "./Components/aauPageForbidden/aauPageForbidden";
import {AauPageLogin} from './Components/aauPageLogin/aauPageLogin';
import {AauPageList} from './Components/aauPageList/aauPageList';
import {AauPageForm} from './Components/aauPageForm/aauPageForm';
import {AauPageRecordHistory} from './Components/aauPageRecordHistory/aauPageRecordHistory';
import {AauPageLog} from './Pages/aauPageLog/aauPageLog';
import {AauPageDatabase} from './Pages/aauPageDatabase/aauPageDatabase';
import {AauPageTestFront} from './Pages/aauPageTestFront/aauPageTestFront';
import {AauPageDiffFile} from './Pages/aauPageDiffFile/aauPageDiffFile';
import {AauPageCartoAudience} from './Pages/aauPageCartoAudience/aauPageCartoAudience';
import {AauPageCartoKubeDeployment} from './Pages/aauPageCartoKubeDeployment/aauPageCartoKubeDeployment';
import {AauPageCartoKubePod} from './Pages/aauPageCartoKubePod/aauPageCartoKubePod';
import {AauPageCartoKubeSvc} from './Pages/aauPageCartoKubeSvc/aauPageCartoKubeSvc';
import {AauPageCartoDns} from './Pages/aauPageCartoDns/aauPageCartoDns';
import {AauPageAIO} from './Pages/aauPageAIO/aauPageAIO';
import {AauPageDockerInfo} from './Pages/aauPageDockerInfo/aauPageDockerInfo';
import {AauPageKubeView} from './Pages/aauPageKubeView/aauPageKubeView';
import {AauPageViewDetailList} from './Pages/aauPageViewDetailList/aauPageViewDetailList';
import {Search24Filled} from "@fluentui/react-icons";
import {AauPageRecordHistoryDetail} from "./Components/aauPageRecordHistoryDetail/aauPageRecordHistoryDetail";
import {AauPageKubeNode} from './Pages/aauPageKubeNode/aauPageKubeNode';
import {AauPageSanityCheck} from './Pages/aauPageSanityCheck/aauPageSanityCheck';
import {AauPageYamlView} from "./Pages/aauPageYamlView/aauPageYamlView";
import {AauPageSearch} from "./Components/aauPageSearch/aauPageSearch";
import {AauPageNotFound} from "./Components/aauPageNotFound/aauPageNotFound";
import {tokens} from "@fluentui/react-theme";
import {AauMixinAvatar} from "./Components/aauMixinAvatar/aauMixinAvatar";
import {AauPageDashboard} from "./Components/aauPageDashboard/aauPageDashboard";
import AauToolsLang from "./Components/aauToolsLang/aauToolsLang";

import {MsalProvider, useMsal} from "@azure/msal-react";

import {
    MenuList, MenuTrigger, MenuPopover, MenuItem, Menu, MenuButton, Link, ToggleButton
} from "@fluentui/react-components";

import {AauPageTreeview} from "./Components/aauPageTreeview/aauPageTreeview";
import {AauMixinIcon} from "./Components/aauMixinIcon/aauMixinIcon";
import {AauPageTextAnalyzer} from "./Pages/aauPageTextAnalyzer/aauPageTextAnalyzer";

declare global {
    interface Window {
        knowit?: any;
    }
}

function App(instance) {
    let qs = require('qs'); // SONAR
    let menuContent = null;
    let content;
    let filterContent = null;

    const retrieveMenu = async () => {
        await axios.get(
            `/rest/menu_with_persona`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(typeof(Cookies.get('JWT')) !== 'undefined' ? Cookies.get('JWT') : null)
                }
            }
        ).then(res => {
            setDmenu(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    const [dmenu, setDmenu] = useState(null);
    const [data, setData] = useState(null);
    const [search, setSearch] = useState(null);

    const lang = new AauToolsLang();

    lang.load();

    let profil = {};
    if ( lang.isLoad() ){
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        const isLogged = typeof(Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null;
        profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};

        if ( isLogged && params.hasOwnProperty('redirect') ) {
            window.location.href = `${params.redirect}`;
        } else if ( isLogged && window.location.pathname === '/login' ) {
            window.location.href = `/`;
        }

        if ( isLogged ) {
            if ( dmenu !== null && dmenu !== false && dmenu !== "waiting" ) {
                let menuObject = JSON.parse(JSON.stringify(dmenu['menu']));
                menuContent = <div
                    className={`width-100 pos-fixed z-412 top-px-0`}
                    style={{backgroundColor: tokens.colorNeutralBackground1}}
                >
                    <div className={`height-px-48`} style={{backgroundColor: tokens.colorNeutralShadowKeyDarker}}>
                        <div className='float-left width-px-900-min'>
                            <AauComponentMenuHorizontal
                                data={menuObject}
                            />
                        </div>
                        <div className='float-right width-px-400-min'>
                            <Input
                                id={`global_search`}
                                key={`global_search`}
                                name={`global_search`}
                                aria-labelledby={`global_search`}
                                appearance={`underline`}
                                placeholder={lang.getText('search')}
                                value={data !== null ? data : ''}
                                onChange={(e, data) => {
                                    setData(data['value']);
                                }}
                                onKeyPress={(e) => {
                                    if ( ['Enter', 'NumpadEnter'].includes(e.code) ) {
                                        setSearch(data);
                                    }
                                }}
                                contentAfter={<AauMixinIcon icon='Search24Filled' source={`microsoft`} />}
                                type={`text`}
                                size={`medium`}
                                className={`float-left width-px-175 mr-4 mt-2`}
                            />
                            <Menu
                                openOnHover={true}
                                closeOnScroll={true}
                            >
                                <MenuTrigger disableButtonEnhancement>
                                    <MenuButton size={`small`} appearance={`subtle`}>
                                        <AauMixinAvatar
                                            as={'persona'}
                                            className={`mt-1`}
                                            displayName={dmenu['persona']['displayName']}
                                            secondaryText={dmenu['persona']['title']}
                                        />
                                    </MenuButton>
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <ToggleButton
                                            checked={(profil.hasOwnProperty('darkMode') ? !profil['darkMode'] : false) === false}
                                            appearance={`subtle`}
                                            icon={<AauMixinIcon icon={(profil.hasOwnProperty('darkMode') ? !profil['darkMode'] : false) ? 'WeatherMoon24Regular' : 'WeatherSunny24Regular'} source={`microsoft`} />}
                                            onClick={() => {
                                                profil['darkMode'] = (profil.hasOwnProperty('darkMode') ? !profil['darkMode'] : false);
                                                localStorage.setItem(Cookies.get('user'), JSON.stringify(profil));
                                                window.location.reload();
                                            }}
                                        >
                                            {(profil.hasOwnProperty('darkMode') ? !profil['darkMode'] : false) ? lang.getText('theme_dark', null,'Dark Theme') : lang.getText('theme_light', null, 'Light Theme')}
                                        </ToggleButton>
                                        <Link appearance="subtle" href={`/logout`} style={{textDecoration: "none"}}><MenuItem>Log Out</MenuItem></Link>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                            <Tooltip content={lang.getBtn('create_support_ticket')} relationship="description">
                                <Button
                                    as={'a'}
                                    size={`large`}
                                    icon={<AauMixinIcon icon='Headset48Regular' source={`microsoft`} />}
                                    appearance={`subtle`}
                                    className={`position-relative right-5`}
                                    target={`_blank`}
                                    href={`https://epmakers.atlassian.net/servicedesk/customer/portal/12/group/32/create/161`}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>;
            } else if ( dmenu !== false ) {
                setDmenu(false);
                retrieveMenu();
            } else {
                // NO// SONAR
            }
        } else if ( window.location.pathname !== '/login' && window.location.pathname !== '/logout' ) {
            window.location.href = `/login?redirect=${window.location.pathname}`;
        } else if ( window.location.pathname !== '/login' ) {
            window.location.href = '/login';
        } else {
            // NO// SONAR
        }

        if ( search !== null ) {
            window.location.href = `/search/${search}`;
        } else if ( data === false ) {
            content = <div className={`width-100 height-vh-100-min`}>
                <AauMixinLoader />
            </div>;
        } else {
            const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            content = (isLogged === false || (isLogged === true && dmenu !== false))
            ? <Routes>
                <Route path='/forbidden' element={<AauPageForbidden msg={params.hasOwnProperty("msg") ? params.msg : 'unknow'}/>} />
                <Route path='/notfound' element={<AauPageNotFound />} />
                <Route path='/' element={<AauPageDashboard lang={lang} />} />
                <Route path='/login' element={<AauPageLogin instance={instance} microsoft={false} login_action={true} lang={lang}/>} />
                <Route path='/logout' element={<AauPageLogin instance={instance} microsoft={false} login_action={false} lang={lang}/>} />
                <Route path='/search/:search' element={<AauPageSearch lang={lang}/>} />
                <Route path='/sso/microsoft' element={<AauPageLogin instance={instance} microsoft={true} login_action={true} lang={lang}/>} />
                <Route path='/database' element={<AauPageDatabase lang={lang}/> }/>
                <Route path='/textanalyzer' element={<AauPageTextAnalyzer lang={lang}/> }/>
                <Route path='/admin/aio' element={<AauPageAIO lang={lang}/>} />
                <Route path='/admin/kubenode' element={<AauPageKubeNode lang={lang}/> } />
                <Route path='/admin/backup/view/' element={<AauPageKubeNode lang={lang}/>} />
                <Route path='/:admin/linksdirectory/cartonetwork' element={<AauPageCartoAudience lang={lang}/>} />
                <Route path='/:admin/kubedeployment/carto' element={<AauPageCartoKubeDeployment lang={lang}/>} />
                <Route path='/kubepod/carto' element={<AauPageCartoKubePod lang={lang}/>} />
                <Route path='/kubepod/view' element={<AauPageKubeView lang={lang}/>} />
                <Route path='/kubesvc/carto' element={<AauPageCartoKubeSvc lang={lang}/>} />
                <Route path='/admin/sanitycheck' element={<AauPageSanityCheck lang={lang}/>} />
                <Route path='/:admin/docker/dockerinfo/:identifier' element={<AauPageDockerInfo lang={lang}/>} />
                <Route path='/:admin/dns/carto' element={<AauPageCartoDns lang={lang}/>} />
                <Route path='/:admin/:module/getyaml/:identifier' element={<AauPageYamlView action='getyaml' lang={lang}/>} />
                <Route path='/:admin/:module/history/:identifier' element={<AauPageRecordHistory lang={lang}/>} />
                <Route path='/:admin/:module/historydetail/:identifier' element={<AauPageRecordHistoryDetail lang={lang}/>} />
                <Route path='/:admin/:module/cloudlog/:identifier' element={<AauPageLog lang={lang}/>} />
                <Route path='/:admin/:module/treeview' element={<AauPageTreeview lang={lang}/>} />
                <Route path='/:admin/:module/difffilewithvar/:identifier' element={<AauPageDiffFile action='difffilewithvar' lang={lang}/>} />
                <Route path='/:admin/:module/viewdetaillist/:identifier' element={<AauPageViewDetailList lang={lang}/>} />
                <Route path='/:admin/:module/difffile/:identifier' element={<AauPageDiffFile action='difffile' lang={lang} />} />
                <Route path='/:admin/:module/:action/:identifier' element={<AauPageForm lang={lang}/>} />
                <Route path='/admin/testfront/view/:identifier' element={<AauPageTestFront lang={lang}/>} />
                <Route path='/:module/cloudlog/:identifier' element={<AauPageLog lang={lang}/>} />
                <Route path='/:module/treeview' element={<AauPageTreeview lang={lang}/>} />
                <Route path='/:module/history/:identifier' element={<AauPageRecordHistory lang={lang}/>} />
                <Route path='/:module/:action/:identifier' element={<AauPageForm lang={lang}/>} />
                <Route path='/:admin/:module' element={<AauPageList lang={lang}/>} />
                <Route path='/:module' element={<AauPageList lang={lang}/>} />
            </Routes>
            : <AauMixinLoader />;
        }
    } else {
        content = <div className={`width-100 height-vh-100-min`}>
            <AauMixinLoader />
        </div>;
        setTimeout(() => window.location.reload(), 2000);
    }

    return <div className={`width-100 height-vh-100-min`} style={{backgroundColor: tokens.colorNeutralBackground1}}>
        <MsalProvider instance={instance.instance}>
            <BrowserRouter>
                {menuContent}
                <ToastContainer
                    position='bottom-center'
                    hideProgressBar
                    autoClose={3000}
                    transition={Slide}
                    theme='dark'
                />
                {filterContent}
                {content}
            </BrowserRouter>
        </MsalProvider>
        <div className={"float-clear"} />
    </div>;
}

export default App;


