/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import nextId from 'react-id-generator';
import {Button} from "@fluentui/react-components";

export interface AauMixinListTextProps {
    value: string[];
    nobullet?: boolean;
    enums?: object;
    type?: 'html_nobullet' | 'html_button' | 'html' | 'num' | 'join';
}

const defaultProps = {
    type: 'html',
    enums: {},
    nobullet: false
} as AauMixinListTextProps;

export const AauMixinListText: FC<AauMixinListTextProps> = props => {
    let ret;
    if (  props.type === 'html_button' ) {
        const content =  props.value.map(index => {
            return <li className='mb-1'>
                <Button
                    as='a'
                    href={`https://${index}`}

                >
                    {index}
                </Button>

            </li>;
        });
        ret = <ul style={{listStyle: 'none', paddingInlineStart: 10}}>
            {content}
        </ul>;
    } else if (  props.type === 'num' ) {
        ret = <ol>
            {
                props.value.sort().map((row, index) => <li key={nextId()} className='mb-1'>
                    {props.enums.hasOwnProperty(row) ? props.enums[row] : row}
                </li>)
            }
        </ol>;
    } else if (  props.type === 'join' ) {
        ret =  props.value.join(', ');
    } else if (  props.type === 'html_nobullet' ) {
        ret = <ul style={{listStyle: 'none', paddingInlineStart: 10}} >
            {
                props.value.sort().map((row, index) => <li style={{wordBreak: 'keep-all'}} key={nextId()} className='mb-1'>
                    {props.enums.hasOwnProperty(row) ? props.enums[row] : row}
                </li>)
            }
        </ul>;
    } else {
        ret = <ul>
            {
                props.value.sort().map((row, index) => <li key={nextId()} className='mb-1'>
                    {props.enums.hasOwnProperty(row) ? props.enums[row] : row}
                </li>)
            }
        </ul>;
    }

    return ret;
};
AauMixinListText.defaultProps = defaultProps;
