/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';

import Cookies from 'js-cookie';

import {AauComponentTableColumnProps} from './tableHeadColumn.d';

import {CSVLink} from "react-csv";
import {Toolbar, ToolbarButton, Tooltip, MenuList, MenuTrigger, MenuPopover, Menu, MenuItemCheckbox} from "@fluentui/react-components";
import {DocumentBulletList24Regular, ColumnTripleEdit24Regular, FilterDismiss24Filled} from "@fluentui/react-icons";
import {tokens} from "@fluentui/react-theme";

export interface AauComponentTablesFiltersProps {
    columns: AauComponentTableColumnProps[];
    parentSetColumns: any;
    lang: AauToolsLang;
    data?: object[];
}

const defaultProps = {
    data: []
} as AauComponentTablesFiltersProps;

export const AauComponentTablesFilters: FC<AauComponentTablesFiltersProps> = props => {
    const displayColumnStateArray: string[] = [];

    const [displayColumnState, setDisplayColumnState] = useState<string[]>(null);

    if ( displayColumnState === null ){
        setDisplayColumnState(displayColumnStateArray);
    }

    const csvSeparator = '";"';
    const csvContentArray = [];

    const savedFiltersLoad = JSON.parse(localStorage.getItem(Cookies.get('user')))['filters'];
    const savedModuleFilterLoad = savedFiltersLoad.hasOwnProperty(window.location.href) ? savedFiltersLoad[window.location.href] : {};

    const csvHeader = []
    Object.keys(props.columns).map(ckey => {
        const c = props.columns[ckey];
        const cname = c['name'];
        const mixin = c['mixin'];

        const co = c.hasOwnProperty('component') ? c['component'] : {};
        const optional = co.hasOwnProperty('optional') && co['optional'] === true;

        if ( !['grp'].includes(mixin) && (
            (
                savedModuleFilterLoad.hasOwnProperty(cname) && savedModuleFilterLoad[cname].hasOwnProperty('state') && savedModuleFilterLoad[cname]['state'] === true
            ) || (
                (!savedModuleFilterLoad.hasOwnProperty(cname) || !savedModuleFilterLoad[cname].hasOwnProperty('state')) && optional === true
            )
        )) {
            csvHeader.push(props.lang.getText(cname));
        }

        return null;
    });
    csvContentArray.push(`"${csvHeader.join(csvSeparator)}"`);

    Object.keys(props.data).map(dkey => {
        const csvTmp = []
        const d = props.data[dkey];

        Object.keys(props.columns).map(ckey => {
            const c = props.columns[ckey];
            const cname = c['name'];
            const mixin = c['mixin'];
            let dTmp = d[cname];

            const co = c.hasOwnProperty('component') ? c['component'] : {};
            const optional = co.hasOwnProperty('optional') && co['optional'] === true;

            switch (mixin) {
                case 'btn_state':
                case 'toggle':
                case 'switch':
                case 'toggle_state':
                case 'switch_state':
                case 'boolean':
                case 'bool':
                    dTmp = dTmp === true ? 1 : 0;
                    break;
                case 'str_mask':
                    const usedMask = co.hasOwnProperty('used_mask') ? co['used_mask'] : false;
                    const maskFieldName = co.hasOwnProperty('mask_field') ? co['mask_field'] : '';
                    const maskField = usedMask && d.hasOwnProperty(maskFieldName) ? d[maskFieldName] : false;

                    dTmp = maskField === true ? '************' : dTmp;
                    break;
                case 'dynamicForm':
                case 'json':
                case 'jsonb':
                    dTmp = JSON.stringify(dTmp).replaceAll("'", "\\'").replaceAll('"', "'")
                    break;
            }

            if ( !['grp'].includes(mixin) && (
                (
                    !savedModuleFilterLoad.hasOwnProperty(cname) && optional === false
                ) || (
                    savedModuleFilterLoad.hasOwnProperty(cname) && savedModuleFilterLoad[cname].hasOwnProperty('state') && savedModuleFilterLoad[cname]['state'] === true
                )
            )) {
                csvTmp.push(dTmp);
            }

            return null;
        });

        csvContentArray.push(`"${csvTmp.join(csvSeparator)}"`);

        return null;
    });

    return <Toolbar className={`float-right`} size={'small'} aria-label="Datatable Toolbar" >
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Tooltip content={props.lang.getBtn('display_column_choice')} relationship="description">
                    <ToolbarButton
                        icon={<ColumnTripleEdit24Regular />}
                        style={{color: tokens.colorBrandForeground1}}
                    />
                </Tooltip>
            </MenuTrigger>

            <MenuPopover>
                <MenuList hasCheckmarks={true} checkedValues={{columns: displayColumnState}}>
                    {
                        Object.keys(props.columns).map(key => {
                            const c = props.columns[key];

                            let filterContent = null;
                            if ( c['state'] === true ) {
                                displayColumnStateArray.push(c['name']);
                            }

                            return c['mixin'] !== 'grp' && c['name'] !== '' ? <MenuItemCheckbox
                                key={`filter-column-${key}-ctrl`}
                                aria-labelledby={props.lang.getText(c['name'])}
                                name={'columns'}
                                value={c['name']}
                                onClick={() => {
                                    let key = c['name'];
                                    let state = !c['state'];

                                    const displayColumnStateTmp = [...displayColumnState];

                                    if ( state === true && !displayColumnStateTmp.includes(key)) {
                                        displayColumnStateTmp.push(key);
                                    } else if ( state === false && displayColumnStateTmp.includes(key)) {
                                        const index = displayColumnStateTmp.indexOf(key, 0);
                                        if (index > -1) {
                                           displayColumnStateTmp.splice(index, 1);
                                        }
                                    }
                                    setDisplayColumnState(displayColumnStateTmp);
                                    props.parentSetColumns(displayColumnStateTmp);
                                }}
                            >
                                {props.lang.getText(c['name'])}
                            </MenuItemCheckbox> : null;
                        })
                    }
                </MenuList>
            </MenuPopover>
        </Menu>
        <Tooltip content={props.lang.getBtn('reset_column_choice_and_filter')} relationship="description">
            <ToolbarButton
                icon={<FilterDismiss24Filled />}
                style={{color: tokens.colorBrandForeground1}}
                onClick={() => props.parentSetColumns('clearColumns')}
            />
        </Tooltip>
        <CSVLink
            data={csvContentArray.join('\n')}
            filename={`knowit_export_${window.location.pathname.split('/')[window.location.pathname.split('/').length-1]}.csv`}
        >
            <Tooltip content={props.lang.getBtn('export_to_csv')} relationship="description" withArrow>
                <ToolbarButton
                    icon={<DocumentBulletList24Regular />}
                    style={{color: tokens.colorBrandForeground1}}
                />
            </Tooltip>
        </CSVLink>
    </Toolbar>;
};
AauComponentTablesFilters.defaultProps = defaultProps;
