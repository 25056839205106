/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {TabList, Tab} from "@fluentui/react-components";


export interface AauMixinTabsProps {
    vertical?: boolean;
    size?: 'small' | 'medium' | 'large';
    data: object;
}

const defaultProps = {
    vertical: true,
    size: 'small'
} as AauMixinTabsProps;

export const AauMixinTabs: FC<AauMixinTabsProps> = props => {

    const [selectedTabId, setSelectedTabId] = React.useState<string>('tab_0');

    const onTabSelect = (tabId: string) => {
        setSelectedTabId(tabId);
    };

    return props.vertical ? <>
        <TabList
            defaultSelectedValue="tab_0"
            vertical={true}
            size={props.size}
            style={{
                width: 300,
                float: 'left'
            }}
            onTabSelect={(_, d) => onTabSelect(d.value as string)}
        >
            {
                Object.keys(props.data).map(key => {
                    return <Tab
                        value={`tab_${key}`}
                        style={{
                            margin: 4
                        }}
                    >
                        {props.data[key]['title']}
                    </Tab>
                })
            }
        </TabList>
        <div
            style={{
                width: 'calc(100% - 300px)',
                float: 'left'
            }}
        >
            {props.data[Number(selectedTabId.split('_')[1])]['content']}
        </div>
    </> : <>
        <TabList
            defaultSelectedValue="tab1"
            vertical={false}
            size={props.size}
        >
            {
                Object.keys(props.data).map(key => {
                    return <Tab value={`tab_${key}`}>{props.data[key]['title']}</Tab>
                })
            }
        </TabList>
    </>;
};
AauMixinTabs.defaultProps = defaultProps;
