/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Field} from "@fluentui/react-components";
import {capitalize} from "lodash";

export interface AauFormFieldProps {
    label: string;
    content: any;
    readonly: boolean;
    required?: boolean | null;
    isValid: boolean;
    hint?: string;
    className?: string;
}

const defaultProps = {
    readonly: false,
    required: false,
    hint: null,
    className: null
} as AauFormFieldProps;

export const AauFormField: FC<AauFormFieldProps> = props => {
    return <Field
        label={capitalize(props.label)}
        validationMessage={props.readonly || props.isValid ? null : 'Erreur'}
        validationState={props.readonly || props.isValid ? 'success' : 'error'}
        required={props.required}
        className={`mb-3 ${props.className}`}
        hint={props.hint}
    >
        {props.content}
    </Field>;
};
AauFormField.defaultProps = defaultProps;
