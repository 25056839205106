/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';

import axios from 'axios';
import Cookies from 'js-cookie';

import {AauMixinTabs} from '../../Components/aauMixinTabs/aauMixinTabs';

import {AauComponentTable} from '../../Components/aauComponentTable/aauComponentTable';
import nextId from 'react-id-generator';


export interface AauPageSanityCheckProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageSanityCheckProps;

export const AauPageSanityCheck: FC<AauPageSanityCheckProps> = props => {
    const {lang} = props;

    const [data, setData] = useState(null);
    const [menuKey, setMenuKey] = useState<string>('0');

    const admin = '/admin';
    const module = 'sanitycheck';

    let content = <AauMixinLoader />;

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        const dataArray = [];
        Object.keys(data['info']['data']).forEach(did => {
            const info = data['info']['data'][did];

            const help_text = `${data['info']['data'][did]['help']}`;

            dataArray.push({
                title: `${data['info']['data'][did]['title']}`,
                content: info['data'] === null ?
                    null : <div>
                        {help_text !== '' && <div className={`mb-3 text-center`} dangerouslySetInnerHTML={{__html: help_text}}></div>}
                        <AauComponentTable
                            key={nextId()}
                            columns={info['columns']}
                            data={info['data']}
                            columnid={'name'}
                            displayRows={200}
                            displayRowsList={[200]}
                            lang={lang}
                    />
                </div>
            });
        });

        content = <AauMixinTabs
            data={dataArray}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div >
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {content}
            </div>
        </div>
    );
};
AauPageSanityCheck.defaultProps = defaultProps;
