/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {AauMixinTimestamp}  from "../aauMixinTimestamp/aauMixinTimestamp";
import {Input} from "@fluentui/react-components";
import {AauFormField} from "../aauFormField/aauFormField";


export interface AauFormDateTimeProps {
    label?: string;
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    value?: string;
    validator: object;
    parentFunction?: any;
    required?: boolean | null;
    hint?: string;
}

const defaultProps = {
    readonly: false,
    validator: {},
    parentFunction: null,
    value: '',
    required: false,
    hint: null
} as AauFormDateTimeProps;

export const AauFormDateTime: FC<AauFormDateTimeProps> = props => {
    const validateField = (validateValue):void => {
        let isValid;
        if (props.required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        } else {
            const dateRegExp = '^([0-4]{4}-(1[0-2]|0[1-9])-(3[0-1]|[1-2][0-9]|0[1-9]) (2[0-3]|1[0-9]|0[0-9]):([0-5][0-9]):([0-5][0-9]))(\\+[0-9]{2}:[0-9]{2})*$';
            isValid = RegExp(dateRegExp, 'g').test(validateValue);
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( state['isValid'] !== isValid && props.parentFunction !== null ) {
                props.parentFunction(props.itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': props.value
    });
    if (state['isValid'] === null && props.readonly === false ) {
        validateField(props.value);
    }

    return <AauFormField
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}
        hint={props.hint}
        content={props.readonly === true ?
            <AauMixinTimestamp value={state['content']} mode='display' />
            :<Input
                id={props.itemKey}
                key={`${props.itemKey}-ctrl`}
                name={props.name}
                aria-labelledby={props.name}
                appearance={`underline`}
                value={state['content']}
                onChange={(e, data) => validateField(data['value'])}
                type={`text`}
                size={`medium`}
            />
        }
    />;
;
};
AauFormDateTime.defaultProps = defaultProps;
