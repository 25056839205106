/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import {AauMixinListText} from '../aauMixinListText/aauMixinListText';
import {AauMixinStrAmount, MoneyType} from '../aauMixinStrAmount/aauMixinStrAmount';
import {AauMixinStrUnit} from '../aauMixinStrUnit/aauMixinStrUnit';
import {AauMixinStrMask} from '../aauMixinStrMask/aauMixinStrMask';
import {AauMixinBadge} from '../aauMixinBadge/aauMixinBadge';
import {AauMixinTimestamp} from '../aauMixinTimestamp/aauMixinTimestamp';
import {AauMixinBtnBool} from '../aauMixinBtnBool/aauMixinBtnBool';
import {AauMixinBtnGroup} from '../aauMixinBtnGroup/aauMixinBtnGroup';
import {AauMixinAvatar} from '../aauMixinAvatar/aauMixinAvatar';
import {AauMixinSwitchBool} from '../aauMixinSwitchBool/aauMixinSwitchBool';
import {AauMixinRating} from '../aauMixinRating/aauMixinRating';
import {AauMixinProgress} from '../aauMixinProgress/aauMixinProgress';
import {AauMixinJson} from '../aauMixinJson/aauMixinJson';
import {AauMixinStrCompare} from "../aauMixinStrCompare/aauMixinStrCompare";
import AauToolsLang from '../aauToolsLang/aauToolsLang';
import axios from 'axios';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {AauComponentForm} from '../aauComponentForm/aauComponentForm';
import {AauMixinTimeAgo} from "../aauMixinTimeAgo/aauMixinTimeAgo";
import {AauMixinStrLink, AauMixinStrLinkScheme} from "../aauMixinStrLink/aauMixinStrLink";

import {Dismiss24Regular, Edit28Regular} from '@fluentui/react-icons';
import {tokens} from "@fluentui/react-theme";
import {
    Button, Dialog, DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Caption1
} from "@fluentui/react-components";
import {AauMixinLoader} from "../aauMixinLoader/aauMixinLoader";

export interface AauComponentTableBodyColumnProps {
    rowKey: number;
    columnKey: string;
    value: object;
    mixin: string;
    component: object;
    dataJson: object;
    refreshTable?: () => void;
    columnid: string
    columnForm: object[],
    canEdit: boolean;
    canView: boolean;
    canSaveAndAction: string|null;
    saveAndActionLabel?: string;
    viewAction?: string;
    lang: AauToolsLang;
    handlerPanelInfo?: (url: string) => void;
}

export const AauComponentTableBodyColumnPropsDefault = {
    columnForm: null,
    canEdit: false,
    canView: false,
    canSaveAndAction: null,
    saveAndActionLabel: 'Save and Action',
    viewAction: 'view'
} as AauComponentTableBodyColumnProps;

export const AauComponentTableBodyColumn: FC<AauComponentTableBodyColumnProps> = props => {
    let ret:any;
    let align:string;
    const history = useNavigate();

    const [fastEditOpen, setFastEditOpen] = useState<boolean>(false);
    const [displayEditIcon, setDisplayEditIcon] = useState<boolean>(false);
    const [displayItem, setDisplayItem] = useState<object>(null);

    const submitHandler = async (formData, identifier, actionName) => {
        await axios.post(
            `/rest/${window.location.pathname}/${actionName}`,
            JSON.stringify({
                identifier,
                data: formData
            }),
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            if (res.data.success === 1 || res.data.success === true) {
                const msg = props.lang.getNotification('save', true);
                toast.success(msg.replace('%s', res.data.name));
            } else {
                const msg = props.lang.getNotification('save', false);
                toast.error(msg.replace('%s', res.data.name));
            }
            props.refreshTable();
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    const retrieveData = async (url) => {
        await axios.get(
            `/rest/${window.location.pathname}/${props.viewAction}/${props.dataJson[props.columnid]}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setDisplayItem(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    switch (props.mixin) {
        case 'list_html_nobullet':
            align = 'left'

            if ( typeof(props.value) === "undefined" || props.value === null || props.value.toString() === '' ) {
                ret = '';
            } else {
                ret = <AauMixinListText
                    value={props.value.toString().split(',')}
                    enums={props.component.hasOwnProperty('enums') ? props.component['enums'] : {}}
                    type='html_nobullet'
                />;
            }
            break;
        case 'list_html':
            align = 'left'
            if ( typeof(props.value) === "undefined" || props.value === null || props.value.toString() === '' ) {
                ret = '';
            } else {
                ret = <AauMixinListText
                    value={props.value.toString().split(',')}
                    enums={props.component.hasOwnProperty('enums') ? props.component['enums'] : {}}
                    type='html'
                />;
            }
            break;
        case 'list_html_button':
            align = 'left';
            if ( typeof(props.value) === "undefined" || props.value === null || props.value.toString() === '' ) {
                ret = '';
            } else {
                ret = <AauMixinListText
                    value={props.value.toString().split(',')}
                    enums={props.component.hasOwnProperty('enums') ? props.component['enums'] : {}}
                    type='html_button'
                />;
            }
            break;
        case 'list_num':
            align = 'left';
            if ( typeof(props.value) === "undefined" || props.value === null || props.value.toString() === '' ) {
                ret = '';
            } else {
                ret = <AauMixinListText
                    value={props.value.toString().split(',')}
                    enums={props.component.hasOwnProperty('enums') ? props.component['enums'] : {}}
                    type='num'
                />;
            }
            break;
        case 'list_join':
            align = 'left';
            if ( typeof(props.value) === "undefined" || props.value === null || props.value.toString() === '' ) {
                ret = '';
            } else {
                ret = <AauMixinListText
                    value={props.value.toString().split(',')}
                    enums={props.component.hasOwnProperty('enums') ? props.component['enums'] : {}}
                    type='join'
                />;
            }
            break;
        case 'int':
            align = 'right';
            ret = props.value.toString();
            break;
        case 'float':
            align = 'right';
            const defaultPrecision = 2;
            const precision = props.component.hasOwnProperty('precision') ? props.component['precision'] : defaultPrecision;
            ret = Number(props.value).toFixed(precision);
            break;
        case 'str_compare':
            align = 'center';

            const field1 = props.component.hasOwnProperty('field1') ? props.component['field1'] : '';
            const value1 = props.dataJson.hasOwnProperty(field1) ? props.dataJson[field1] : '-';
            const field2 = props.component.hasOwnProperty('field2') ? props.component['field2'] : '';
            const value2 = props.dataJson.hasOwnProperty(field2) ? props.dataJson[field2] : '-';

            ret = <AauMixinStrCompare
                value1={value1}
                value2={value2}
                colorOK= {null}
                colorNULL= {null}
            />;
            break;
        case 'str_date':
        case 'str_timestamp':
            align = 'center';

            ret = <AauMixinTimestamp
                value={props.value !== null ? (props.mixin === "str_timestamp" ? Number(props.value) : props.value) : null}
                mode='display'
                date_format={props.component.hasOwnProperty('date_format') ? props.component['date_format'] : 'DD/MM/YYYY HH:mm:ss'}
            />;
            break;
        case 'str_date-diff-from-now':
            align = 'center';

            ret = <AauMixinTimestamp
                value={props.value !== null ? Number(props.value) : null}
                mode='compare'
                less={props.component.hasOwnProperty('less') ? props.component['less'] : 30}
                greater={props.component.hasOwnProperty('greater') ? props.component['greater'] : 70}
                date_format={props.component.hasOwnProperty('date_format') ? props.component['date_format'] : 'DD/MM/YYYY H:m:s'}
            />;
            break;
        case 'str_timestamp-diff-from-now':
            align = 'center';

            ret = <AauMixinTimestamp
                value={props.value !== null ? Number(props.value) : null}
                mode='display'
                less={props.component.hasOwnProperty('less') ? props.component['less'] : 30}
                greater={props.component.hasOwnProperty('greater') ? props.component['greater'] : 70}
                date_format={props.component.hasOwnProperty('date_format') ? props.component['date_format'] : 'DD/MM/YYYY HH:mm:ss'}
            />;
            break;
        case 'str_euro':
        case 'str_dollar':
        case 'str_pound':
        case 'str_yen':
        case 'str_rubble':
            align = 'right';

            ret = <AauMixinStrAmount
                value={Number(props.value)}
                moneyType={props.mixin.split('_')[1].toLowerCase() as MoneyType}
            />;
            break;
        case 'str_unit':
        case 'str_octect':
            align = 'right';

            ret = <AauMixinStrUnit
                value={Number(props.value)}
                precision={props.component.hasOwnProperty('precision') ? props.component['precision'] : parseInt(localStorage.getItem('precision'))}
                unit={props.component.hasOwnProperty('unit') ? props.component['unit'] : 'o'}
                multiplier={props.component.hasOwnProperty('multiplier') ? props.component['multiplier'] : 1}
            />;
            break;
        case 'str_bytes':
            align = 'right';

            ret = <AauMixinStrUnit
                value={Number(props.value)}
                precision={props.component.hasOwnProperty('precision') ? props.component['precision'] : parseInt(localStorage.getItem('precision'))}
                unit='B'
            />;
            break;
        case 'str_bit':
            align = 'right';
            ret = <AauMixinStrUnit
                value={Number(props.value)}
                precision={props.component.hasOwnProperty('precision') ? props.component['precision'] : parseInt(localStorage.getItem('precision'))}
                unit='b'
            />;
            break;
        case 'str_mask':
            align = 'center';

            const usedMask = props.component.hasOwnProperty('used_mask') ? props.component['used_mask'] : false;
            const maskFieldName = props.component.hasOwnProperty('mask_field') ? props.component['mask_field'] : '';
            const maskField = usedMask && props.dataJson.hasOwnProperty(maskFieldName) ? props.dataJson[maskFieldName] : false;

            ret = <AauMixinStrMask
                value={props.value !== null ? props.value.toString() : null}
                maskChar={props.component.hasOwnProperty('mask_char') ? props.component['mask_char'] : '*'}
                maskLen={props.component.hasOwnProperty('mask_len') ? props.component['mask_len'] : 10}
                maskEnabled={!maskField}
            />;
            break;
        case 'str_enum':
            align = 'center';

            const values = props.component.hasOwnProperty('enums') ? props.component['enums'] : {};

            ret = values.hasOwnProperty(props.value) ? values[props.value.toString()] : props.value;
            break;
        case 'str_split':
            align = 'center';
        
            const separator = props.component.hasOwnProperty('separator') ? props.component['separator'] : '?';
            let formatStr = props.component.hasOwnProperty('formatStr') ? props.component['formatStr'] : '';
            const split_values = props.value.toString().split(separator);
            const enums1 = props.component.hasOwnProperty('enums') ? props.component['enums'] : {};
        
            if (props.value.toString() !== 'NC') {
                split_values.forEach((val, index) => formatStr = formatStr.replace(`{${index}}`, val));
                ret = <AauMixinStrLink
                    url={`${formatStr.split('://')[1]}`}
                    scheme={`${formatStr.split('://')[0]}` as AauMixinStrLinkScheme}
                    text={enums1.hasOwnProperty(props.value.toString()) ? enums1[props.value.toString()] : props.value.toString()}
                />;
            } else {
                ret = '-'
            }
            break;
        case 'int_color':
        case 'float_color':
            align = 'center';

            ret = <AauMixinBadge
                value={Number(props.value)}
                less={props.component.hasOwnProperty('less') ? props.component['less'] : 30}
                greater={props.component.hasOwnProperty('greater') ? props.component['greater'] : 70}
                precision={props.component.hasOwnProperty('precision') ? props.component['precision'] : parseInt(localStorage.getItem('precision'))}
            />;
            break;
        case 'rating':
            align = 'center';

            ret = <AauMixinRating
                value={Number(props.value)}
            />;
            break;
        case 'btn_state':
            align = 'center';

            ret = <AauMixinBtnBool
                value={props.value}
                lang={props.lang}
            />;
            break;
        case 'toggle':
        case 'switch':
            align = 'center';

            ret = <AauMixinSwitchBool
                value={props.value}
            />;
            break;
        case 'toggle_state':
        case 'switch_state':
            align = 'center';

            ret = <AauMixinSwitchBool
                value={props.value}
            />;
            break;
        case 'dynamicForm':
            ret = Object.keys(props.value[props.columnKey]).map(rid => {
                let ret = null;
                const o = props.value[props.columnKey][rid];
                if ( !o.hasOwnProperty('substate') || (o.hasOwnProperty('substate') && (o['substate'] === 1 || o['substate'] === true)) ) {
                    const subContent = Object.keys(o).map(cid => {
                        let subret = null;

                        if (cid !== 'substate') {
                            subret = <li key={`${props.rowKey}_${rid}_${cid}`}>
                                <b>{props.lang.getText(cid)}</b>: {o[cid]}
                            </li>;
                        }

                        return subret;
                    });
                    ret = <ul key={`${props.rowKey}_${rid}`} className='mb-1'>{subContent}</ul>;
                }
                return ret
            });
            break;
        // case 'str_to_icon':
        //     ret = <AauMixinStrToIcon
        //         value={props.value.toString()}
        //         options={props.component.hasOwnProperty('options') ? props.component['options'] : {}}
        //     />;
        //     break;
        case 'avatar':
        case 'persona':
            align = 'center';

            ret = <AauMixinAvatar
                displayName={props.value.toString()}
            />;
            break;
        case 'progress':
            align='center';
            ret = <AauMixinProgress
                value={Number(props.value)}
                thickness={`large`}
                validationState={true}
            />;
            break;
        case 'str_timeago':
            ret = <AauMixinTimeAgo
                value={props.value !== null ? Number(props.value) : 0}
                type="ts"
            />;
            break;
        case 'str_datetimeago':
            ret = <AauMixinTimeAgo
                value={props.value !== null ? props.value.toString() : '0'}
                type="dt"
            />;
            break;
        case 'json':
        case 'jsonb':
            ret = <AauMixinJson
                value={props.value}
            />;
            break;
            break;
        case 'str_int':
            align = props.component.hasOwnProperty('align') ? props.component['align'] : 'center';
            ret = `${props.value.toString()}${props.component.hasOwnProperty('unit') ? props.component['unit'] : ''}`;
            break;
        case 'str':
            align = props.component.hasOwnProperty('align') ? props.component['align'] : 'center';
            const enums = props.component.hasOwnProperty('enums') ? props.component['enums'] : null;
            let value = props.value !== null ? props.value.toString() : ''
            ret = enums !== null && enums.hasOwnProperty(props.value) ? enums[props.value.toString()] : value;
            break;
        case 'grp':
            ret = <AauMixinBtnGroup
                key={`btngroup_${props.dataJson[props.columnid]}`}
                btns={props.component.hasOwnProperty('grpItems') ? props.component['grpItems'] : {}}
                dataJson={props.dataJson}
                refreshTable={props.refreshTable}
                columnid={props.columnid}
                lang={props.lang}
                maxButton={6}
            />;
            break;
        default:
            align = 'center';
            ret = 'Not Implemented';
    }

    if ( props.canView && props.columnKey === 'name' ) {;
        ret = <Dialog
            modalType={`alert`}
            key={``}
        >
            <DialogTrigger disableButtonEnhancement>
                <Button
                    size={`small`}
                    appearance={`transparent`}
                    onClick={() => {retrieveData(`${window.location.pathname}/${props.viewAction}/${props.dataJson[props.columnid]}`)}}
                >
                    <Caption1>{ret}</Caption1>
                </Button>
            </DialogTrigger>
            <DialogSurface
                className={`table-display-dialog`}
                style={{
                    maxWidth: '948px',
                    maxHeight: '80vh'
                }}
            >
                <DialogBody
                    style={{
                        width: '100%',
                    }}
                >
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        {window.knowit.SITENAME} - {ret}
                    </DialogTitle>
                    <DialogContent
                        style={{
                            width: '900px',
                            height: `calc(70vh - 48px)`,
                            paddingRight: '24px'
                        }}
                    >
                        {
                            displayItem !== null ? <AauComponentForm
                                columns={displayItem['columns']}
                                item={displayItem['item']}
                                readonly={true}
                                saveHandler={null}
                                lang={props.lang}
                            /> : <AauMixinLoader />
                        }
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">{props.lang.getBtn('close')}</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>;
    }

    return <td key={props.rowKey} className={`pos-relative text-${align} p-1 ${props.canEdit? 'cursor-pointer': ''}`}
        onDoubleClick={e => {
        if ( props.canEdit ) {
            setFastEditOpen(true);
        }
    }}
        onMouseOver={e => {setDisplayEditIcon(props.canEdit && true)}}
        onMouseOut={e => {setDisplayEditIcon(props.canEdit && false)}}
    >
        {ret}
        {
            displayEditIcon && <Button
                size="small"
                icon={<Edit28Regular />}
                appearance="primary"
                color={tokens.colorBrandForeground1}
                className={`pos-absolute right-0 z-10 mt-0 mr-1`}
            />
        }
        {
            fastEditOpen && <AauComponentForm
                key={`fast_edit_popup_${props.rowKey}_${props.dataJson[props.columnid]}`}
                title={`${props.lang.getText('edit')}: ${props.dataJson.hasOwnProperty('name') ? props.dataJson['name'] : ''} (${props.dataJson[props.columnid]})`}
                columns={props.columnForm}
                item={props.dataJson}
                readonly={false}
                saveHandler={formData => {
                    submitHandler(formData, props.dataJson[props.columnid], "save");
                }}
                saveActionHandler={props.canSaveAndAction !== null ? formData => {
                    submitHandler(formData, props.dataJson[props.columnid], "saveAndPush");
                } : null}
                saveAndActionLabel={props.canSaveAndAction !== null ? props.saveAndActionLabel : null}
                dialogMode={true}
                dialogOpen={fastEditOpen}
                setDialogOpen={setFastEditOpen}
                lang={props.lang}
            />
        }
    </td>;
};
AauComponentTableBodyColumn.defaultProps = AauComponentTableBodyColumnPropsDefault;
