/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Subtitle2} from "@fluentui/react-components";
import {ProgressBar} from '@fluentui/react-components';

export interface AauMixinProgressProps {
    value: number;
    max?: number;
    validationState?: boolean;
    validationStateWarning?: number;
    validationStateritical?: number;
    thickness?: 'medium' | 'large';
}

const defaultProps = {
    max: 100,
    validationState: false,
    validationStateWarning: 60,
    validationStateritical: 80,
    thickness: 'medium'
} as AauMixinProgressProps;

export const AauMixinProgress: FC<AauMixinProgressProps> = props => {
    return <Subtitle2>
        {props.value}%
        <ProgressBar
            as={`div`}
            // shape={`rectangular`}
            value={props.value}
            max={props.max}
            thickness={props.thickness}
            color={props.validationState ? (props.value > props.validationStateritical ? 'error' : (props.value > props.validationStateWarning ? 'warning' : 'success')) : 'brand'}
        />
    </Subtitle2>;
};
AauMixinProgress.defaultProps = defaultProps;
