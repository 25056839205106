/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import './aauComponentLogFile.css';
import {tokens} from '@fluentui/react-theme';

export interface AauComponentLogFileProps {
    value?: string;
    loglevel?: string;
    searchPattern?: string;
}

const defaultProps = {
    value: 'N.C.',
    loglevel: 'all',
    searchPattern: '',
} as AauComponentLogFileProps;

export const AauComponentLogFile: FC<AauComponentLogFileProps> = props => {
    const colorLine = (line:string):string => {
        let ret;
        if ( line.indexOf(' DEBUG ') !== -1) {
            ret = tokens.colorNeutralForeground1;
        } else if ( line.indexOf(' INFO ') !== -1) {
            ret = tokens.colorPaletteBlueBorderActive;
        } else if ( line.indexOf(' WARN ') !== -1) {
            ret = tokens.colorPalettePeachBorderActive;
        } else if ( line.indexOf(' ERROR ') !== -1) {
            ret = tokens.colorPaletteCranberryBorderActive;
        } else if ( line.indexOf(' FATAL ') !== -1) {
            ret = tokens.colorPalettePeachBorderActive;
        } else if ( line.indexOf(' TRACE ') !== -1) {
            ret = tokens.colorPaletteDarkRedForeground2;
        } else {
            ret =  tokens.colorNeutralForeground1;
        }

        return ret;
    };

    const hideLine = (line:string):string => {
        let ret;

        const nologlevel = line.indexOf(' DEBUG ') === -1 && line.indexOf(' INFO ') === -1 && line.indexOf(' WARN ') === -1 && line.indexOf(' ERROR ') === -1;

        if ((line.indexOf(' DEBUG ') !== -1 && ['all', 'debug'].includes(props.loglevel) && !nologlevel)
            || (line.indexOf(' INFO ') !== -1 && ['all', 'debug', 'info'].includes(props.loglevel) && !nologlevel)
            || (line.indexOf(' WARN ') !== -1 && ['all', 'debug', 'info', 'warn'].includes(props.loglevel) && !nologlevel)
            || (line.indexOf(' ERROR ') !== -1 && ['all', 'debug', 'info', 'warn', 'error'].includes(props.loglevel) && !nologlevel)
            || (['all'].includes(props.loglevel) && nologlevel)
        ) {
            ret = 'd-block';
        }  else {
            ret = 'd-none';
        }

        return ret;
    };

    let foundIndex = 0;

    return (
        <div className='width-100-min fit-content' style={{backgroundColor: tokens.colorPalettePlatinumBackground2}}>
            {
                props.value.split('\n').map((line, index) => {
                    let ret = null;

                    if ( line !== '' ) {
                        ret = <span
                            id={`found${foundIndex}`}
                            key={`line_${index}`}
                            style={{
                                color: colorLine(line)
                            }}
                            className={`${hideLine(line)} p-1`}
                            dangerouslySetInnerHTML={{__html: (props.searchPattern !== '' && props.value.indexOf(props.searchPattern)) ? line.replace(new RegExp(props.searchPattern, 'gi'), `<span id='' style='background-color: yellow; font-weight: bold'>${props.searchPattern}</span>`) : line}}
                        />;
                    }
                    return ret;
                })
            }
            <div className='float-clear' />
        </div>
    );
};
AauComponentLogFile.defaultProps = defaultProps;
