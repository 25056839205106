/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Switch} from '@fluentui/react-components';


export interface AauMixinSwitchBoolProps {
    value:  any;
    label?: string;
}

const defaultProps = {
    label: null
} as AauMixinSwitchBoolProps;

export const AauMixinSwitchBool: FC<AauMixinSwitchBoolProps> = props => {
    const {value} = props;

    return <Switch
        style={{textAlign: 'left'}}
        checked={value === 1 || value === true ? true : false}
        label={props.label}
    />;
};
