/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {
    MenuList, MenuTrigger, MenuPopover, MenuItem, Menu, MenuGroup, MenuButton, Link, Toolbar, ToolbarButton
} from "@fluentui/react-components";

import {AauMixinBadgeVersion} from "../aauMixinBadgeVersion/aauMixinBadgeVersion";
import {subtle} from "crypto";

export interface AauComponentMenuHorizontalProps {
    data: object;
}

const defaultProps = {

} as AauComponentMenuHorizontalProps;

export const AauComponentMenuHorizontal: FC<AauComponentMenuHorizontalProps> = props => {

    const {data} = props;

    let sitename = null;
    if ( window.knowit.LOGO !== '' ) {
        sitename = <img alt='Site Logo' src={window.knowit.LOGO} width="80" />;
    } else {
        sitename = window.knowit.SITENAME;
    }

    let draw_Menu = (data, parent: boolean=true) => {
        return Object.keys(data).map(key => {
            let ret = null;

            if (data[key].hasOwnProperty('href')) {
                ret = <Link
                    key={`item_menu_${key}_link`}
                    appearance="subtle"
                    href={data[key]['href']}
                    style={{textDecoration: "none"}}
                >
                    <MenuItem>
                        {key}
                        {
                            data[key] !== null && <AauMixinBadgeVersion
                                text={data[key]['badge_text']}
                                state={data[key]['badge_state']}
                            />
                        }
                    </MenuItem>
                </Link>;
            } else if (parent === true) {
                ret = <Menu
                    key={`main_menu_${key}`}
                    hoverDelay={0}
                    openOnHover={true}
                    closeOnScroll={true}
                >
                    <MenuTrigger disableButtonEnhancement>
                        <MenuButton appearance={`subtle`}>{key}</MenuButton>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            {draw_Menu(data[key], false)}
                        </MenuList>
                    </MenuPopover>
                </Menu>;
            } else if (parent === false){
                ret = <Menu
                    key={`sub_menu_${key}`}
                    hoverDelay={0}
                    openOnHover={true}
                    closeOnScroll={true}
                >
                    <MenuTrigger disableButtonEnhancement>
                        <MenuItem>{key}</MenuItem>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            {draw_Menu(data[key], false)}
                        </MenuList>
                    </MenuPopover>
                </Menu>;
            }

            return ret;
        })
    }

    return <Toolbar className={`mt-0`}>
        <ToolbarButton
            as={`button`}
            onClick={() => window.location.href = window.knowit.FRONTURL}
        >
            {sitename}
        </ToolbarButton>
        <MenuGroup>
            {draw_Menu(data)}
        </MenuGroup>
    </Toolbar>;
};
AauComponentMenuHorizontal.defaultProps = defaultProps;
