/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//https://particles.js.org/samples/index.html#growing

import React, {FC, useState} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';

import {AauFormInput} from '../aauFormInput/aauFormInput';
import {tokens} from "@fluentui/react-theme";
import {Field} from "@fluentui/react-components";
import {Button} from "@fluentui/react-components";
import {People24Regular, Password24Regular, Key24Regular, WindowSettings20Filled} from "@fluentui/react-icons";

import { useMsal } from '@azure/msal-react';

export interface AauComponentLoginProps {
    lang: AauToolsLang;
    processLogin: any;
}

const defaultProps = {
} as AauComponentLoginProps;

export const AauComponentLogin: FC<AauComponentLoginProps> = props => {
    const [state, setState] = useState({login: '', password: ''});

    const { instance } = useMsal();

    const updateDataLogin = (keyName: string, value: any, valid: boolean): void => {
        const dataTmp = JSON.parse(JSON.stringify(state));
        dataTmp[keyName.split('-')[0]] = value;
        setState(dataTmp);
    };

    return <div
        className={`center-screen width-px-300 height-px-350 pos-absolute p-10`}
        style={{backgroundColor: tokens.colorNeutralShadowKeyDarker}}
    >
        <div className={`p-3 pl-8 pr-8`}>
            <a className={``} href='/'>
                {
                    window.knowit.LOGO !== '' ? <img
                        className={`height-px-70 mx-auto mb-4`}
                        alt={`Site logo`}
                        src={window.knowit.LOGO}
                    /> : window.knowit.SITENAME
                }
            </a>
        </div>
        <Field
            className={`mb-5`}
        >
            <AauFormInput
                key={`login-ctrl`}
                itemKey={`login-ctrl`}
                name={`login`}
                label={''}
                prefix={<People24Regular />}
                parentFunction={updateDataLogin}
                style={{color: tokens.colorBrandBackground}}
            />
        </Field>
        <Field
            className={`mb-5`}
            style={{color: tokens.colorBrandBackground}}
        >
            <AauFormInput
                key={`password-ctrl`}
                itemKey={`password-ctrl`}
                name={`password`}
                label={''}
                prefix={<Password24Regular />}
                parentFunction={updateDataLogin}
                type={`password`}
                keyPressHandler={e => {
                    if ( e.charCode === 13 ) { props.processLogin(state); return false; }
                }}
            />
        </Field>
        <Field
            className={`mt-10 mb-5`}
        >
            <Button
                appearance='primary'
                icon={<Key24Regular />}
                onClick={e => {
                    props.processLogin(state);
                }}
            >
                {props.lang.getBtn('login')}
            </Button>
        </Field>
        <Field
            className={`mb-2`}
        >
            <Button
                appearance='primary'
                icon={<Key24Regular />}
                onClick={() => instance.loginRedirect()}
            >
                {props.lang.getBtn('login_sso_microsoft')}
            </Button>
        </Field>
    </div>;
};
AauComponentLogin.defaultProps = defaultProps;