/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import './variables.scss';
import './_iaau.scss';

import * as FluentUiIcon from "@fluentui/react-icons"
import {tokens} from "@fluentui/react-components";

export type aauSize = 'small' | 'medium' | 'large';

// export type aauMixinIconsEnum = '' | 'address-book' | 'airplane' | 'angry' | 'arrow-big-redo' | 'arrow-big-undo' |
//     'arrow-down-left' | 'arrow-down-right' | 'arrow-down' | 'arrow-forward' | 'arrow-left' | 'arrow-redo' |
//     'arrow-reply' | 'arrow-right' | 'arrow-undo' | 'arrow-up-left' | 'arrow-up-right' | 'arrow-up' |
//     'attachment' | 'backward' | 'barcode' | 'bookmark' | 'books' | 'brightness-contrast' | 'bubbles' |
//     'bug' | 'burger-1' | 'burger-close' | 'calculator' | 'camera' | 'cancel-trash' | 'cart' | 'checkmark' |
//     'chrome' | 'circle-down' | 'circle-left' | 'circle-right' | 'circle-up' | 'clipboard' | 'clock' | 'cloud-download'
//     | 'cloud-trash' | 'cloud-upload' | 'cog' | 'coin-dollar' | 'coin-euro' | 'coin-pound' | 'coin-yen' | 'compass' |
//     'connection' | 'contrast' | 'copy-paste' | 'credit-card' | 'cross' | 'down' | 'drive-full' | 'drive' | 'dropbox' |
//     'droplet' | 'edge' | 'eject' | 'embed' | 'enlarge' | 'envelop' | 'equalizer-1' | 'equalizer' | 'eye-blocked' |
//     'eye-close' | 'eye-minus' | 'eye-open' | 'eye-plus' | 'eye' | 'facebook' | 'feed' | 'file-empty' | 'file-excel' |
//     'file-music' | 'file-openoffice' | 'file-pdf' | 'file-picture' | 'file-play' | 'file-video' | 'file-word' |
//     'file-zip' | 'filter' | 'filter-v2' | 'firefox' | 'first' | 'flickr' | 'floppy-disk-number' | 'floppy-disk' |
//     'folder-check' | 'folder-cross' | 'folder-download' | 'folder-minus' | 'folder-plus' | 'folder' | 'folder-upload' |
//     'forward' | 'gift' | 'github' | 'google-drive' | 'google-plus' | 'google' | 'happy' | 'headphones' | 'heart' |
//     'history' | 'home' | 'hour-glass' | 'image' | 'infinite' | 'instagram' | 'key' | 'last' | 'left' | 'linkedin' |
//     'link' | 'list' | 'location' | 'map' | 'menu-burger' | 'menu' | 'mic' | 'minus' | 'neutral' | 'next' | 'on-off' |
//     'opera' | 'paint-format' | 'pause' | 'paypal' | 'pencil' | 'pen' | 'phone-hang-up' | 'phone' | 'pie-chart' |
//     'pinterest' | 'play' | 'plus' | 'power-cord' | 'power' | 'previous' | 'print' | 'profile' | 'pushpin' | 'qrcode' |
//     'quotes-right' | 'reboot' | 'renren' | 'right' | 'rocket' | 'safari' | 'scissors' | 'search' | 'share' | 'shocked' |
//     'shrink' | 'shuffle' | 'sort-amount-asc' | 'sort-amount-desc' | 'spinner' | 'spotify' | 'star-empty' | 'star-full' |
//     'star-half' | 'star-quarter' | 'star-threequarter' | 'stats-bars' | 'stats-dots' | 'steam' | 'stop' | 'sun' |
//     'table' | 'terminal' | 'trash' | 'truck' | 'twitch' | 'twitter' | 'update' | 'up' | 'user-check' | 'user-king' |
//     'user-me' | 'user-minus' | 'user-plus' | 'user-SA' | 'users' | 'user' | 'view' | 'view-v2' | 'vine' |
//     'volume-decrease' | 'volume-high' | 'volume-increase' | 'volume-low' | 'volume-medium' | 'volume-mute' | 'warning' |
//     'warning-v2' | 'windows8' | 'windows' | 'wordpress' | 'wrench-alert' | 'wrench-bug' | 'wrench-incident' |
//     'wrench-serv-1' | 'wrench-serv-2' | 'wrench' | 'youtube' | 'zoom-in' | 'zoom-out'; // tslint:disable-line

export interface AauMixinIconProps {
    size?: aauSize;
    icon: string;
    source?: 'askanduse' | 'microsoft';
    style?: 'standard' | 'fab';
    color?: string;
}

const defaultProps = {
    size: 'medium',
    source: 'askanduse',
    style: 'standard',
    color: tokens.colorBrandForeground1
} as AauMixinIconProps;

export const AauMixinIcon: FC<AauMixinIconProps> = props => {

    let sizepx = '14px';
    if ( props.size === 'medium' ) sizepx = '16px';
    if ( props.size === 'large' ) sizepx = '18px';

    let content = null;

    let fluentUiStyle: object = {fontSize: 10, color: props.color};
    if ( props.style === 'fab' ) {
        fluentUiStyle = {};
    }

    if ( props.source === 'askanduse' ) {
        content  = <i
            style={{fontSize: sizepx}}
            className={`ms-Icon ms-Icon--${props.icon}`}
        />;
    } else if ( props.source === 'microsoft' ) {
        content = React.createElement(
            FluentUiIcon[props.icon],
            {style: fluentUiStyle},
            null
        )
    }

    return content;

};
AauMixinIcon.defaultProps = defaultProps;
