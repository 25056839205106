/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauComponentTableBodyColumn} from './tableBodyColumn';
import {AauComponentTableColumnProps} from './tableHeadColumn.d';
import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {tokens} from "@fluentui/react-theme";


export interface AauComponentTableBodyRowProps {
    rowKey: number;
    columns: AauComponentTableColumnProps[];
    data: object[];
    refreshTable?: () => void;
    columnid: string;
    canEdit: boolean;
    canView: boolean;
    canSaveAndAction: string|null;
    saveAndActionLabel?: string;
    viewAction?: string;
    lang: AauToolsLang;
    handlerPanelInfo?: (url:string) => void;
}

const defaultProps = {
    canEdit: false,
    canView: false,
    canSaveAndAction: null,
    viewAction: 'view'
} as AauComponentTableBodyRowProps;

export const AauComponentTableBodyRow: FC<AauComponentTableBodyRowProps> = props => {
    return <tr key={`data-${props.rowKey}`} style={{
        backgroundColor: props.rowKey % 2 === 0 ? tokens.colorNeutralBackground1 : tokens.colorNeutralShadowKey,
        color: tokens.colorNeutralForeground4
    }}>
        {
            Object.keys(props.columns).map(cid => {
                let ret = null;

                let columnForm = [];

                let col = props.columns[cid]

                const canViewTmp  = props.canView;
                let canEditTmp = props.canEdit && col['inline_edit'];
                if ( canEditTmp ) {
                    const displayRankField = col.hasOwnProperty('rank_field') ? col['rank_field'] : null;
                    const displayRankFilters = col.hasOwnProperty('rank_filters') ? col['rank_filters'] : {};
                    const displayState = col.hasOwnProperty('displayState') ? col['displayState'] : null;
                    const displayField = col.hasOwnProperty('displayField') ? col['displayField'] : null;
                    const displayFieldValue = col.hasOwnProperty('displayFieldValue') ? col['displayFieldValue'] : [];

                    if (displayState !== null && props.data.hasOwnProperty('state') && !displayState.includes(props.data['state'])) {
                        canEditTmp = false;
                    }

                    if (displayField !== null && props.data.hasOwnProperty(displayField) && !displayFieldValue.includes(props.data[displayField])) {
                        canEditTmp = false;
                    }

                    if (displayRankField !== null
                        && props.data.hasOwnProperty(displayRankField)
                        && ((
                                displayRankFilters.hasOwnProperty(props.data[displayRankField])
                                && displayRankFilters[props.data[displayRankField].toString()] === false
                            ) || !displayRankFilters.hasOwnProperty(props.data[displayRankField]))
                    ) {
                        canEditTmp = false;
                    }

                    columnForm.push(col);
                }

                if (col['state'] === true) {
                    ret = <AauComponentTableBodyColumn
                        key={`td_${props.rowKey}_${col['name']}`}
                        rowKey={props.rowKey}
                        columnKey={col['name']}
                        value={props.data[col['name']]}
                        dataJson={props.data}

                        mixin={col['mixin']}
                        component={col['component']}
                        refreshTable={props.refreshTable}
                        columnid={props.columnid}
                        columnForm={columnForm}
                        canView={canViewTmp}
                        canEdit={canEditTmp}
                        canSaveAndAction={props.canSaveAndAction}
                        saveAndActionLabel={props.saveAndActionLabel}
                        viewAction={props.viewAction}
                        lang={props.lang}
                        handlerPanelInfo={props.handlerPanelInfo}
                    />;
                }

                return ret;
            })
        }
    </tr>;
};
AauComponentTableBodyRow.defaultProps = defaultProps;
