/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import axios from 'axios';

import Cookies from 'js-cookie';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {toast} from 'react-toastify';
import format from 'string-format';

import {
    Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Menu,
    MenuList, MenuPopover, MenuTrigger, Tooltip, Toolbar, ToolbarButton, tokens
} from "@fluentui/react-components";

import {MoreHorizontal24Filled, Dismiss24Regular} from "@fluentui/react-icons";
import {Link} from 'react-router-dom';
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";

export interface AauMixinBtnGroupProps {
    btns: object;
    dataJson: object;
    refreshTable?: () => void;
    columnid: string;
    lang: AauToolsLang;
    optionalMode?: boolean;
    maxButton?: number;
}

const defaultProps = {
    optionalMode: true,
    maxButton: 15
} as AauMixinBtnGroupProps;

export const AauMixinBtnGroup: FC<AauMixinBtnGroupProps> = props => {
    const btnsOptional = [];
    const btnsMandatory = [];

    Object.keys(props.btns).forEach((btnKey, key) => {
        const btn = props.btns[btnKey];

        const optional = btn['optional'];
        const c = btn['component'];

        const btnActionName:string = btnKey;
        const confirm:boolean = c.hasOwnProperty('confirm') ? c.confirm : false;
        const displayRankField = c.hasOwnProperty('rank_record_field') ? c.rank_record_field : {};
        const displayRankFilters = c.hasOwnProperty('rank_filters') ? c.rank_filters : {};
        const displayState = c.hasOwnProperty('displayState') ? c.displayState : null;
        const displayField = c.hasOwnProperty('displayField') ? c.displayField : null;
        const displayFieldValue = c.hasOwnProperty('displayFieldValue') ? c.displayFieldValue : [];
        const formatStr = c.hasOwnProperty('formatStr') ? c.formatStr : '#';

        let changeDesignBool = false;
            if ( c.hasOwnProperty('change_design') && props.dataJson['state'] === true ) {
            if ( c.hasOwnProperty('change_design') && props.dataJson['knowitlastactions'] !== null ) {
                let la = props.dataJson['knowitlastactions'].split(',')
                if ( (la.length >= 2 && (c.change_design.action.includes(la[0]) && la[1] !== btnKey)) || c.change_design.action.includes(la[1])  ){
                    changeDesignBool = true;
                }
            }
        }

        const admin = window.location.pathname.split('/')[1] === 'admin' ? 'admin/' : '';
        const module = window.location.href.split('/').reverse()[0];

        let display = true;
        if (displayState !== null && props.dataJson.hasOwnProperty('state') && !displayState.includes(props.dataJson['state'])) {
            display = false;
        }

        if (display !== false && displayField !== null && props.dataJson.hasOwnProperty(displayField) && !displayFieldValue.includes(props.dataJson[displayField])) {
            display = false;
        }

        // console.log('-----------');
        // console.log(display);
        // console.log(displayRankField);
        // console.log(displayRankField !== null);
        // console.log(props.dataJson.hasOwnProperty(displayRankField));
        // console.log(props.dataJson[displayRankField]);
        // console.log(displayRankFilters.hasOwnProperty(props.dataJson[displayRankField]));
        // console.log(displayState);
        // console.log(props.dataJson.hasOwnProperty('state'));
        // console.log(!displayState.includes(props.dataJson['state']));
        // console.log('-----------');

        if (display !== false && displayRankField !== null
            && props.dataJson.hasOwnProperty(displayRankField)
            && ((
                    displayRankFilters.hasOwnProperty(props.dataJson[displayRankField])
                    && displayRankFilters[props.dataJson[displayRankField]] === false
                ) || !displayRankFilters.hasOwnProperty(props.dataJson[displayRankField]))
        ) {
            display = false;
        }

        let ret = null;
        if ( display === true ) {
            ret = {
                name: btn['name'],
                label: btn['label'],
                dialog: btn['dialog'],
                icon: c.hasOwnProperty('iconazure') ? c['iconazure'] : (c.hasOwnProperty('icon') ? c['icon'] : 'windows'),
                confirm: confirm,
                as: btn['mixin'] === 'btn_link_with_url' ? 'a' : 'button',
                link: btn['mixin'] === 'btn_link_with_url' ? format(formatStr, props.dataJson) : `/${admin}${module}/${btnActionName}/${props.dataJson[props.columnid]}`,
                appearance: changeDesignBool
            }

            if (props.optionalMode && optional) {
                btnsOptional.push(ret);
            } else {
                btnsMandatory.push(ret);
            }
        }
    });

    return <Toolbar key={`toolbar_${props.dataJson[props.columnid]}`} className={`width-px-${props.maxButton*32}-max float-right`} size={'small'} aria-label="Datatable Row Toolbar">
        {
            btnsMandatory.map(btn => {
                let ret;

                if ( btn['confirm'] === true ) {
                    ret = <Dialog modalType={`alert`} key={`button_confirm_${btn['name']}_${props.dataJson[props.columnid]}`}>
                        <DialogTrigger disableButtonEnhancement>
                            <Tooltip content={btn['label']} relationship="description">
                                <ToolbarButton
                                    key={`${btn['name']}_${props.dataJson[props.columnid]}`}
                                    icon={<AauMixinIcon size={'small'} icon={btn['icon']}  />}
                                    appearance={btn['appearance'] === true ? 'primary' : 'subtle'}
                                    as={'button'}
                                />
                            </Tooltip>
                        </DialogTrigger>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle
                                    action={
                                        <DialogTrigger action="close">
                                            <Button
                                                appearance="subtle"
                                                aria-label="close"
                                                icon={<Dismiss24Regular />}
                                            />
                                        </DialogTrigger>
                                    }
                                >
                                    {window.knowit.SITENAME}
                                </DialogTitle>
                                <DialogContent>
                                    {`${btn['dialog']} de ${props.dataJson.hasOwnProperty('name') ? props.dataJson['name'] : ''} (${props.dataJson[props.columnid]})`}
                                    <br/>
                                    <br/>
                                    {props.lang.getText('dialog_confirm_text')}
                                </DialogContent>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="secondary">{props.lang.getBtn('cancel')}</Button>
                                    </DialogTrigger>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance="primary" onClick={() => {
                                            axios.get(
                                                `/rest${btn['link']}`,
                                                {
                                                    baseURL: `${window.knowit.BACKURL}`,
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                                    }
                                                }
                                            ).then(res => {
                                                const msgToast = res.data.action_return;
                                                if (res.data.success === true) {
                                                    toast.success(msgToast.replace('%s', res.data.name));
                                                } else {
                                                    toast.error(msgToast.replace('%s', res.data.name));
                                                }
                                                props.refreshTable();
                                            }).catch(err => {
                                                const unauthorizedError = 401;
                                                if (err.response.data['code'] === unauthorizedError) {
                                                    Cookies.remove('JWT');
                                                    Cookies.remove('user');
                                                    Cookies.remove('rank');
                                                    Cookies.remove('displayname');
                                                    window.location.href = `/login?redirect=${window.location.pathname}`;
                                                } else {
                                                    const msgToast = props.lang.getError(err.response.data['code']);
                                                    toast.error(msgToast.replace('%s', ''));
                                                }
                                            });
                                        }}>
                                            {props.lang.getBtn('confirm')}
                                        </Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>;
                } else if ( btn['as'] === 'a' && btn['confirm'] === false ) {
                    ret = <Tooltip key={`${btn['name']}_${props.dataJson[props.columnid]}_tooltip`} content={btn['label']} relationship="description">
                        <ToolbarButton
                            key={`${btn['name']}_${props.dataJson[props.columnid]}`}
                            icon={<AauMixinIcon size={'small'} icon={btn['icon']} />}
                            as={btn['as']}
                            appearance={btn['appearance'] === true ? 'primary' : 'subtle'}
                            href={btn['link']}
                            target={'_blank'}
                        />
                    </Tooltip>;
                } else {
                    ret = <Tooltip key={`${btn['name']}_${props.dataJson[props.columnid]}_tooltip`} content={btn['label']} relationship="description">
                        <Link key={`${btn['name']}_${props.dataJson[props.columnid]}_link`} to={{pathname: btn['link']}} style={{textDecoration: 'none'}}>
                            <ToolbarButton
                                key={`${btn['name']}_${props.dataJson[props.columnid]}`}
                                icon={<AauMixinIcon size={'small'} icon={btn['icon']} />}
                                as={btn['as']}
                                appearance={btn['appearance'] === true ? 'primary' : 'subtle'}
                                href={btn['link']}
                                target={`_blank`}
                            />
                        </Link>
                    </Tooltip>;
                }

                return ret;
            })
        }
        {
            btnsOptional.length > 0 ? <Menu key={`menu_showmore_${props.dataJson[props.columnid]}`}>
                <MenuTrigger disableButtonEnhancement>
                    <Tooltip content={props.lang.getBtn('showmoreaction')} relationship="description">
                        <ToolbarButton
                            key={`btn_showmore_${props.dataJson[props.columnid]}`}
                            icon={<MoreHorizontal24Filled />}
                        />
                    </Tooltip>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList className={'pt-6 pb-6 pr-3 pl-3'}>
                        {
                            btnsOptional.map(btn => {
                                let ret;

                                if ( btn['confirm'] === true ) {
                                    ret = <Dialog modalType={`alert`} key={`button_confirm_${btn['name']}_${props.dataJson[props.columnid]}`}>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button
                                                appearance={`subtle`}
                                                aria-labelledby={btn['label']}
                                                icon={<AauMixinIcon size={'small'} icon={btn['icon']} />}
                                                style={{alignItems: 'left', justifyContent: 'left', paddingLeft: 0, paddingRight: 0}}
                                            >
                                                {btn['label']}
                                            </Button>
                                        </DialogTrigger>
                                        <DialogSurface>
                                            <DialogBody>
                                                <DialogTitle
                                                    action={
                                                        <DialogTrigger action="close">
                                                            <Button
                                                                appearance="subtle"
                                                                aria-label="close"
                                                                icon={<Dismiss24Regular />}
                                                            />
                                                        </DialogTrigger>
                                                    }
                                                >
                                                    {window.knowit.SITENAME}
                                                </DialogTitle>
                                                <DialogContent>
                                                    {`${btn['dialog']} de ${props.dataJson.hasOwnProperty('name') ? props.dataJson['name'] : ''} (${props.dataJson[props.columnid]})`}
                                                    <br/>
                                                    <br/>
                                                    {props.lang.getText('dialog_confirm_text')}
                                                </DialogContent>
                                                <DialogActions>
                                                    <DialogTrigger disableButtonEnhancement>
                                                        <Button appearance="secondary">{props.lang.getBtn('cancel')}</Button>
                                                    </DialogTrigger>
                                                    <DialogTrigger disableButtonEnhancement>
                                                        <Button appearance="primary" onClick={() => {
                                                            axios.get(
                                                                `/rest${btn['link']}`,
                                                                {
                                                                    baseURL: `${window.knowit.BACKURL}`,
                                                                    headers: {
                                                                        'Content-Type': 'application/json',
                                                                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                                                                    }
                                                                }
                                                            ).then(res => {
                                                                const msgToast = res.data.action_return;
                                                                if (res.data.success === true) {
                                                                    toast.success(msgToast.replace('%s', res.data.name));
                                                                } else {
                                                                    toast.error(msgToast.replace('%s', res.data.name));
                                                                }
                                                                props.refreshTable();
                                                            }).catch(err => {
                                                                const unauthorizedError = 401;
                                                                if (err.response.data['code'] === unauthorizedError) {
                                                                    Cookies.remove('JWT');
                                                                    Cookies.remove('user');
                                                                    Cookies.remove('rank');
                                                                    Cookies.remove('displayname');
                                                                    window.location.href = `/login?redirect=${window.location.pathname}`;
                                                                } else {
                                                                    const msgToast = props.lang.getError(err.response.data['code']);
                                                                    toast.error(msgToast.replace('%s', ''));
                                                                }
                                                            });
                                                        }}>
                                                            {props.lang.getBtn('confirm')}
                                                        </Button>
                                                    </DialogTrigger>
                                                </DialogActions>
                                            </DialogBody>
                                        </DialogSurface>
                                    </Dialog>;
                                } else {
                                    ret = <Link to={{pathname: btn['link']}} style={{textDecoration: "none"}} key={`button_${btn['name']}_${props.dataJson[props.columnid]}`}>
                                        <Button
                                            appearance={`subtle`}
                                            aria-labelledby={btn['label']}
                                            icon={<AauMixinIcon size={'small'} icon={btn['icon']} />}
                                            style={{alignItems: 'left', justifyContent: 'left', paddingLeft: 0, paddingRight: 0}}
                                        >
                                            {btn['label']}
                                        </Button>
                                    </Link>;
                                }

                                return ret;
                            })
                        }
                    </MenuList>
                </MenuPopover>
            </Menu> : null
        }
    </Toolbar>
};
AauMixinBtnGroup.defaultProps = defaultProps;
