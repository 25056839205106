/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';

import {AauDynamicAccordionItem} from './aauDynamicAccordionItem';
import nextId from 'react-id-generator';

import {Button, Tooltip} from "@fluentui/react-components";
import {Add24Filled} from "@fluentui/react-icons";
import {AauFormField} from "../aauFormField/aauFormField";

import { Reorder } from "framer-motion"


export interface AauDynamicAccordionProps {
    readonly?: boolean;
    itemKey: string;
    name: string;
    columns: object[];
    value: object[];
    label: string;
    helper?: string | null;
    parentFunction?: any; // NOSONAR
    required?: boolean;
    substate?: boolean;
    titleStr?:  string;
    lang: AauToolsLang;
    template?: object;
    templateVersion?: number;
}

const defaultProps = {
    label: null,
    readonly: false,
    columns: [],
    value: [],
    required: false,
    parentFunction: null,
    helper: null,
    substate: false,
    titleStr: '',
    template: null,
    templateVersion: 1
} as AauDynamicAccordionProps;

export const AauDynamicAccordion: FC<AauDynamicAccordionProps> = props => {

    const numeric2 = 2;

    const subStateClick = (index:string):void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
        stateTmp[index]['substate'] = (stateTmp[index]['substate'] === 0 || stateTmp[index]['substate'] === false) ? (stateTmp[index]['substate'] === 0 ? 1 : true) : (stateTmp[index]['substate'] === 1 ? 0 : false);
        setState({
            isValid: state['isValid'],
            content: stateTmp,
            componentId: nextId()
        });
        if ( props.parentFunction !== null ) {
            const ret = {};
            ret[props.itemKey.split('_').reverse()[0]] = stateTmp;
            props.parentFunction(props.itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
    };

    const subDeleteClick = (index:string):void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
        const validTmp = JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')));

        if ( props.parentFunction !== null ) {
            const ret = {};
            ret[props.itemKey.split('_').reverse()[0]] = stateTmp.filter(o => o !== stateTmp[index]);
            props.parentFunction(props.itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
        setState({
            isValid: validTmp.filter(o => o !== validTmp[index]),
            content: stateTmp.filter(o => o !== stateTmp[index]),
            componentId: nextId()
        });
        let orderTmp = [];
        Object.keys(stateTmp.filter(o => o !== stateTmp[index])).forEach((key)=>orderTmp.push(Number(key)));
        setOrder(orderTmp);
    };

    const subCopyClick = (index:string, item:object):void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
        const validTmp = JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')));

        item['content']['substate'] = 0;
        stateTmp.splice(parseInt(index)+1,0,item['content']);
        validTmp.splice(parseInt(index)+1,0,validTmp[index])

        if ( props.parentFunction !== null ) {
            const ret = {};
            ret[props.itemKey.split('_').reverse()[0]] = stateTmp;
            props.parentFunction(props.itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
        setState({
            isValid: validTmp,
            content: stateTmp,
            expanded: state['expanded'],
            componentId: nextId()
        });
        let orderTmp = [];
        Object.keys(stateTmp).forEach((key)=>orderTmp.push(Number(key)));
        setOrder(orderTmp);
    };

    const subSetData = (index: number, item: object, valid: boolean): void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content']));
        const validTmp = JSON.parse(JSON.stringify(state['isValid']));

        stateTmp[index] = item;
        validTmp[index] = valid;

        if ( (state['isValid'][index] === true && valid === false) || JSON.stringify(state['content'][index]) !== JSON.stringify(item) ) {
            if ( props.parentFunction !== null ) {
                const ret = {};
                ret[props.name] = stateTmp;
                props.parentFunction(props.itemKey, ret, validTmp.filter(o => o !== true).length < 1 );
            }
            setState({
                isValid: validTmp,
                content: stateTmp,
                componentId: state['componentId']
            });
        }
    };

    const reorderSave = (): void => {
        let stateTmp = [];
        let validTmp = [];

        order.forEach(i => {
            stateTmp.push(state['content'][Object.keys(JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined'))))[i]]);
            validTmp.push(state['isValid'][Object.keys(JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined'))))[i]]);
        });

        if ( props.parentFunction !== null ) {
            const ret = {};
            ret[props.itemKey] = stateTmp;
            props.parentFunction(props.itemKey, ret, state['isValid'].filter(o => o !== true).length < 1 );
        }
        setState({
            isValid: validTmp,
            content: stateTmp,
            componentId: nextId()
        });
        let orderTmp = [];
        Object.keys(JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')))).forEach((key)=>orderTmp.push(Number(key)));
        setOrder(orderTmp)
    };

    const item = {
        isValid: (new Array(props.value.length)).fill(true),
        content: props.value,
        componentId: `${nextId()}`
    };
    const [state, setState] = useState<object>(item);

    let orderTmp = [];
    Object.keys(JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')))).forEach((key)=>orderTmp.push(Number(key)));
    const [order, setOrder] = useState<number[]>(orderTmp);

    return <AauFormField
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}
        content={<div className='mb-5' key={`${props.name}-formcontrol`}>
            {
                props.readonly ? null :
                    <Tooltip content={props.lang.getBtn('subform_add')} relationship="description" withArrow>
                        <Button
                            size={`small`}
                            appearance={`outline`}
                            icon={<Add24Filled />}
                            style={{marginTop: '-5px', marginLeft: '0px'}}
                            onClick={() => {
                                const stateTmp = JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')));
                                const validTmp = JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')));

                                const item = {};
                                if ( props.substate ) {
                                    item['substate'] = 0;
                                }

                                Object.keys(props.columns).forEach(cid => {
                                    const c: object = props.columns[cid];
                                    item[c['name']] = c.hasOwnProperty('default') ? c['default'] : null;
                                });

                                stateTmp.push(item);

                                setState({
                                    isValid: validTmp,
                                    content: stateTmp,
                                    componentId: nextId()
                                });

                                let orderTmp = [];
                                Object.keys(stateTmp).forEach((key)=>orderTmp.push(Number(key)));
                                setOrder(orderTmp);
                            }}
                        >
                            {props.lang.getBtn('subform_add')}
                        </Button>
                    </Tooltip>
            }
            <Reorder.Group
                axis="y"
                className='mt-3 p-0 clear-both'
                style={{
                    listStyle: 'none'
                }}
                values={order}
                onReorder={setOrder}
            >
                {
                    order.map(did => {
                        return <AauDynamicAccordionItem
                            key={`${props.name}_${state['componentId']}_${did}-accordion`}
                            dynamicKey={`${props.name}-${state['componentId']}_${did}`}
                            readonly={props.readonly}
                            columns={props.columns}
                            value={JSON.parse(JSON.stringify(state['content'].filter(o => typeof (o) !== 'undefined')))[did]}
                            substate={props.substate}
                            index={did.toString()}
                            isValid={JSON.parse(JSON.stringify(state['isValid'].filter(o => typeof (o) !== 'undefined')))[did]}
                            titleStr={props.titleStr}
                            subStateClick={subStateClick}
                            subDeleteClick={subDeleteClick}
                            subCopyClick={subCopyClick}
                            parentFunction={subSetData}
                            itemsCount={state['content'].length}
                            lang={props.lang}
                            reorderSave={reorderSave}
                            template={props.template}
                            templateVersion={props.templateVersion}
                        />;
                    })
                }
            </Reorder.Group>
        </div>}
    />;


    ;
};
AauDynamicAccordion.defaultProps = defaultProps;
