/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import CodeMirror from '@uiw/react-codemirror';
import Cookies from 'js-cookie';
import {AauFormField} from "../aauFormField/aauFormField";


export interface AauFormEditorCodeProps {
    label?: string;
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    value?: string;
    validator: object;
    parentFunction?: any;
    helper?: string | null;
    required?: boolean | null;
    hint?: string;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {},
    parentFunction: null,
    value: '',
    helper: null,
    required: false,
    hint: null
} as AauFormEditorCodeProps;

export const AauFormEditorCode: FC<AauFormEditorCodeProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (props.required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( props.parentFunction !== null ) {
                props.parentFunction(props.itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };
    
    const [state, setState] = useState<object>({
        'isValid': null,
        'content': props.value
    });

    if (state['isValid'] === null && props.readonly === false ) {
        validateField(props.value);
    }

    const isLogged = typeof (Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null;
    let profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};

    return <AauFormField
        className={`d-flow-root`}
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}
        hint={props.hint}
        content={
            props.readonly === true
            ? <pre 
                style={{
                    'whiteSpace': 'pre-wrap',
                    'wordWrap': 'break-word'
                }}
            >
                {state['content']}
            </pre>
            : <CodeMirror
                // id={itemKey}
                key={`${props.itemKey}-ctrl`}
                //name={name}
                value={state['content']}
                onChange={(value, viewUpdate) => validateField(value)}
                theme={(profil.hasOwnProperty('darkMode') ? profil['darkMode'] : false) === true ? 'dark' : 'light'}
                minHeight="400px"
                //indentWithTabs={true}
                //autoCloseBrackets={true}
                basicSetup={{
                    lineNumbers: true,
                    bracketMatching: true,
                    history: true,
                    foldGutter: true,
                    closeBrackets: true
                }}
            />
        }
    />;


;
};
AauFormEditorCode.defaultProps = defaultProps;

