/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC, useRef} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {AauComponentLogFile} from '../../Components/aauComponentLogFile/aauComponentLogFile';
import {AauMixinTabs} from '../../Components/aauMixinTabs/aauMixinTabs';
import {AauMixinFilters} from '../../Components/aauMixinFilters/aauMixinFilters';
import {AauMixinFabBar} from "../../Components/aauMixinFabBar/aauMixinFabBar";


export interface AauPageLogProps {
    maxHistory?: number;
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageLogProps;

export const AauPageLog: FC<AauPageLogProps> = props => {
    const firstLoadData = null;
    const {lang} = props;
    const {module, identifier, admin} = useParams();
    const [data, setData] = useState(firstLoadData);
    const [logLevel, setLogLevel] = useState<string>('all');
    const [nbrLigneToDisplay, setNbrLigneToDisplay] = useState<string>('5000');
    const [searchPattern, setSearchPattern] = useState<string>('');
    const [menuKey, setMenuKey] = useState<string>('0');
    const [streamEnabled, setStreamenabled] = useState<boolean>(false);
    const timeoutId = useRef(null);

    let content = <AauMixinLoader />;

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}/cloudlog/${identifier}?lines=${nbrLigneToDisplay}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    const refreshData = (from=null) => {
        axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/cloudlog/${identifier}?from=${data['info']['logs']['timestamp']}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            const d = res.data;
            const dTmp = JSON.parse(JSON.stringify(data));

            dTmp['info']['logs']['timestamp'] = d['info']['logs']['timestamp'];
            Object.keys(data['info']['logs']['logs']).forEach(name => {
                if ( d['info']['logs']['logs'].hasOwnProperty(name) ) {
                    dTmp['info']['logs']['logs'][name]['content'] = `${dTmp['info']['logs']['logs'][name]['content']}\n${d['info']['logs']['logs'][name]['content']}`;
                }
            });

            setData(dTmp);
            window.scroll(0, document.body.scrollHeight);
        }).catch(err => {
            const unauthorizedError = 401;
            if (err.response.status === unauthorizedError) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        let i = 1;
        const dataArray = [];

        Object.keys(data['info']['logs']['logs']).forEach(name => {
            dataArray.push({
                title: `${name} | ${data['info']['logs']['logs'][name]['start_since']}`,
                content: data['info']['logs']['logs'][name]['content'] === null ?
                    null : <AauComponentLogFile
                        value={data['info']['logs']['logs'][name]['content']}
                        loglevel={logLevel}
                        searchPattern={searchPattern}
                    />
            });
            i = i + 1;
        });

        content = <AauMixinTabs
            data={dataArray}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    const optionsNbrLigneToDisplay= [
        {key: '10', text: 'Afficher    10 lignes'},
        {key: '1000', text: 'Afficher  1000 lignes'},
        {key: '2500', text: 'Afficher  2500 lignes'},
        {key: '5000', text: 'Afficher  5000 lignes'},
        {key: '7500', text: 'Afficher  7500 lignes'},
        {key: '10000', text: 'Afficher 10000 lignes'},
        {key: '15000', text: 'Afficher 15000 lignes'},
        {key: '20000', text: 'Afficher 20000 lignes'}
    ];

    const optionsLogLevel = [
        {key: 'all', text: 'Tout afficher'},
        {key: 'debug', text: 'Afficher debug et plus'},
        {key: 'info', text: 'Afficher info et plus'},
        {key: 'warn', text: 'Afficher warn et plus'},
        {key: 'error', text: 'Afficher error et plus'}
    ];

    return (
        <div >
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='pos-absolute top-px-87'>
                <AauMixinFilters
                    lang={lang}
                    filters={[
                        {component: 'textbox', name: 'text', width: 200, changeHandler: newValue => {setSearchPattern(newValue);}, value: searchPattern},
                        {component: 'select', name: 'nbrLigneToDisplay', width: 200, 'options': optionsNbrLigneToDisplay, changeHandler: value => {
                            setNbrLigneToDisplay(value);
                            setData(null);
                        }, value: `Afficher ${nbrLigneToDisplay} lignes`},
                        {component: 'select', name: 'loglevel', width: 200, 'options': optionsLogLevel, changeHandler: value => {setLogLevel(value);},
                            value: (logLevel === 'all' ? 'Tout afficher' : `Afficher ${logLevel} et plus`)
                        }
                    ]}
                />
            </div>
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {content}
            </div>
            <AauMixinFabBar
                back={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                lang={props.lang}
                urlBasePath={`${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                fabs={{}}
            />
            {/*<AauMixinFab*/}
            {/*    lang={lang}*/}
            {/*    tooltip={lang.getBtn('log_modestream_on')}*/}
            {/*    pos_px_bottom={210}*/}
            {/*    iconazure='Play'*/}
            {/*    bgColor='l3-green'*/}
            {/*    fgColor='l3-black'*/}
            {/*    hidden={streamEnabled}*/}
            {/*    mouseClickHandler={() => {*/}
            {/*        timeoutId.current = setInterval(refreshData, 10000);*/}
            {/*        setStreamenabled(true);*/}
            {/*    }}*/}
            {/*/>*/}
            {/*<AauMixinFab*/}
            {/*    lang={lang}*/}
            {/*    tooltip={lang.getBtn('log_modestream_off')}*/}
            {/*    pos_px_bottom={210}*/}
            {/*    iconazure='Pause'*/}
            {/*    bgColor='l3-red'*/}
            {/*    fgColor='l3-black'*/}
            {/*    hidden={!streamEnabled}*/}
            {/*    mouseClickHandler={() => {*/}
            {/*        clearInterval(timeoutId.current);*/}
            {/*        setStreamenabled(false);*/}
            {/*    }}*/}
            {/*/>*/}
        </div>
    );
};
AauPageLog.defaultProps = defaultProps;
