/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {CheckboxChecked24Regular, CheckboxUnchecked24Regular} from "@fluentui/react-icons";
import {tokens} from "@fluentui/react-theme";


export interface AauMixinBtnBoolProps {
    value:  any;
    lang: AauToolsLang;
}

const defaultProps = {
} as AauMixinBtnBoolProps;

export const AauMixinBtnBool: FC<AauMixinBtnBoolProps> = props => {
    return (props.value === 1 || props.value === true === true) ? <CheckboxChecked24Regular
        style={{color: tokens.colorPaletteGreenBorderActive}}
   /> : <CheckboxUnchecked24Regular
       style={{color: tokens.colorPaletteRedBorderActive}}
   />;
};
AauMixinBtnBool.defaultProps = defaultProps;
