/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';
import AauToolsLang from '../aauToolsLang/aauToolsLang';

import {AauMixinAvatar} from "../aauMixinAvatar/aauMixinAvatar";
import {
    Button,
    Caption1,
    Text,
    tokens,
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
    Dialog, DialogTrigger, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions
} from "@fluentui/react-components";
import {Dismiss24Regular, LinkSquare24Regular, ApprovalsApp24Filled} from "@fluentui/react-icons"


export interface AauPageRecordHistoryCardProps {
    rowid: string,
    user: string,
    action: string,
    delta: object,
    timestamp: string,
    viewClickHandler: () => void
    rollbackClickHandler: () => void
    lang: AauToolsLang;
}

const defaultProps = {
    rollbackClickHandler: null
} as AauPageRecordHistoryCardProps;

export const AauPageRecordHistoryCard: FC<AauPageRecordHistoryCardProps> = props => {
    return <Card>
        <CardPreview logo={``}>
        </CardPreview>

        <CardHeader
            image={<AauMixinAvatar displayName={props.user} />}
            header={<Text weight="semibold">{props.user}</Text>}
            description={<Caption1>[{props.action}] {props.timestamp}</Caption1>}
            // description={
            //     <Body1>
            //         {props.name}
            //         <br/>
            //         <Caption1>[{props.module}] {props.timestamp}</Caption1>
            //     </Body1>
            // }
            // action={<Button appearance="transparent" icon={<Power24Filled style={{color: (props.state ? tokens.colorPaletteGreenForeground3 : tokens.colorPaletteRedForeground3) }} />} aria-label="Objet State" />}
        />

        <CardFooter>
            {props.delta !== null && props.viewClickHandler? <Button onClick={props.viewClickHandler} icon={<LinkSquare24Regular />} >Voir</Button> : null}
            {
                props.delta !== null && props.rollbackClickHandler !== null ? <Dialog>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            aria-labelledby={'rollback'}
                            icon={<ApprovalsApp24Filled />}
                        >
                            Rollback
                        </Button>
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle
                                action={
                                    <DialogTrigger action="close">
                                        <Button
                                            appearance="subtle"
                                            aria-label="close"
                                            icon={<Dismiss24Regular />}
                                        />
                                    </DialogTrigger>
                                }
                            >
                                {window.knowit.SITENAME}
                            </DialogTitle>
                            <DialogContent>
                                {`Rollback de`}
                                <br/>
                                <br/>
                                {props.lang.getText('dialog_confirm_text')}
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">{props.lang.getBtn('cancel')}</Button>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="primary" onClick={props.rollbackClickHandler}>
                                        {props.lang.getBtn('confirm')}
                                    </Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog> : null
            }
        </CardFooter>
    </Card>;
    //
    //
    //
    // const className = `pos-relative bd-1 height-px-60-min width-100`
    //
    // return delta !== null ?
    //     <Link key={id} className={className}>{content}</Link> :
    //     <div key={id} className={className}>{content}</div>;
};
AauPageRecordHistoryCard.defaultProps = defaultProps;
