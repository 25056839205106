/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauMixinAvatar} from "../aauMixinAvatar/aauMixinAvatar";
import {LinkSquare24Regular, Power24Filled} from "@fluentui/react-icons";
import {Link, Text, Caption1, Button, tokens, Card, CardPreview, CardHeader, CardFooter} from "@fluentui/react-components";

export interface AauPageSearchCardProps {
    id: string,
    user: string,
    url: string,
    name: string,
    state: boolean,
    module: string,
    timestamp: string,
}

const defaultProps = {
} as AauPageSearchCardProps;

export const AauPageSearchCard: FC<AauPageSearchCardProps> = props => {
    return <Card>
        <CardPreview logo={``}>
        </CardPreview>

        <CardHeader
            image={<AauMixinAvatar displayName={props.user} />}
            header={<Text weight="semibold">{props.name}</Text>}
            description={<Caption1>[{props.module}] {props.timestamp}</Caption1>}
            // description={
            //     <Body1>
            //         {props.name}
            //         <br/>
            //         <Caption1>[{props.module}] {props.timestamp}</Caption1>
            //     </Body1>
            // }
            action={<Button appearance="transparent" icon={<Power24Filled style={{color: (props.state ? tokens.colorPaletteGreenForeground3 : tokens.colorPaletteRedForeground3) }} />} aria-label="Objet State" />}
        />

        <CardFooter>
            <Link href={props.url} key={props.id} className={``}><Button icon={<LinkSquare24Regular />}>Voir</Button></Link>
        </CardFooter>
    </Card>;
};
AauPageSearchCard.defaultProps = defaultProps;
