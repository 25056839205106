/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {AauComponentTableColumnProps, AauComponentTableColumnPropsDefault} from './tableHeadColumn.d';
import {AauComponentTablesFilters} from './tableFilters';

import {DatePicker, DayOfWeek} from '@fluentui/react-datepicker-compat';

import dayjs from 'dayjs';

import {Field, Button, Input, Combobox, Option} from '@fluentui/react-components';
//import {DatePicker} from '@fluentui/react-datepicker-compat';
import {TextSortAscending16Regular, TextSortDescending16Regular, Filter24Filled, IosArrowLtr24Filled, IosArrowRtl24Filled, ChevronDown24Filled} from '@fluentui/react-icons';
import {tokens} from "@fluentui/react-theme";
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";

export const AauComponentTableHeadColumn: FC<AauComponentTableColumnProps> = props => {
    const select_options = ['switch', 'switch_state', 'btn_state'].includes(props.mixin)
            ? {'true': 'true', 'false': 'false'}
            : props.enums;

    const select_options_invert = []
    Object.entries(typeof(select_options) !== "undefined" && select_options !== null ? select_options : {}).forEach(([key, value]) => { select_options_invert[value as string] = key; });

    const [matchingOptions, setMatchingOptions] = React.useState(select_options_invert);

    let filterCond = null;
    let filterElement;
    let cssWidth: number = null;

    if ( props.name === 'btnactiongroup' ) {
        cssWidth = 200;
    } else if ( props.name === 'id' ) {
        cssWidth = 70;
    } else if ( ['createdBy', 'updatedBy'].includes(props.name) ) {
        cssWidth = 90;
    } else if ( ['rangeint', 'rangedint', 'rangedate'].includes(props.filter)  || ['str_to_icon'].includes(props.mixin) ) {
        cssWidth = 90;
    } else if ( ['btn_state', 'toggle', 'switch_state', 'switch', 'persona'].includes(props.mixin) ) {
        cssWidth = 90;
    } else if ( ['rating', 'str_date'].includes(props.mixin) ) {
        cssWidth = 150;
    } else if ( props.width !== null ) {
        cssWidth = props.width;
    }
    let style = cssWidth !== null ? {minWidth: `${cssWidth}px`, maxWidth: `${cssWidth}px`, margin: 0, padding: 0} : {width: '100%', margin: 0, padding: 0};

    switch(props.filter) {
        case 'textField':
            filterElement = <Input
                id={`filter_${props.name}`}
                key={`filter_${props.name}`}
                name={`filter_${props.name}`}
                style={style}
                size={`small`}
                contentBefore={<AauMixinIcon icon='Filter24Filled' source={`microsoft`} />}
                appearance={`underline`}
                defaultValue={typeof(props.filterValue) !== 'undefined' && props.filterValue !== null ? props.filterValue.toString() : ''}
                placeholder={props.lang.getText('search')}
                onChange={(ev, data) => props.setFilters(props.name, data.value, props.primary)}
            />;
            break;
        case 'select':
        case 'multiselect':
            filterElement = <Combobox
                // id={`filter_${props.name}`}
                key={`filter_${props.name}`}
                aria-labelledby={props.name}
                appearance={'underline'}
                inlinePopup={false}
                size={`small`}
                input={{style: {width: cssWidth - (8 * 2) - 24}}}
                listbox={{style: {width: 'fit', minWidth: 225 }}}
                expandIcon={<AauMixinIcon icon='ChevronDown24Filled' source={`microsoft`} />}
                style={style}
                positioning={'below-start'}
                multiselect={props.filter !== 'select'}
                placeholder={props.lang.getText('Tous', 'filter', 'Tous')}
                selectedOptions={props.filterValue !== null ? (props.filter === 'select' ? [props.filterValue.toString()] : props.filterValue as string[]) : []}
                className={`z-20`}
                onChange={(ev) => {
                    const value = ev.target.value.trim();
                    const matches = value !== '' ? Object.keys(select_options_invert).filter(option => option.toLowerCase().indexOf(value.toLowerCase()) === 0) : Object.keys(select_options_invert);

                    const select_options_invert_tmp = []
                    matches.forEach(value => { select_options_invert_tmp[value as string] = select_options_invert[value]; });

                    setMatchingOptions(select_options_invert_tmp);
                }}
                onOptionSelect={(event, data) => {
                    props.setFilters(props.name, data.selectedOptions.length >= 1 ? data.selectedOptions : null, props.primary)
                }}
                freeform={false}
                defaultValue={props.filterValue !== null ? (props.filterValue === '_NOFILTER_' ? props.lang.getText('Tous', 'filter', 'Tous') : (props.filter === 'select' ? props.filterValue.toString() : (props.filterValue as string[]).join(','))) : null}
            >
                {
                    props.filter === 'select' ? <Option
                        id={`filter_${props.name}_NO_FILTER`}
                        key={`filter_${props.name}_NO_FILTER`}
                        value='_NOFILTER_'
                    >
                        {props.lang.getText('Tous', 'filter', 'Tous')}
                    </Option> : null
                }
                {
                    Object.keys(matchingOptions).sort(function (first, second) {
                        let ret = 0;

                        if (first !== '' && second !== '' && first !== null && second !== null) {
                            if (first.toString().toLowerCase() < second.toString().toLowerCase()) {
                                ret = -1;
                            } else if (first.toString().toLowerCase() > second.toString().toLowerCase()) {
                                ret = 1;
                            } else {
                                //NOSONAR
                            }
                        }

                        return ret;
                    }).map(key => (
                        <Option
                            id={`filter_${props.name}_${matchingOptions[key].replace(new RegExp("[\ \/\?\*\&\@]", "g"), '_').replace('')}`}
                            key={`filter_${props.name}_${matchingOptions[key].replace(new RegExp("[\ \/\?\*\&\@]", "g"), '_')}`}
                            text={select_options_invert[key]}
                        >
                            {key}
                        </Option>
                    ))
                }
            </Combobox>
            break;
        case 'rangeint':
        case 'rangedint':
            filterElement = <div className={`width-px-${cssWidth} mx-auto`}>
                <Input
                    id={`filter_${props.name}_min`}
                    key={`filter_${props.name}_min`}
                    name={`filter_${props.name}_min`}
                    contentBefore={<AauMixinIcon icon='IosArrowRtl24Filled' source={`microsoft`} />}
                    appearance={`underline`}
                    style={style}
                    defaultValue={props.filterValue !== null && typeof(props.filterValue) === 'object' && props.filterValue.hasOwnProperty('min') && props.filterValue['min'] !== null ? props.filterValue['min'] : ''}
                    placeholder={props.lang.getText('filter_min')}
                    onChange={(ev, data) => props.setFilters(`${props.name}@min`, data.value !== null && data.value !==  '' ? parseInt(data.value, 10) : '', props.primary)}
                />
                <Input
                    id={`filter_${props.name}_max`}
                    key={`filter_${props.name}_max`}
                    name={`filter_${props.name}_max`}
                    contentBefore={<AauMixinIcon icon='IosArrowLtr24Filled' source={`microsoft`} />}
                    appearance={`underline`}
                    style={style}
                    defaultValue={props.filterValue !== null && typeof(props.filterValue) === 'object' && props.filterValue.hasOwnProperty('max') && props.filterValue['max'] !== null && !Number.isNaN(props.filterValue['max']) ? props.filterValue['max'] : ''}
                    placeholder={props.lang.getText('filter_max')}
                    onChange={(ev, data) => props.setFilters(`${props.name}@max`, data.value !== null && data.value !==  '' ? parseInt(data.value, 10) : '', props.primary)}
                />
            </div>;
            break;
        case 'rangedate':
            filterCond = props.filterValue !== null && typeof(props.filterValue) === 'object';
            const dFrom = filterCond && props.filterValue.hasOwnProperty('from') ? new Date(props.filterValue['from']) : null;
            const dTo = filterCond && props.filterValue.hasOwnProperty('to') ? new Date(props.filterValue['to']) : null;

            // const months = [];
            // const shortMonths = [];
            // const days = [];
            // const shortDays = [];

            // const padLength = 2;
            // const monthLength = 12;
            // const dayLength = 6;

            // for ( let i = 1; i <= monthLength; i++ ) {
            //     months.push(props.lang.getText(`month_long_${i.toString().padStart(padLength, '0')}`));
            //     shortMonths.push(props.lang.getText(`month_short_${i.toString().padStart(padLength, '0')}`));
            // }
            //
            // for ( let i = 0; i <= dayLength; i++ ) {
            //     days.push(props.lang.getText(`day_long_${i.toString().padStart(padLength, '0')}`));
            //     shortDays.push(props.lang.getText(`day_short_${i.toString().padStart(padLength, '0')}`));
            // }

            const extractDate = dValue => `${dValue.getFullYear()}-${(dValue.getMonth() + 1).toString().padStart(2, '0')}-${dValue.getDate().toString().padStart(2, '0')}`;

            filterElement = <div className={`width-px-${cssWidth} mx-auto`}>
                {/*<DatePicker*/}
                {/*    showMonthPickerAsOverlay={true}*/}
                {/*    showWeekNumbers={true}*/}
                {/*    firstWeekOfYear={1}*/}
                {/*    formatDate={date => dayjs(date).format('DD/MM/YYYY').toString()}*/}
                {/*    value={dFrom !== null ? new Date(extractDate(dFrom)) : null}*/}
                {/*    onSelectDate={date => {*/}
                {/*        props.setFilters(`${props.name}@from`, date, props.primary);*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<DatePicker*/}
                {/*    showMonthPickerAsOverlay={true}*/}
                {/*    showWeekNumbers={true}*/}
                {/*    firstWeekOfYear={1}*/}
                {/*    formatDate={date => dayjs(date).format('DD/MM/YYYY').toString()}*/}
                {/*    value={dTo !== null ? new Date(extractDate(dTo)) : null}*/}
                {/*    onSelectDate={date => {*/}
                {/*        props.setFilters(`${props.name}@to`, date, props.primary);*/}
                {/*    }}*/}
                {/*/>*/}
                <Field>
                    <DatePicker
                        style={style}
                        firstDayOfWeek={DayOfWeek.Monday}
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        appearance={`underline`}
                        contentAfter={<AauMixinIcon icon='CalendarMonth24Regular' source={`microsoft`} />}
                        formatDate={date => dayjs(date).format('DD/MM/YYYY').toString()}
                        value={dFrom !== null ? new Date(extractDate(dFrom)) : null}
                        onSelectDate={(date) => {
                            props.setFilters(`${props.name}@from`, date, props.primary);
                        }}
                        // strings={{goToToday: props.lang.getText('goToToday')} as ICalendarStrings}
                        isMonthPickerVisible={true}
                        showMonthPickerAsOverlay={false}
                    />
                </Field>
                <Field>
                    <DatePicker
                        style={style}
                        firstDayOfWeek={DayOfWeek.Monday}
                        showWeekNumbers={true}
                        firstWeekOfYear={1}
                        appearance={`underline`}
                        contentAfter={<AauMixinIcon icon='CalendarMonth24Regular' source={`microsoft`} />}
                        formatDate={date => dayjs(date).format('DD/MM/YYYY').toString()}
                        value={dTo !== null ? new Date(extractDate(dTo)) : null}
                        onSelectDate    ={(date) => {
                            props.setFilters(`${props.name}@to`, date, props.primary);
                        }}
                        // strings={{goToToday: props.lang.getText('goToToday')} as ICalendarStrings}
                        isMonthPickerVisible={true}
                        showMonthPickerAsOverlay={false}
                    />
                </Field>
            </div>;
            break;
        default:
            filterElement = null;
    }

    return <th key={props.name} className={`text-bold ${cssWidth!==null ? `width-px-${cssWidth}-min width-px-${cssWidth}-max` : ''} p-1`}>
        {
            props.showFilter === false || props.name !== 'btnactiongroup'
            ? null :
            <AauComponentTablesFilters
                data={props.data}
                columns={props.columns}
                parentSetColumns={props.parentSetColumns}
                lang={props.lang}
            />
        }
        {
            props.showFilter || props.name === 'btnactiongroup'
            ? null
            : <Button
                as={`a`}
                size="small"
                icon={<AauMixinIcon icon={props.sort === 'asc' || props.sort === 'none' ? 'TextSortAscending16Regular' : 'TextSortDescending16Regular'} source={`microsoft`} />}
                appearance="subtle"
                onClick={() => {
                    props.sortSetColumn(props.name, props.sort === 'desc' ? 'asc' : 'desc')
                }}
            >
                {props.title}
            </Button>
        }
        {props.showFilter ? filterElement : null}
    </th>;
};
AauComponentTableHeadColumn.defaultProps = AauComponentTableColumnPropsDefault;
