/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to distribute, remix, adapt without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {
    Body1,
    Button,
    Caption1, Card,
    CardHeader,
    CardPreview,
    tokens,
    Toolbar,
    ToolbarButton
} from "@fluentui/react-components";
import {AauMixinIcon} from "../../Components/aauMixinIcon/aauMixinIcon";

export interface AauPageTextAnalyzerProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageTextAnalyzerProps;

export const AauPageTextAnalyzer: FC<AauPageTextAnalyzerProps> = props => {
    const admin = '/';
    const module = 'textanalyzer';

    const [text, setText] = useState(null);
    const [lexical, setLexical] = useState(null);
    const [ranking, setRanking] = useState(null);
    const [tail, setTail] = useState(null);
    const [analysis, setAnalysis] = useState(false);
    const [result, setResult] = useState(null);

    if ( analysis === true ) {
        const text_lower = text.toLowerCase()

        let lexical_counts = {};
        lexical.split('\n').forEach((word) => {
            const pattern = new RegExp(word, 'g');
            lexical_counts[word] = (text_lower.match(pattern) || []).length;
        });

        let ranking_counts = {};
        ranking.split('\n').forEach((word) => {
            const pattern = new RegExp(word, 'g');
            ranking_counts[word] = (text_lower.match(pattern) || []).length;
        });

        let tail_counts = {};
        tail.split('\n').forEach((word) => {
            const pattern = new RegExp(word, 'g');
            tail_counts[word] = (text_lower.match(pattern) || []).length;
        });

        setResult({
            lexical: lexical_counts,
            ranking: ranking_counts,
            tail: tail_counts
        })

        setAnalysis(false);
    }

    return (
        <div >
            <AauMixinPageHeader
                pageTitle="Text Analyzer"
                pageHelp="Texte Analysis tools"
                menuBadgeText="Text Analyzer"
                menuBadgeState="v1.0"
            />
            <div className='p-5 pos-absolute width-92 top-px-87'>
                <div className='p-5 float-clear pos-relative'>
                    <Card
                        style={{
                            width: '100%'
                        }}
                    >
                        <CardHeader
                            description={
                                <Body1>
                                    Test
                                </Body1>
                            }
                        />

                        <CardPreview>
                            <textarea
                                key="text"
                                style={{
                                    width: '98%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    height: '200px',
                                    marginBottom: '10px'
                                }}
                                onChange={(e) => setText(e.target.value)}
                            />
                            {
                                text !== null ? <Button
                                    onClick={() => {
                                        setAnalysis(true);
                                    }}
                                >
                                    Analyser
                                </Button> : null
                            }
                        </CardPreview>
                    </Card>
                </div>
                <div className='p-5 float-clear pos-relative'>
                    <Card
                        style={{
                            float: 'left',
                            width: '32%',
                            marginRight: '1%',
                        }}
                    >
                        <CardHeader
                            description={
                                <Body1>
                                    Champs Lexical
                                </Body1>
                            }
                        />

                        <CardPreview>
                            <textarea
                                key="lexical"
                                style={{
                                    width: '94%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    height: '200px',
                                    marginBottom: '10px'
                                }}
                                onChange={(e) => setLexical(e.target.value)}
                            />
                        </CardPreview>
                    </Card>
                    <Card
                        style={{
                            float: 'left',
                            width: '32%',
                            marginRight: '1%',
                            marginLeft: '1%'
                        }}
                    >
                        <CardHeader
                            description={
                                <Body1>
                                    Cibles ranking
                                </Body1>
                            }
                        />

                        <CardPreview>
                            <textarea
                                key="ranking"
                                style={{
                                    width: '94%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    height: '200px',
                                    marginBottom: '10px'
                                }}
                                onChange={(e) => setRanking(e.target.value)}
                            />
                        </CardPreview>
                    </Card>
                    <Card
                        style={{
                            float: 'left',
                            width: '32%'
                        }}
                    >
                        <CardHeader
                            description={
                                <Body1>
                                    Long Tail Cible
                                </Body1>
                            }
                        />

                        <CardPreview>
                            <textarea
                                key="tail"
                                style={{
                                    width: '94%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    height: '200px',
                                    marginBottom: '10px'
                                }}
                                onChange={(e) => setTail(e.target.value)}
                            />
                        </CardPreview>
                    </Card>
                </div>
                {
                    result !== null ? <div className='p-5 float-clear pos-relative'>
                        <Card
                            style={{
                                float: 'left',
                                width: '32%',
                                marginRight: '1%',
                            }}
                        >
                            <CardHeader
                                description={
                                    <Body1>
                                        Résultat Champs Lexical
                                    </Body1>
                                }
                            />

                            <CardPreview>
                                {
                                    Object.keys(result['lexical']).sort((a, b) => { return result['lexical'][b] - result['lexical'][a] }).map(key => {
                                        return key !== '' ? <div>
                                            <Body1>
                                                {key} : {result['lexical'][key]}
                                            </Body1>
                                        </div> : null
                                    })
                                }
                            </CardPreview>
                        </Card>
                        <Card
                            style={{
                                float: 'left',
                                width: '32%',
                                marginRight: '1%',
                            }}
                        >
                            <CardHeader
                                description={
                                    <Body1>
                                        Résultat Cibles ranking
                                    </Body1>
                                }
                            />

                            <CardPreview>
                                {
                                    Object.keys(result['ranking']).sort((a, b) => { return result['ranking'][b] - result['ranking'][a] }).map(key => {
                                        return key !== '' ? <div>

                                            <Body1>
                                                {key} : {result['ranking'][key]}
                                            </Body1>
                                        </div> : null
                                    })
                                }
                            </CardPreview>
                        </Card>
                        <Card
                            style={{
                                float: 'left',
                                width: '32%',
                                marginRight: '1%',
                            }}
                        >
                            <CardHeader
                                description={
                                    <Body1>
                                        Résultat Long Tail Cible
                                    </Body1>
                                }
                            />

                            <CardPreview>
                                {
                                    Object.keys(result['tail']).sort((a, b) => { return result['tail'][b] - result['tail'][a] }).map(key => {
                                        return key !== '' ? <div>
                                            <Body1>
                                                {key} : {result['tail'][key]}
                                            </Body1>
                                        </div> : null
                                        // return result['tail'][key] > 0 && key !== '' ? <div>
                                        //     <Body1>
                                        //         {key} : {result['tail'][key]}
                                        //     </Body1>
                                        // </div> : null
                                    })
                                }
                            </CardPreview>
                        </Card>
                    </div> : null
                }
            </div>

        </div>
    );
};
AauPageTextAnalyzer.defaultProps = defaultProps;
