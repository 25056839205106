/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {AauFormField} from "../aauFormField/aauFormField";


export interface AauFormTextProps {
    label?: string;
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    value?: string;
    validator: object;
    parentFunction?: any;
    helper?: string | null;
    required?: boolean | null;
    rows?: number;
    hint?: string;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {},
    parentFunction: null,
    value: '',
    helper: null,
    required: false,
    rows: 10,
    hint: null
} as AauFormTextProps;

export const AauFormText: FC<AauFormTextProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    let content;
    const {readonly, itemKey, name, value, parentFunction, helper, rows, required, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value);
    }

    // const cssClassName = `bd-1 bd-l3-black bd-top-none bd-right-none`;

    return null;
    // <Field
    //     key={`${name}-field`}
    //     label={label}
    //     validationState={state['isValid'] === true ? 'success' : 'error'}
    //     required={required}
    // >
    //     {/*<Input */}
    //     {/*    id={itemKey}*/}
    //     {/*    key={`${itemKey}-ctrl`}*/}
    //     {/*    name={name}     */}
    //     {/*    defaultValue={state['content']}*/}
    //     {/*    onChange={ (e, val) => validateField(val)}*/}
    //     {/*    */}
    //     {/*/>*/}
    // </Field>


    // if ( readonly === false) {
    //         <div className='mb-5 width-100' key={`${name}-formcontrol`}>
    //         <AauFormLabel
    //             key={`${name}-label`}
    //             // isValid={state['isValid']}
    //             name={name}
    //             value={label}
    //             // readonly={false}
    //             required={required}
    //             // helper={helper}
    //         />
    //         <div className='mt-3'>
    //             <TextField
    //                 id={itemKey}
    //                 key={`${itemKey}-ctrl`}
    //                 name={name}
    //                 aria-labelledby={name}
    //                 defaultValue={state['content']}
    //                 onChange={ (e, val) => validateField(val)}
    //                 inputClassName={cssClassName}
    //                 multiline
    //                 rows={rows}
    //                 borderless
    //             />
    //         </div>
    //     </div>;
    // } else {
    //    content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
    //         <AauFormLabel
    //             key={`${name}-label`}
    //             // isValid={state['isValid']}
    //             name={name}
    //             value={label}
    //             // readonly={true}
    //             required={required}
    //             // helper={helper}
    //         />
    //         <div className={`view-value pt-9`}>
    //             {HTMLReactParser(state['content'])}
    //         </div>
    //     </div>;
    // }
    //
    // return content;
};
AauFormText.defaultProps = defaultProps;
