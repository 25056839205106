/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {Combobox, Input, Option, Switch} from "@fluentui/react-components";
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";


export interface AauMixinFiltersProps {
    lang: AauToolsLang;
    filters: AauMixinFiltersItemProps[];
    className?: string;
}

export interface AauMixinFiltersItemProps {
    component: string;
    name: string;
    width: number;
    options ?: object[];
    changeHandler: any;
    value?: string | boolean;
}

const defaultProps = {
    className: ''
} as AauMixinFiltersProps;

export const AauMixinFilters: FC<AauMixinFiltersProps> = props => {
    const { lang, filters} = props;

    return <div key='filters_container' className={`height-50 pl-10 pr-10 mt-5 mx-auto bg-1 bd ${props.className}`}>
        {
            Object(filters).map(f =>{
                let content = null;
                switch ( f['component'] ) {
                    case 'text':
                    case 'textbox':
                    case 'search':
                        content = <Input
                            id={`filter_${f['name']}`}
                            key={`filter_${f['name']}`}
                            name={`${f['name']}`}
                            size={`small`}
                            contentBefore={<AauMixinIcon icon='Filter24Filled' source={`microsoft`} />}
                            appearance={`underline`}
                            placeholder={props.lang.getText('search')}
                            onChange={(ev, data) => f['changeHandler'](data.value)}
                        />;
                        break;
                    case 'select':
                        content = <Combobox
                            key={`filter_${f['name']}`}
                            name={`filter_${f['name']}`}
                            aria-labelledby={f['name']}
                            appearance={'underline'}
                            inlinePopup={false}
                            size={`small`}
                            expandIcon={<AauMixinIcon icon='ChevronDown24Filled' source={`microsoft`} />}
                            positioning={'below-start'}
                            multiselect={false}
                            placeholder={props.lang.getText('Tous', 'filter', 'Tous')}
                            className={`z-20`}
                            onOptionSelect={(event, data) => {
                                f['changeHandler'](data['optionValue'].toString());
                            }}
                            freeform={false}
                        >
                            {
                                Object.keys(f['options']).sort(function (first, second) {
                                    let ret = 0;

                                    if (f['options'][first]['text'] !== '' && f['options'][second]['text'] !== '' && f['options'][first]['text'] !== null && f['options'][second]['text'] !== null) {
                                        if (f['options'][first]['text'].toString().toLowerCase() < f['options'][second]['text'].toString().toLowerCase()) {
                                            ret = -1;
                                        } else if (f['options'][first]['text'].toString().toLowerCase() > f['options'][second]['text'].toString().toLowerCase()) {
                                            ret = 1;
                                        } else {
                                            //NOSONAR
                                        }
                                    }

                                    return ret;
                                }).map(key => (
                                    <Option
                                        id={`filter_${f['name']}_${f['options'][key]['key'].replace(new RegExp("[\ \/\?\*\&\@]", "g"), '_').replace('')}`}
                                        key={`filter_${f['name']}_${f['options'][key]['key'].replace(new RegExp("[\ \/\?\*\&\@]", "g"), '_')}`}
                                        text={f['options'][key]['text']}
                                        value={f['options'][key]['key']}
                                    >
                                        {f['options'][key]['text']}
                                    </Option>
                                ))
                            }
                        </Combobox>
                        break;
                    case 'toggle':
                    case 'switch':
                        content = <div className='float-left mt-1 ml-5'>
                            <Switch
                                id={`filter_${f['name']}`}
                                key={`filter_${f['name']}`}
                                onChange={(ev, data) => {
                                    f['changeHandler'](data['checked'])
                                }}
                                label={lang.getFilter(f['name'], 'on')}
                            />
                        </div>;
                        break;
                    default:
                        content = 'Not implemented';
                        break;
                }

                return <div key={`filter_${f['name']}_container`} className={`float-left mr-1 ml-1`}>
                    {content}
                </div>;
            })
        }
        <div className='float-clear' />
    </div>;
};
AauMixinFilters.defaultProps = defaultProps;