/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinLoader} from '../aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {AauComponentForm} from '../aauComponentForm/aauComponentForm';
import {AauMixinStrYaml} from "../aauMixinStrYaml/aauMixinStrYaml";
import {Button} from "@fluentui/react-components";
import {tokens} from "@fluentui/react-theme";
import {PaddingLeft24Filled, PaddingRight24Filled} from "@fluentui/react-icons";
import {AauMixinPageHeader} from "../aauMixinPageHeader/aauMixinPageHeader";
import {AauMixinFabBar} from "../aauMixinFabBar/aauMixinFabBar";

export interface AauPageFormProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageFormProps;

export const AauPageForm: FC<AauPageFormProps> = props => {
    const {module, action, identifier, admin} = useParams();
    const [data, setData] = useState(null);
    const [dataExtra, setDataExtra] = useState(true);

    const history = useNavigate();

    let content = <AauMixinLoader />;
    let content_extra = null;

    const submitHandler = async (formData, itemid, actionName) => {
        if ( Object.keys(formData).length > 0 ) {
            await axios.post(
                `/rest/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}/${actionName}`,
                JSON.stringify({
                    identifier: itemid,
                    data: formData
                }),
                {
                    baseURL: `${window.knowit.BACKURL}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                    }
                }
            ).then(res => {
                if (res.data.success === true) {
                    const msg = props.lang.getNotification('save', true);
                    toast.success(msg.replace('%s', res.data.name));
                    history(`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`);
                } else {
                    const msg = props.lang.getNotification('save', false);
                    const msgDetail = props.lang.getText(res.data.msg);
                    toast.error(msg.replace('%s', res.data.name) + ' - ' + msgDetail);
                }
            }).catch(err => {
                const unauthorizedError = 401;
                const notFoundError = 404;
                if ( err.response.status === unauthorizedError ) {
                    if ( !err.response.data.hasOwnProperty("action") ) {
                        Cookies.remove('JWT');
                        Cookies.remove('user');
                        Cookies.remove('rank');
                        Cookies.remove('displayname');
                        window.location.href = `/login?redirect=${window.location.pathname}`;
                    } else {
                        window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                    }
                } else if ( err.response.status === notFoundError ) {
                    window.location.href = `/notfound`;
                }
            });
        } else {
            window.location.href = `/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`;
        }
    };

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${identifier}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        let canSaveAndAction = data.hasOwnProperty('can_save_and_action') ? data['can_save_and_action'] : null;
        let content_form = <AauComponentForm
            key={`knowit_form_${module}_${identifier}`}
            columns={data['columns']}
            item={data['item']}
            readonly={action === 'view' || data['can_edit'] === false ? true : false}
            saveHandler={action === 'view' ? null : formData => submitHandler(formData, identifier, "save")}
            saveActionHandler={canSaveAndAction === null ? null : formData => submitHandler(formData, identifier, "saveAndPush")}
            saveAndActionLabel={canSaveAndAction !== null ? data['save_and_action_label'] : 'save and ' + canSaveAndAction}
            templateVersion={data['template_version']}
            template={data['template_form']}
            lang={props.lang}
        />;

        if ( data['extra_information'] !== null ) {
            switch (data['extra_information']['kind'].toLowerCase()) {
                case 'yaml':
                    content_extra = <AauMixinStrYaml value={data['extra_information']['value'].replaceAll("\\\n", "").replaceAll('\\n', '\n').replaceAll('\\"', '\"').replaceAll('\\t', '  ').replace('\s\s\s', '').replace('\\ ', '')} />;
                    break;
                case 'text':
                    content_extra = <pre>
                        {data['extra_information']['value']}
                    </pre>;
                    break;
                case 'json':
                    content_extra = "Extra JSON Text - Not implemented";
                    break;
            }
        }

        content = <div>
            <div className={`p-2 float-left width-${content_extra !== null && dataExtra === true ? 60 : 100 } pos-relative`}>
                {content_form}
                {
                    content_extra !== null && <Button
                        style={{
                            position: 'absolute',
                            right: content_extra !== null && dataExtra === true ? 0 : -25,
                            zIndex: 300,
                            top: -3
                        }}
                        appearance="primary"
                        icon={dataExtra ? <PaddingRight24Filled /> : <PaddingLeft24Filled />}
                        onClick={() => {
                            setDataExtra(!dataExtra)
                        }}
                    />
                }
            </div>
            {
                content_extra !== null && dataExtra === true && <div className={`p-2 float-left width-35`}>
                    {content_extra}
                </div>
            }
        </div>;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='mt-23 p-2 pl-2 pr-15' style={{backgroundColor: tokens.colorNeutralBackground1}}>
                {content}
            </div>
            <AauMixinFabBar
                back={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                lang={props.lang}
                urlBasePath={`${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                fabs={{
                    view: {
                        enabled: data !== null && data !== false && data['can_view'] && window.location.pathname.indexOf('/edit/') !== -1,
                        confirm: false,
                        label: props.lang.getText('view'),
                        component: {
                            confirm: false,
                            iconazure: 'View',
                            url: `/view/${identifier}`
                        },
                        mixin: 'btn_link_page_id'
                    },
                    edit: {
                        enabled: data !== null && data !== false && data['can_edit'] && window.location.pathname.indexOf('/view/') !== -1,
                        confirm: false,
                        label: props.lang.getText('edit'),
                        component: {
                            confirm: false,
                            iconazure: 'PencilReply',
                            url: `/edit/${identifier}`
                        },
                        mixin: 'btn_link_page_id'
                    },
                    history: {
                        enabled: data !== null && data !== false && data['can_history_compare'] ,
                        confirm: false,
                        label: props.lang.getText('history'),
                        component: {
                            iconazure: 'TimeEntry',
                            url: `/history/${identifier}`
                        },
                        mixin: 'btn_link_page_id'
                    }
                }}
            />
        </div>
    );
};
AauPageForm.defaultProps = defaultProps;
