/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {AauComponentTable} from '../../Components/aauComponentTable/aauComponentTable';
import {AauMixinTabs} from '../../Components/aauMixinTabs/aauMixinTabs';
import {AauKubeNodeRow} from "./aauPageKubeNodeRow";

export interface AauPageKubeNodeProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageKubeNodeProps;

export const AauPageKubeNode: FC<AauPageKubeNodeProps> = props => {
    const {lang} = props;

    const [data, setData] = useState(null);
    const [menuKey, setMenuKey] = useState<string>('0');

    const admin = true;
    const module = 'kubenode';

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    let content = <AauMixinLoader />;

    if ( data !== null && data !== false ) {

        const dataArray = [{
            title: 'Data Overview',
            content: <AauComponentTable
                columns={data['columns']}
                data={data['info']['data']}
                columnid={data['columnid']}
                displayRows={data.hasOwnProperty('options') ? data['options']['showEntries'] : 15}
                displayRowsList={data.hasOwnProperty('options') ? data['options']['showEntriesList'] : [15,25,50,100]}
                refreshTable={retrieveData}
                lang={lang}
                canEdit={data.hasOwnProperty('can_edit') ? data['can_edit'] : false}
                canView={data.hasOwnProperty('can_view') ? data['can_view'] : false}
                canSaveAndAction={null}
                saveAndActionLabel={null}
                viewAction={data.hasOwnProperty('table_view_action') ? data['table_view_action'] : 'view'}
            />
        }];

        Object.keys(data['info']['data']).forEach(rid => {
            let d = data['info']['data'][rid]
            if ( d.hasOwnProperty('labels') ) {
                dataArray.push({
                    title: `${d['name']}`,
                    content: d === null ?
                        null : <div className='ms-Grid'>
                            <AauKubeNodeRow label="Nom" val={d['name']} />
                            <AauKubeNodeRow label="Labels" val='' />
                            {
                                Object.entries(d['labels']).map((value, srid) => {
                                    return <AauKubeNodeRow
                                        label={value[0] as string}
                                        val={value[1] as string}
                                        col={3}
                                    />;
                                })
                            }
                            <AauKubeNodeRow label="Annotations" val='' />
                            {
                                Object.entries(d['annotations']).map((value, srid) => {
                                    return <AauKubeNodeRow
                                        label={value[0] as string}
                                        val={value[1] as string}
                                        col={3}
                                    />;
                                })
                            }
                            <AauKubeNodeRow label="CreationTimestamp" val={d['createdAt']} />
                            <AauKubeNodeRow label="Taints" val={d['taints']} />
                            <AauKubeNodeRow label="Unschedulable" val={d['unschedulable']} />
                            <AauKubeNodeRow label="Conditions" val='' />
                            {
                                Object.entries(d['conditions']).map((value, srid) => {
                                    return <div className='ms-Grid-row'>
                                        <div className='ms-Grid-col ms-sm2 float-left pl-5'><b>{value[1]['type']}</b>
                                        </div>
                                        <div className='ms-Grid-col ms-sm1 float-left'>{value[1]['status']}</div>
                                        <div className='ms-Grid-col ms-sm3 float-left'>{value[1]['heartbeat']}</div>
                                        <div className='ms-Grid-col ms-sm3 float-left'>{value[1]['reason']}</div>
                                        <div className='ms-Grid-col ms-sm3 float-left'>{value[1]['message']}</div>
                                    </div>;
                                })
                            }
                            <AauKubeNodeRow label="Addresses" val='' />
                            {
                                Object.entries(d['addresses']).map((value, srid) => {
                                    return <AauKubeNodeRow
                                        label={value[1]['type']}
                                        val={value[1]['address'] as string}
                                        col={3}
                                    />;
                                })
                            }
                            <AauKubeNodeRow label="Capacity" val='' />
                            {
                                Object.entries(d['capacity']).map((value, srid) => {
                                    return <AauKubeNodeRow label={value[0]} col={3} val={value[1] as string} />;
                                })
                            }
                            <AauKubeNodeRow label="Allocatable" val='' />
                            {
                                Object.entries(d['allocatable']).map((value, srid) => {
                                    return <AauKubeNodeRow label={value[0]} col={3} val={value[1] as string} />;
                                })
                            }
                            <AauKubeNodeRow label="System Info" val='' />
                            {
                                Object.entries(d['node_info']).map((value, srid) => {
                                    return <AauKubeNodeRow label={value[0]} col={3} val={value[1] as string} />;
                                })
                            }
                            <AauKubeNodeRow label="Non-terminated Pods" val='' />
                            <AauKubeNodeRow label="" val={`${d['pods'].length} in total`} />
                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm2 float-left text-center pl5'>Namespace</div>
                                <div className='ms-Grid-col ms-sm6 float-left text-center'>Name</div>
                                <div className='ms-Grid-col ms-sm1 float-left text-center'>CPU Req</div>
                                <div className='ms-Grid-col ms-sm1 float-left text-center'>CPU Lim</div>
                                <div className='ms-Grid-col ms-sm1 float-left text-center'>MEM Req</div>
                                <div className='ms-Grid-col ms-sm1 float-left text-center'>MEM Lim</div>
                            </div>
                            {
                                Object.keys(d['pods']).map(pid => {
                                    return <div className='ms-Grid-row'>
                                        <div
                                            className='ms-Grid-col ms-sm2 float-left text-center pl5'>{d['pods'][pid]['namespace']}</div>
                                        <div
                                            className='ms-Grid-col ms-sm6 float-left text-center'>{d['pods'][pid]['name']}</div>
                                        <div
                                            className='ms-Grid-col ms-sm1 float-left text-right'>{d['pods'][pid]['req_cpu']} m
                                        </div>
                                        <div
                                            className='ms-Grid-col ms-sm1 float-left text-right'>{d['pods'][pid]['lim_cpu']} m
                                        </div>
                                        <div
                                            className='ms-Grid-col ms-sm1 float-left text-right'>{d['pods'][pid]['req_mem']}
                                        </div>
                                        <div
                                            className='ms-Grid-col ms-sm1 float-left text-right'>{d['pods'][pid]['lim_mem']}
                                        </div>
                                    </div>;
                                })
                            }
                            <AauKubeNodeRow label="Allocated resources" val='' />
                            <AauKubeNodeRow label="" val='(Total limits may be over 100 percent, i.e., overcommitted.)' />
                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm1 float-left'></div>
                                <div className='ms-Grid-col ms-sm5 float-left text-center'>Resource</div>
                                <div className='ms-Grid-col ms-sm3 float-left text-center'>Requests</div>
                                <div className='ms-Grid-col ms-sm3 float-left text-center'>Limits</div>
                            </div>
                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm1 float-left'></div>
                                <div className='ms-Grid-col ms-sm5 float-left text-center'>cpu</div>
                                <div className='ms-Grid-col ms-sm3 float-left text-center'>{d['requestCpu']}%</div>
                                <div className='ms-Grid-col ms-sm3 float-left text-center'>{d['limitCpu']}%</div>
                            </div>
                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm1 float-left'></div>
                                <div className='ms-Grid-col ms-sm5 float-left text-center'>memory</div>
                                <div className='ms-Grid-col ms-sm3 float-left text-center'>{d['requestMem']}%</div>
                                <div className='ms-Grid-col ms-sm3 float-left text-center'>{d['limitMem']}%</div>
                            </div>
                        </div>
                });
            }
        });

        content = <AauMixinTabs
            data={dataArray}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div >
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-87'>
                {content}
            </div>
        </div>
    );
};
AauPageKubeNode.defaultProps = defaultProps;
