/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {useParams} from "react-router-dom";
import {AauMixinStrYaml} from "../../Components/aauMixinStrYaml/aauMixinStrYaml";
import ReactDiffViewer, {DiffMethod} from '@terran-one/react-diff-viewer';
import {AauMixinFabBar} from "../../Components/aauMixinFabBar/aauMixinFabBar";
import {AauMixinDiffFile} from "../../Components/aauMixinDiffFile/aauMixinDiffFile";


export interface AauPageYamlViewProps {
    action: string;
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageYamlViewProps;

export const AauPageYamlView: FC<AauPageYamlViewProps> = props => {
    const {lang, action} = props;
    const {admin, module, identifier} = useParams();

    const [data, setData] = useState(null);

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${identifier}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {

    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div >
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {
                    data !== null && data !== false ? (
                        data['info'].hasOwnProperty('value2') > 0 ? <AauMixinDiffFile
                            oldValue={data['info']['value1']}
                            newValue={data['info']['value2']}
                            leftTitle={"Kubernetes item build from BDD"}
                            rightTitle={"Kubernetes item extract"}
                        /> : <AauMixinStrYaml value={data['info']['value1']} />
                    ) : <AauMixinLoader />
                }
            </div>
            <AauMixinFabBar
                back={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                lang={props.lang}
                urlBasePath={`${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                fabs={null}
            />
        </div>
    );
};
AauPageYamlView.defaultProps = defaultProps;
