/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {AauFormLabel} from '../aauFormLabel/aauFormLabel';
import JSONInput from 'react-json-editor-ajrm';
import locale    from 'react-json-editor-ajrm/locale/fr';
import {AauFormField} from "../aauFormField/aauFormField";


export interface AauFormJsonProps {
    label?: string;
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    value?: string;
    validator: object;
    parentFunction?: any;
    helper?: string | null;
    required?: boolean | null;
    hint?: string;
    rows?: number;
}

const defaultProps = {
    label: null,
    readonly: false,
    validator: {},
    parentFunction: null,
    value: {},
    helper: null,
    required: false,
    rows: 10,
    hint: null
} as AauFormJsonProps;

export const AauFormJson: FC<AauFormJsonProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (required === true && (validateValue === '' || validateValue === null || typeof validateValue === "undefined")) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( ((state['isValid'] === null && isValid === false) || state['isValid'] !== null) && parentFunction !== null ) {
                parentFunction(itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    let content;
    const {readonly, itemKey, name, value, parentFunction, helper, required, label} = props;

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': value
    });
    if (state['isValid'] === null && readonly === false ) {
        validateField(value);
    }

    if ( readonly === false) {
        content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                // isValid={state['isValid']}
                name={name}
                value={label}
                // readonly={false}
                required={required}
                // helper={helper}
            />
            <div className='mt-3'>
                <JSONInput
                    id={itemKey}
                    placeholder={state['content']}
                    locale={locale}
                    height='200px'
                    width='100%'
                    viewOnly={false}
                    onChange={(e) => validateField(e.jsObject)}
                />
            </div>
        </div>;
    } else {
       content = <div className='mb-5 width-100' key={`${name}-formcontrol`}>
            <AauFormLabel
                key={`${name}-label`}
                // isValid={state['isValid']}
                name={name}
                value={label}
                // readonly={true}
                required={required}
                // helper={helper}
            />
            <div className={`view-value pt-9`}>
                <JSONInput
                    id={itemKey}
                    placeholder={state['content']}
                    locale={locale}
                    height='200px'
                    width='100%'
                    viewOnly={true}
                />
            </div>
        </div>;
    }

    return content;
};
AauFormJson.defaultProps = defaultProps;
