/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useParams} from 'react-router-dom';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {colorsVisCode} from '../../Components/aauToolsColor/aauToolsColor';
import {AauMixinFilters} from '../../Components/aauMixinFilters/aauMixinFilters';
import {AauMixinCartoVis} from '../../Components/aauMixinCartoVis/aauMixinCartoVis';
import {AauMixinFabBar} from "../../Components/aauMixinFabBar/aauMixinFabBar";


export interface AauPageCartoKubeDeploymentProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageCartoKubeDeploymentProps;

export const AauPageCartoKubeDeployment: FC<AauPageCartoKubeDeploymentProps> = props => {
    const {lang} = props;
    const {admin} = useParams();
    const [data, setData] = useState(null);
    const [visnetwork, setVisnetwork] = useState(null);
    const [showlabel, setShowlabel] = useState<boolean>(true);
    const [showProducts, setShowProducts] = useState<boolean>(true);
    const [shapeFilter, setShapeFilter] = useState<string>('shapeall');
    const [colorFilter, setColorFilter] = useState<string>('colorall');

    const module = 'kubedeployment';
    const action = 'carto';
    const identifier = 0;

    let content = <AauMixinLoader />;
    let filterContent = null;

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/${action}/${identifier}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            const notFoundError = 404;
            if ( err.response.status === unauthorizedError ) {
                if ( !err.response.data.hasOwnProperty("action") ) {
                    Cookies.remove('JWT');
                    Cookies.remove('user');
                    Cookies.remove('rank');
                    Cookies.remove('displayname');
                    window.location.href = `/login?redirect=${window.location.pathname}`;
                } else {
                    window.location.href = `/forbidden?kind=${err.response.data.msg}`;
                }
            } else if ( err.response.status === notFoundError ) {
                window.location.href = `/notfound`;
            }
        });
    };

    if ( data !== null && data !== false ) {
        const nodesTmp = [];
        const edgesTmp = [];

        const nodeNameLabel = 'name';

        const nodesSearch = [];
        const nodesNameToId = {};

        const colors = [{key: 'colorall', text: `${lang.getSelectOption(module, 'colorall')}`}];
        let productIndex = 0;
        let languageIndex = 0;
        const colorsList = {};

        const shapes = [{key: 'shapeall', text: `${lang.getSelectOption(module, 'shapeall')}`}];
        const shapesList = {
            '0 Produits': 'triangle',
            '1 Produits': 'dot',
            '2 Produits': 'diamond',
            '3 Produits': 'star',
            '4 Produits': 'square',
            '5 Produits': 'hexagon',
            '6 Produits': 'triangleDown'
        };

        Object(data['info']['data']).forEach(node => {
            nodesSearch.push(node[nodeNameLabel]);
            nodesNameToId[node[nodeNameLabel]] = node['id'];

            const nodeShape = shapesList[`${node['products'].length} Produits`];

            let hidden = shapeFilter !== 'shapeall' && nodeShape !== shapeFilter ? true : false;
            hidden = !hidden && colorFilter !== 'colorall' && showProducts && !node['products'].includes(colorFilter) ? true : hidden;
            hidden = !hidden && colorFilter !== 'colorall' && !showProducts && node['dev'] !== colorFilter ? true : hidden;

            let nodeColor = null;
            const productIdBase = 10000;
            const devIdBase = 20000;
            Object.keys(node['products']).forEach(product => {
                if ( !colorsList.hasOwnProperty(node['products'][product]) ) {
                    colorsList[node['products'][product]] = {
                        id: productIdBase + Object.keys(colorsList).length,
                        name: node['products'][product],
                        type: 'product',
                        color: colorsVisCode[productIndex]
                    };
                    productIndex += 1;
                    nodesTmp.push({
                        id: colorsList[node['products'][product]]['id'],
                        label: node['products'][product],
                        shape: 'ellipsis',
                        hidden: showProducts ? false : true,
                        color: colorsList[node['products'][product]]['color'],
                        font: {
                            color: '#000000'
                        }
                    });
                }

                if ( colorsList.hasOwnProperty(node['products'][product]) ) {
                    edgesTmp.push({
                        id: `${node['id']}_${node[nodeNameLabel]}_to_${node['products'][product]}`,
                        from: node['id'],
                        to: colorsList[node['products'][product]]['id'],
                        dashes: false,
                        hidden: false,
                        color: {
                            inherit: 'both',
                            opacity: 0.3
                        },
                        width: 2
                    });
                }
            });
            if (!colorsList.hasOwnProperty(node['dev'])) {
                colorsList[node['dev']] = {
                    id: devIdBase + Object.keys(colorsList).length,
                    name: node['dev'],
                    type: 'dev',
                    color: colorsVisCode[languageIndex]
                };
                languageIndex += 1;
                nodesTmp.push({
                    id: colorsList[node['dev']]['id'],
                    label: node['dev'],
                    shape: 'ellipsis',
                    hidden: !showProducts ? false : true,
                    color: colorsList[node['dev']]['color'],
                    font: {
                        color: '#000000'
                    }
                });
            }
            if ( colorsList.hasOwnProperty(node['dev']) ) {
                edgesTmp.push({
                    id: `${node['id']}_${node[nodeNameLabel]}_to_${node['dev']}`,
                    from: node['id'],
                    to: colorsList[node['dev']]['id'],
                    dashes: false,
                    hidden: false,
                    color: {
                        inherit: 'both',
                        opacity: 0.5
                    },
                    width: 2
                });
            }

            if ( showProducts ) {
                nodeColor = node['products'].length === 1 ? colorsList[node['products'][0]]['color'] : '#CCCCCC';
            } else {
                nodeColor = colorsList[node['dev']]['color'];
            }

            nodesTmp.push({
                id: `${node['id']}`,
                label: showlabel === false  ? '' : node[nodeNameLabel],
                shape: nodeShape,
                color: nodeColor,
                hidden
            });
        });

        Object.keys(shapesList).forEach(key => {
            shapes.push({
                key: shapesList[key],
                text: key
            });
        });

        Object.keys(colorsList).sort((first, second) => {
            let ret = 0;
            if ( first.toLowerCase() < second.toLowerCase() ) {
                ret = -1;
            } else if ( first.toLowerCase() > second.toLowerCase() ) {
                ret = 1;
            } else {
                //NOSONAR
            }

            return ret;
        }).forEach(key => {
            if ( showProducts && colorsList[key]['type'] === 'product' ) {
                colors.push({
                    key,
                    text: colorsList[key]['name']
                });
            } else if ( !showProducts && colorsList[key]['type'] === 'dev' ) {
                colors.push({
                    key,
                    text: colorsList[key]['name']
                });
            } else {
                //NOSONAR
            }
        });

        content = <AauMixinCartoVis
            nodes={nodesTmp}
            edges={edgesTmp}
            networkHandler={setVisnetwork}
            sprintLength={400}
        />;

        const substractIndex = 2;
        const searchNode = newValue => {
            const nodeSearch =  newValue.toLowerCase();
            const ns = nodesSearch.join('|');
            const nodesSearchList = ns.substr( 1, ns.indexOf('|', ns.indexOf(nodeSearch)) ).split('|');
            const nodeId = nodesNameToId.hasOwnProperty(
                nodesSearchList[nodesSearchList.length-substractIndex]
            ) ? nodesNameToId[nodesSearchList[nodesSearchList.length-substractIndex]] : null;

            if ( nodeSearch === '' ) {
                visnetwork.moveTo({
                    position:{
                        x: 0,
                        y: 0
                    },
                    scale: 0.75
                });
            } else if ( visnetwork !== null && nodeId !== null ) {
                visnetwork.focus({
                    locked: true
                });
                visnetwork.moveTo({
                    position:{
                        x: visnetwork.body.nodes[nodeId].x,
                        y: visnetwork.body.nodes[nodeId].y
                    },
                    scale: 1.2
                });
            } else {
                //NOSONAR
            }
        };

        filterContent = <AauMixinFilters
            lang={lang}
            filters={[
                {component: 'search', name: 'nodename', 'width': 200, changeHandler: value => {searchNode(value);}},
                {component: 'select', name: 'shape', options: shapes, 'width': 200, changeHandler: value => {setShapeFilter(value);},
                    value: `${lang.getSelectOption(module, shapeFilter)}`
                },
                {component: 'select', name: 'color', options: colors, 'width': 200, changeHandler: value => {setColorFilter(value);},
                    value: `${lang.getSelectOption(module, colorFilter)}`
                },
                {component: 'toggle', name: 'products', options: colors, 'width': 200, changeHandler: () => {setShowProducts(!showProducts);}, value: showProducts},
                {component: 'toggle', name: 'label', options: colors, 'width': 150, changeHandler: () => {setShowlabel(!showlabel);}, value: showlabel}
            ]}
        />;
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div >
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='pos-absolute top-px-87'>
                {filterContent}
            </div>
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {content}
            </div>
            <AauMixinFabBar
                back={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                lang={props.lang}
                urlBasePath={`${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                fabs={null}
            />
        </div>
    );
};
AauPageCartoKubeDeployment.defaultProps = defaultProps;
