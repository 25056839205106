/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import Graph from 'react-graph-vis';
import Cookies from 'js-cookie';
import {AauFormSlider} from "../aauFormSlider/aauFormSlider";
import {AauFormBoolean} from "../aauFormBoolean/aauFormBoolean";
import {tokens, webLightTheme, webDarkTheme} from '@fluentui/react-theme';

export interface AauMixinCartoVisProps {
    nodes: object[];
    edges: object[];
    events?: object;
    networkHandler: any;
    springConstant?: number;
    sprintLength?: number;
}

const defaultProps = {
    events: {},
    springConstant: 40,
    sprintLength: 200
} as AauMixinCartoVisProps;

export const AauMixinCartoVis: FC<AauMixinCartoVisProps> = props => {

    const [p, setP] = React.useState(true);
    const [sc, setSc] = React.useState(props.springConstant);
    const [sl, setSl] = React.useState(props.sprintLength);

    const isLogged = typeof (Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null;
    let profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};
    const theme = (profil.hasOwnProperty('darkMode') ? profil['darkMode'] : true) === true ? webDarkTheme : webLightTheme

    return <div
        style={{
            width: '95%',
            height: '95%'
        }}
    >
        <div>
            {/*<AauFormSlider*/}
            {/*    readonly={false}*/}
            {/*    key={'sc'}*/}
            {/*    itemKey={'sc'}*/}
            {/*    name={'sc'}*/}
            {/*    label={'Spring Constant'}*/}
            {/*    value={sc}*/}
            {/*    helper={''}*/}
            {/*    min={1}*/}
            {/*    max={40}*/}
            {/*    parentFunction={(keyName: string, value: any, valid: boolean): void => {*/}
            {/*        console.log(value);*/}
            {/*        setSc(value);*/}
            {/*    }}*/}
            {/*    validator={{}}*/}
            {/*    step={1}*/}
            {/*/>*/}
            <div className={`float-left mr-3 ml-3`}>
                <AauFormBoolean
                    readonly={false}
                    key={'config_physics'}
                    itemKey={'config_physics'}
                    name={'config_physics'}
                    label={'Animation'}
                    value={p}
                    helper={''}
                    parentFunction={(keyName: string, value: any, valid: boolean) => {
                        setP(value);
                    }}
                    validator={{}}
                />
            </div>
            <div className={`float-left mr-1 ml-1 width-px-300`}>
                <AauFormSlider
                    readonly={false}
                    key={'sl'}
                    itemKey={'sl'}
                    name={'sl'}
                    label={'Longeur'}
                    value={sl}
                    helper={''}
                    min={1}
                    max={500}
                    parentFunction={(keyName: string, value: any, valid: boolean): void => {
                        setSl(value);
                        setP(true);
                    }}
                    validator={{}}
                    step={1}
                />
            </div>
        </div>
        <Graph
            id='graph-vis-network'
            key='graph-vis-network'
            graph={{
                edges: props.edges,
                nodes: props.nodes
            }}
            options={{
                'autoResize': true,
                'height': '95%',
                'width': '95%',
                'nodes': {
                    'borderWidth': 0.5,
                    'shadow': {
                        'enabled': true
                    },
                    'shape': 'dot',
                    'size': 13,
                    'font': {
                        'color': `${theme['colorNeutralForeground1']}`
                    }
                },
                'edges': {
                    'color': {
                        'inherit': 'both',
                        'opacity': 0.5
                    },
                    'smooth': {
                        'type': 'cubicBezier',
                        'forceDirection': 'none',
                        'roundness': 0.75
                    }
                },
                'interaction': {
                    'hover': true,
                    'keyboard': {
                        'enabled': false
                    },
                    'navigationButtons': true,
                    'selectable': false,
                    'selectConnectedEdges': false
                },
                'manipulation': {
                    'enabled': true
                },
                'physics': {
                    'enabled': p,
                    'barnesHut': {
                        'gravitationalConstant': -80000,
                        'centralGravity': 0.2,
                        'springConstant': sc/1000,
                        'springLength': sl,
                        'avoidOverlap': 0.8
                    },
                    'maxVelocity': 20,
                    'minVelocity': 0.75
                }
            }}
            events={props.events}
            getNetwork={network => {
                props.networkHandler(network);
             }}
        />
    </div>;
};
AauMixinCartoVis.defaultProps = defaultProps;
