/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import AauToolsLang from '../../Components/aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../../Components/aauMixinPageHeader/aauMixinPageHeader';
import {AauMixinLoader} from '../../Components/aauMixinLoader/aauMixinLoader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {TooltipHost} from '@fluentui/react/lib/Tooltip';

import {AauMixinFilters} from '../../Components/aauMixinFilters/aauMixinFilters';


export interface AauPageKubeViewProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageKubeViewProps;

export const AauPageKubeView: FC<AauPageKubeViewProps> = props => {
    const {lang} = props;
    const [data, setData] = useState(null);
    const [search, setSearch] = useState();
    const [groupBy, setGroupby] = useState('ns');
    const [ws, setWs] = useState(null);

    const module = 'kubepod';
    const action = 'initview';

    if ( ws !== null ) {
        ws.onmessage = event => {
            const d = JSON.parse(event.data);

            if ( d['knowit_module'] === module && d['knowit_action'] === action ) {
                let dataTmp = JSON.parse(JSON.stringify(data));
                dataTmp['info']['data'] = dataTmp['info']['data'].filter(item => item.name !== d['value']['name']);
                if ( d['action'] === 'ADDED' ) {
                    dataTmp['info']['data'].push(d['value']);
                }
                setData(dataTmp);
            }
        };
    }


    const retrieveData = async () => {
        await axios.get(
            `/rest/${module}/${action}/0`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    const podGroupBy = {};
    if ( data !== null && data !== false ) {
        Object(data['info']['data']).forEach(item=>{
            let groupName = groupBy === 'ns' ? item['namespace'] : item['node_name'];

            if ( !podGroupBy.hasOwnProperty(groupName) ) {
                podGroupBy[groupName] = [];
            }
            podGroupBy[groupName].push(item);
        });
        // if ( ws === null ) {
        //     setWs(new WebSocket(config.getGlobal('ws')));
        // }
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <div >
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='pos-absolute top-px-87'>
                <AauMixinFilters
                    lang={lang}
                    filters={[
                        {component: 'search', name: 'nodename', 'width': 200, changeHandler: value => {setSearch(value)}},
                        {component: 'toggle', name: 'kuberneteViewType', 'width': 200, changeHandler: () =>{ setGroupby(groupBy === 'ns' ? 'node' : 'ns');}, value: groupBy}
                    ]}
                />
            </div>
            <div className='p-5 pl-7 pos-absolute width-92 height-vh-90 top-px-150'>
                {
                    data !== null && data !== false ? Object.keys(podGroupBy).sort((first, second) => {
                        let ret = 0;
                        if ( first.toLowerCase() < second.toLowerCase() ) {
                            ret = -1;
                        } else if ( first.toLowerCase() > second.toLowerCase() ) {
                            ret = 1;
                        } else {
                            //NOSONAR
                        }

                        return ret;
                    }).map(groupName => {
                        return <div className='float-clear p-2'>
                            <h2>{groupName} ({podGroupBy[groupName].length} pod)</h2>
                            <div>
                                {
                                    Object(podGroupBy[groupName]).map(item => {

                                        let bgColorLevel = search !== '' && item['name'].indexOf(search) !== -1 ? 'l5' : 'l3';
                                        let bgColor = 'red';
                                        switch (item['ready']) {
                                            case 'Running':
                                                bgColor = 'green';
                                                break;
                                            case 'Pending':
                                                bgColor = 'orange';
                                                break;
                                            default:
                                                bgColor = 'red';
                                        }
                                        let bdColor = search !== '' && item['name'].indexOf(search) !== -1 ? 'black' : bgColor;

                                        return <div
                                            key={item['name']}
                                        >
                                            <TooltipHost
                                                content={item['name']}
                                            >
                                                <div
                                                    className={`d-block width-px-15 height-px-15 bg-${bgColorLevel}-${bgColor} bd-2 bd-l5-${bdColor} float-left ml-1 mb-1`}
                                                />
                                            </TooltipHost>
                                        </div>;
                                    })
                                }
                            </div>
                        </div>;
                    }) : <AauMixinLoader />
                }
            </div>
            <div className='p-4 float-left width-30'>
            </div>
        </div>
    );
};
AauPageKubeView.defaultProps = defaultProps;
