/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';

import {AauComponentTableHeadColumn} from './tableHeadColumn';
import {AauComponentTableColumnProps} from './tableHeadColumn.d';


export interface AauComponentTableHeadRowProps {
    rowId: string;
    showFilter: boolean;
    columns: AauComponentTableColumnProps[];
    parentSetColumns: any;
    sortSetColumn: any;
    lang: AauToolsLang;
    data?: object[];
}

const defaultProps = {
    columns: {},
    data: []
} as AauComponentTableHeadRowProps;

export const AauComponentTableHeadRow: FC<AauComponentTableHeadRowProps> = props => {
    return <tr>
        {
            Object.keys(props.columns).map(column => {
                let ret = null;

                if (props.columns[column]['state'] === true) {
                    ret = <AauComponentTableHeadColumn
                        data={props.data}
                        showFilter={props.showFilter}
                        key={`${props.rowId}_${props.columns[column]['name']}`}
                        name={props.columns[column]['name']}
                        label={props.columns[column]['label']}
                        mixin={props.columns[column]['mixin']}
                        width={props.columns[column]['width']}
                        enums={props.columns[column]['component']['enums']}
                        filter={props.columns[column]['filter']}
                        component={props.columns[column]['component']}
                        primary={props.columns[column]['primary']}
                        state={props.columns[column]['state']}
                        filterValue={props.columns[column]['filterValue']}
                        title={props.columns[column]['title']}
                        setFilters={props.columns[column]['setFilters']}
                        sort={props.columns[column]['sort']}
                        columns={props.columns}
                        parentSetColumns={props.parentSetColumns}
                        sortSetColumn={props.sortSetColumn}
                        lang={props.lang}
                    />;
                }

                return ret;
            })
        }
    </tr>;
};
AauComponentTableHeadRow.defaultProps = defaultProps;
