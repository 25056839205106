/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import { Label } from "@fluentui/react-components";

export interface AauFormLabelProps {
    name: string;
    value: string;
    required: boolean;
}

const defaultProps = {
    value: null,
} as AauFormLabelProps;

export const AauFormLabel: FC<AauFormLabelProps> = props => {
    return <Label
        id={`${props.name}_label`}
        key={`${props.name}_label`}
        weight={"semibold"}
        required={props.required}
    >
        {props.value}
    </Label>;
};
AauFormLabel.defaultProps = defaultProps;
