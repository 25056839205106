/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {tokens} from '@fluentui/react-theme';
import {Caption2, Button} from '@fluentui/react-components';
import {Folder20Regular, FolderOpen20Regular, FolderSync20Regular, Document20Regular} from '@fluentui/react-icons';


export interface AauMixinTreeViewProps {
    data?: object;
    level?: number;
    closeAfterLevel?: number;
    size ?: 'small' | 'medium' | 'large';
}

const defaultProps = {
    data: [],
    level: 1,
    size: 'medium',
    closeAfterLevel: 2
} as AauMixinTreeViewProps;

export const AauMixinTreeView: FC<AauMixinTreeViewProps> = props =>  {

    const [collapsed, setCollapsed] = React.useState(props.level> props.closeAfterLevel);

    let content = <li
        style={{}}
    >
        <Button
            appearance={`subtle`}
            icon={props.data.hasOwnProperty('child') ? (props.data['child'].length > 0 ? (collapsed ? <Folder20Regular /> : <FolderOpen20Regular />) : <FolderSync20Regular style={{color: tokens.colorPaletteRedBorderActive}} />) : <Document20Regular />}
            onClick={() => setCollapsed(!collapsed)}
        >
            {props.data.hasOwnProperty('kind') ? `[${props.data['kind']}]`: null} {props.data['name']} {props.data.hasOwnProperty('id') ? `- ID: ${props.data['id']}`: null}
            {
                props.data.hasOwnProperty('child') ? (
                    props.data['child'].length > 0
                    ? <Caption2 style={{paddingLeft: 10}}>({props.data['child'].length} élémenents)</Caption2>
                    : <Caption2 style={{paddingLeft: 10, color: tokens.colorPaletteRedBorderActive}}>(0 élémenent)</Caption2>
                )
                : null
            }
        </Button>
        {
            !collapsed && props.data.hasOwnProperty('child') && props.data['child'].length > 0 ? <ul
                style={{
                    listStyleType: 'none',
                    marginBottom: props.size === 'small' ? 5 : props.size === 'medium' ? 10 : 15,
                    marginTop: props.size === 'small' ? 5 : props.size === 'medium' ? 10 : 15,
                }}
            >
                {
                    Object.keys(props.data['child']).map(column => {
                        return <AauMixinTreeView
                            data={props.data['child'][column]}
                            level={props.level + 1}
                            closeAfterLevel={props.closeAfterLevel}
                        />
                    })
                }
            </ul> : null
        }
    </li>;

    return props.level === 1
        ? <ul style={{listStyleType: 'none'}}>{content}</ul>
        : content;

};
AauMixinTreeView.defaultProps = defaultProps;