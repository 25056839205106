/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC, useState} from 'react';

import Cookies from 'js-cookie';
import ReactDiffViewer, {DiffMethod} from '@terran-one/react-diff-viewer';
import {
    Toolbar, ToggleButton
} from "@fluentui/react-components";
import {CheckboxChecked24Regular, CheckboxUnchecked24Regular} from "@fluentui/react-icons";


export interface AauFormFieldProps {
    oldValue: string;
    newValue: string;
    leftTitle: string;
    rightTitle: string
}

const defaultProps = {
} as AauFormFieldProps;

export const AauMixinDiffFile: FC<AauFormFieldProps> = props => {
    const [splitView, setSplitView] = useState(true);
    const [diffOnly, setDiffOnly] = useState(false);

    const isLogged = typeof (Cookies.get('JWT')) !== 'undefined' && localStorage.getItem(Cookies.get('user')) !== null;
    let profil = isLogged ? JSON.parse(localStorage.getItem(Cookies.get('user'))) : {};

    return <>
        <Toolbar>
            <ToggleButton
                checked={splitView}
                appearance={`subtle`}
                icon={splitView ? <CheckboxChecked24Regular /> : <CheckboxUnchecked24Regular />}
                onClick={() => setSplitView(!splitView)}
            >
                Split View
            </ToggleButton>
            <ToggleButton
                checked={diffOnly}
                icon={diffOnly ? <CheckboxChecked24Regular /> : <CheckboxUnchecked24Regular />}
                appearance={`subtle`}
                onClick={() => setDiffOnly(!diffOnly)}
            >
                Only Diff
            </ToggleButton>
        </Toolbar>
        <ReactDiffViewer
            oldValue={props.oldValue.replaceAll("\\\n    ", "").replaceAll('\\n', '\n').replaceAll('\\"', '\"')}
            newValue={props.newValue.replaceAll("\\\n    ", "").replaceAll('\\n', '\n').replaceAll('\\"', '\"')}
            splitView={splitView}
            showDiffOnly={diffOnly}
            compareMethod={DiffMethod.WORDS}
            extraLinesSurroundingDiff={0}
            leftTitle={splitView ? props.leftTitle : null}
            rightTitle={splitView ? props.rightTitle : null}
            useDarkTheme={profil.hasOwnProperty('darkMode') ? profil['darkMode'] : localStorage.getItem('dark_mode') === 'true'}
        />
    </>;
};
AauMixinDiffFile.defaultProps = defaultProps;
