/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import {AauMixinPageHeader} from '../aauMixinPageHeader/aauMixinPageHeader';
import axios from 'axios';
import Cookies from 'js-cookie';
import {AauMixinFabBar} from "../aauMixinFabBar/aauMixinFabBar";
import {AauMixinDiffFile} from "../aauMixinDiffFile/aauMixinDiffFile";


export interface AauPageRecordHistoryDetailProps {
    lang: AauToolsLang;
}

const defaultProps = {
} as AauPageRecordHistoryDetailProps;

export const AauPageRecordHistoryDetail: FC<AauPageRecordHistoryDetailProps> = props => {
    const {module, identifier, admin} = useParams();
    const [data, setData] = useState(null);

    const retrieveData = async () => {
        await axios.get(
            `/rest/${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}/view/${identifier}`,
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT '.concat(Cookies.get('JWT'))
                }
            }
        ).then(res => {
            setData(res.data);
        }).catch(err => {
            const unauthorizedError = 401;
            if ( err.response.status === unauthorizedError ) {
                Cookies.remove('JWT');
                Cookies.remove('user');
                Cookies.remove('rank');
                Cookies.remove('displayname');
                window.location.href = `/login?redirect=${window.location.pathname}`;
            }
        });
    };

    if ( data !== null && data !== false ) {
    } else if ( data !== false ) {
        setData(false);
        retrieveData();
    } else {
        //NOSONAR
    }

    return (
        <>
            <AauMixinPageHeader
                pageTitle={data !== null && data !== false ? data['page_title'] : null}
                pageHelp={data !== null && data !== false ? data['page_help'] : null}
                menuBadgeText={data !== null && data !== false ? data['menu_badge_text'] : null}
                menuBadgeState={data !== null && data !== false ? data['menu_badge_state'] : null}
            />
            <div className='mt-23 p-2 pl-2 pr-15'>
                {
                    data !== null && data !== false ? <AauMixinDiffFile
                        oldValue={data['info']['codes'][0]['code']}
                        newValue={data['info']['codes'][1]['code']}
                        leftTitle={data['info']['codes'][0]['name']}
                        rightTitle={data['info']['codes'][1]['name']}
                    /> : null
                }
            </div>
            <AauMixinFabBar
                back={`/${typeof (admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                lang={props.lang}
                urlBasePath={`${typeof(admin) !== 'undefined' ? 'admin/' : ''}${module}`}
                fabs={{
                    view: {
                        enabled: data !== null && data !== false && data['can_view'] && window.location.pathname.indexOf('/edit/') !== -1,
                        confirm: false,
                        label: props.lang.getText('view'),
                        component: {
                            iconazure: 'View',
                            url: `/view/${identifier}`
                        },
                        mixin: 'btn_link_page_id'
                    }
                }}
            />
        </>
    );
};
AauPageRecordHistoryDetail.defaultProps = defaultProps;
