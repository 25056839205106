/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useEffect, useState, FC} from 'react';

import AauToolsLang from '../aauToolsLang/aauToolsLang';
import axios from 'axios';
import Cookies from 'js-cookie';
import {Helmet} from 'react-helmet-async';
import {AauComponentLogin} from '../aauComponentLogin/aauComponentLogin';
import {toast} from 'react-toastify';
import qs from "qs";
import { AauMixinLoader } from '../aauMixinLoader/aauMixinLoader';


export interface AauPageLoginProps {
    instance: any;
    login_action: boolean;
    microsoft: boolean;
    lang: AauToolsLang;
}

const defaultProps = {
    microsoft: false
} as AauPageLoginProps;

export const AauPageLogin: FC<AauPageLoginProps> = props => {
    useEffect(
        () => {
            const timer = setTimeout(() => ssoMicrosoftLogin(), 500);
        },
        [props.microsoft]
    );    
    
    // console.log("props.microsoft", props.microsoft)

    const updateProfil = (res) => {
        const user = res.data['login'];

        Cookies.set('JWT', res.data['token']);
        Cookies.set('user', user);
        Cookies.set('rank', res.data['rank']);
        Cookies.set('displayname', res.data['displayname']);

        let profil = null;
        if (localStorage.getItem(user) === null) {
            profil = {};
        } else {
            profil = JSON.parse(localStorage.getItem(user));
        }
        if ( profil.hasOwnProperty('favorites') !== true  ) {
            profil['favorites'] = {};
        }
        if ( profil.hasOwnProperty('filters') !== true ) {
            profil['filters'] = {};
        }
        if ( profil.hasOwnProperty('darkMode') !== true ) {
            profil['darkMode'] = true;
        }
        localStorage.setItem(user, JSON.stringify(profil));

        var qs = require('qs'); //SONAR
        const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        let ret = '/';
        if ( params.hasOwnProperty('redirect') ) {
            ret = params.redirect;
        }

        window.location.href = ret;
    }

    const processLogin = async dataLogin => {
        await axios.post(
            `/auth`,
            JSON.stringify(dataLogin),
            {
                baseURL: `${window.knowit.BACKURL}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(res => {
            updateProfil(res);
        }).catch(err => {
            toast.error(err.response.data.msg);
        });
    };
    
    const ssoMicrosoftLogin = async () => {
        const account = JSON.parse(sessionStorage.getItem('msal.account.keys'));

        if ( account !== null ) {
            await axios.post(
                `/auth/sso/microsoft`,
                sessionStorage.getItem(account[0]),
                {
                    baseURL: `${window.knowit.BACKURL}`,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then(res => {
                updateProfil(res);
            }).catch(err => {
                toast.error(err.response.data.msg);
            });
        }
    };

    if ( props.login_action === false ) {
        Cookies.remove('JWT');
        Cookies.remove('user');
        Cookies.remove('rank');
        Cookies.remove('displayname');
        sessionStorage.clear();
        window.location.href = '/login';
    }

    return (
        <div>
            <Helmet>
                <title>{window.knowit.SITENAME}</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
            {
                props.microsoft === false
                && <AauComponentLogin
                    lang={props.lang}
                    processLogin={processLogin}
                />  || <AauMixinLoader />
            }
        </div>
    );
};
AauPageLogin.defaultProps = defaultProps;