/*
 * KnowIT Front is a the web front design to be on top of KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {FC} from 'react';

import {Toolbar, Caption1, Button, tokens, Body1, Card, CardPreview, CardHeader} from "@fluentui/react-components";
import {AauMixinIcon} from "../aauMixinIcon/aauMixinIcon";

export interface AauPageDaahboardCardProps {
    id: string,
    data: any[],
    icon: any,
    title: string,
    description?: string,
    iconColor?: string,
    width?: number,
    height?: number,
    mixin?: string
}

const defaultProps = {
    mixin: 'static',
    description: null,
    width: 3,
    height: 3,
    iconColor: tokens.colorBrandBackground
} as AauPageDaahboardCardProps;


export const AauPageDaahboardCard: FC<AauPageDaahboardCardProps> = props => {

    return props.data !== null && Object.keys(props.data).length < 1 ? null : <Card
        key={`${props.id}_card`}
        className={'grid-item'}
        style={{
            // width: `calc(${100/12 * props.width}% - (8*2)*1px)`,
            margin: '8px',
            float: 'left',
            backgroundColor: tokens.colorNeutralBackground3Pressed
        }}
    >
        <CardHeader
            image={<AauMixinIcon icon={props.icon} source={`microsoft`} />}
            // header={
            //     <Body1>
            //         {props.title}
            //     </Body1>
            // }
            description={
                <Body1>
                    {props.title}
                    <br/>
                    <Caption1>{props.description}</Caption1>
                </Body1>
            }
        />  

        <CardPreview>
            <Toolbar className={`mt-0`} vertical={true}>
                {
                    Object.keys(props.data).map((key, index) => {
                        return <Button
                            key={`${props.id}_card_button_${index}`}
                            as={props.data[key] !== '' ? 'a' : 'button'}
                            style={{
                                width: `calc(100% - 16px - (4*2)*1px)`,
                                margin: '4px',
                                cursor: props.data[key] !== '' ? 'pointer' : 'default'
                            }}
                            href={props.data[key] !== '' ? props.data[key] : '#'}
                        >
                            {key}
                        </Button>
                    })
                }
            </Toolbar>
        </CardPreview>
    </Card>;
};
AauPageDaahboardCard.defaultProps = defaultProps;
