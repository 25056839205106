
/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

//TODO (vincent.candeau): Manage List key item

import React, {FC, useState} from 'react';

import {AauComponentForm} from '../aauComponentForm/aauComponentForm';
import AauToolsLang from '../aauToolsLang/aauToolsLang';
import format from 'string-format';

import {Tooltip, Body1} from '@fluentui/react-components';
import {Card, Toolbar, ToolbarButton, CardPreview, CardHeader} from '@fluentui/react-components';
import {ArrowMove24Regular, CheckboxChecked24Regular, CheckboxUnchecked24Regular, CopyAdd24Regular,
        Delete24Regular} from "@fluentui/react-icons";
import {tokens} from "@fluentui/react-theme";

import {Reorder, useDragControls} from "framer-motion"


export interface AauDynamicAccordionItemProps {
    key:string;
    dynamicKey: string;
    readonly: boolean;
    columns: object[];
    value: object[];
    substate: boolean;
    index: string;
    // expanded: boolean;
    isValid: boolean;
    subStateClick: (index:string) => void;
    subCopyClick: (index:string, item:object) => void;
    subDeleteClick: (index:string) => void;
    // subExpandedClick: (index:string) => void;
    parentFunction: (index: number, item: object, valid: boolean) => void;
    itemsCount: number;
    titleStr?:  string;
    lang: AauToolsLang;
    reorderSave: () => void;
    template?: object;
    templateVersion?: number;
}

const defaultProps = {
    readonly: false,
    columns: [],
    value: [],
    isValid: false,
    substate: false,
    subStateClick: null,
    subCopyClick: null,
    subDeleteClick: null,
    parentFunction: null,
    itemsCount: 0,
    titleStr: '',
    template: null,
    templateVersion: 1
} as AauDynamicAccordionItemProps;

export const AauDynamicAccordionItem: FC<AauDynamicAccordionItemProps> = props => {
    const [state] = useState<object>({content: props.value});
    const [expanded, setExpanded] = useState<boolean>(false);
    const controls = useDragControls()

    const subSetData = (indexTmp: number,  key: string, valueTmp: any, valid: boolean): void => {
        const stateTmp = JSON.parse(JSON.stringify(state['content']));
        stateTmp[key] = valueTmp;

        if ( props.parentFunction !== null ) {
            props.parentFunction(indexTmp, stateTmp, valid);
        }
    };

    return <Reorder.Item
        key={Number(props.index)}
        value={Number(props.index)}
        dragListener={false}
        dragControls={controls}
    >
        <Card
            style={{
                padding: '2px',
                marginBottom: '8px',
                width: '100%'
            }}
        >
            <CardHeader
                style={{
                    display: 'flex-root',
                    overflow: 'hidden'
                }}
                description={
                    <Toolbar
                        size={'small'}
                        aria-label="Subform toolbar"
                        className={`z-200`}
                        style={{
                            display: 'block'
                        }}
                    >
                        {
                            props.readonly ? null : <ToolbarButton
                                key={`${props.dynamicKey}-mover`}
                                disabledFocusable={props.readonly}
                                icon={<ArrowMove24Regular />}
                                style={{
                                    cursor: 'move'
                                }}
                                onPointerDown={(e) => {
                                    controls.start(e)
                                }}
                                onPointerUp={(e) => {
                                    props.reorderSave();
                                }}
                            />
                        }
                        {
                            props.substate && <Tooltip content={props.lang.getBtn(`subform_${props.substate === true && state['content']['props.substate'] === 1 ? 'disabled' : 'enabled'}`)} relationship="description" withArrow>
                                <ToolbarButton
                                    key={`${props.dynamicKey}-btn-state`}
                                    disabledFocusable={props.readonly}
                                    icon={props.substate === true && (state['content']['substate'] === 1 || state['content']['substate'] === true) ? <CheckboxChecked24Regular /> : <CheckboxUnchecked24Regular />}
                                    className={`mt-1`}
                                    style={{backgroundColor: (props.substate === true && (state['content']['substate'] === 1 || state['content']['substate'] === true)) ? tokens.colorPaletteGreenBorderActive : tokens.colorPaletteRedBorderActive, color: tokens.colorNeutralForegroundInverted}}
                                    onClick={props.readonly === false ? () => {
                                        props.subStateClick(props.index);
                                    } : null}
                                />
                            </Tooltip>
                        }
                        <Tooltip content={props.lang.getBtn('subform_duplicate')} relationship="description" withArrow>
                            <ToolbarButton
                                key={`${props.dynamicKey}-btn-copy`}
                                disabledFocusable={props.readonly}
                                icon={<CopyAdd24Regular />}
                                onClick={() => {
                                    props.subCopyClick(props.index, {...state});
                                }}
                            />
                        </Tooltip>
                        <Tooltip content={props.lang.getBtn('subform_delete')} relationship="description" withArrow>
                            <ToolbarButton
                                key={`${props.dynamicKey}-btn-delete`}
                                disabledFocusable={props.readonly}
                                icon={<Delete24Regular />}
                                onClick={() => {
                                    props.subDeleteClick(props.index);
                                }}
                            />
                        </Tooltip>
                        <Tooltip content={format(props.titleStr, state['content'])} relationship="description" withArrow>
                            <ToolbarButton
                                style={{
                                    alignItems: 'left',
                                    justifyContent: 'left',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    width: `calc(100% - (32+8)*4px)`,
                                    cursor: !expanded ? 'zoom-out' : 'zoom-in'
                                }}
                                onClick={() => {
                                    setExpanded(!expanded)
                                }}
                            >
                                <Body1
                                    truncate={true}
                                    wrap={false}
                                    className={`text-left`}
                                >
                                    {format(props.titleStr, state['content'])}
                                    {props.isValid ? null : <span className='ml-3 fg-l4-red text-bold'>Erreur</span>}
                                </Body1>
                            </ToolbarButton>
                        </Tooltip>
                    </Toolbar>
                }
            />
            {
                expanded && <CardPreview
                    style={{padding: '10px'}}
                >
                    <AauComponentForm
                        key={`${props.dynamicKey}-form`}
                        columns={props.columns}
                        templateVersion={props.templateVersion}
                        template={props.template}
                        className={`${expanded ? 'fit-content width-90 op-100 z-100' : 'height-px-0 op-0 z-10'}`}
                        item={state['content']}
                        mainForm={false}
                        readonly={props.readonly}
                        dynamicKey={props.dynamicKey}
                        parentFunction={subSetData}
                        lang={props.lang}
                    />
                </CardPreview>
            }
        </Card>
    </Reorder.Item>
};
AauDynamicAccordionItem.defaultProps = defaultProps;