/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {Input} from "@fluentui/react-components";
import {AauFormField} from "../aauFormField/aauFormField";


export interface AauFormInputProps {
    label?: string;
    readonly?: boolean;
    itemKey: string;
    name: string;
    value?: string | number;
    parentFunction?: any;
    prefix?: any;
    suffix?: any;
    isInteger?: boolean;
    required?: boolean | null;
    pattern?: string | null;
    min?: number | null;
    max?: number | null;
    type?: "number" | "search" | "text" | "time" | "tel" | "url" | "email" | "date" | "datetime-local" | "month" | "password" | "week";
    className?: string;
    style?: object;
    keyPressHandler?: (e) => void;
    hint?: string;
}

const defaultProps = {
    label: null,
    readonly: false,
    parentFunction: null,
    value: '',
    prefix: null,
    suffix: null,
    isInteger: false,
    required: false,
    pattern: null,
    min: null,
    max: null,
    type: 'text',
    className: '',
    style: {},
    keyPressHandler: null,
    hint: null
} as AauFormInputProps;

export const AauFormInput: FC<AauFormInputProps> = props => {
    const validateField = (validateValue):void => {
        let isValid = true;

        if (props.required === true && (validateValue === '' || validateValue === null)) {
            isValid = false;
        }

        if ( props.pattern !== null && (validateValue !== null && validateValue !== '') ) {
            let reg = props.pattern;
            if ( reg === 'url' ) {
                // Line by Line : Protocol, Domaine Name, or IPV4 addresse, Port and Path, query string
                reg = '^(http[s]{0,1}?:\\/\\/)?'+
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+
                    '(\\#[-a-z\\d_]*)?$';
            } else if ( reg === 'ipv4' ) {
                reg = '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';
            } else if ( reg === 'ipv4-cidr' ) {
                reg = '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\\/(?:[0-9]|[0-9][0-9]:?)?){0,1}$';
            } else if ( reg === 'cidr' ) {
                reg = '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\\/(?:[0-9]|[0-9][0-9]:?)?){1}$';
            } else if ( reg === 'ipv6' ) {
                reg = '^(([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))$';
            } else {
                //NOSONAR
            }

            isValid = RegExp(reg, 'g').test(validateValue);
        }

        if ( props.min !== null && validateValue < props.min ) {
            isValid = false;
        }

        if ( props.max !== null && validateValue > props.max ) {
            isValid = false;
        }

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( props.parentFunction !== null ) {
                props.parentFunction(props.itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                'content': validateValue
            });
        }
    };

    const [state, setState] = useState<object>({
        'isValid': null,
        'content': props.isInteger === true ? Number(props.value) : props.value
    });
    if (state['isValid'] === null && props.readonly === false ) {
        validateField((props.isInteger === true ? Number(props.value) : props.value));
    }

    return <AauFormField
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}
        hint={props.hint}
        content={
            props.readonly === true
            ? <div>{state['content']}</div>
            : <Input
                id={props.itemKey}
                key={`${props.itemKey}-ctrl`}
                name={props.name}
                aria-labelledby={props.name}
                appearance={`underline`}
                value={state['content'] === null ? '' : state['content']}
                contentBefore={props.prefix}
                onChange={(e, data) => validateField((props.isInteger === true ? Number(data['value']) : data['value']))}
                contentAfter={props.suffix}
                type={props.type}
                size={`medium`}
                className={props.className}
                style={props.style}
                onKeyPress={props.keyPressHandler}
            />
        }
    />;
};
AauFormInput.defaultProps = defaultProps;
