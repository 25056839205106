/*
 * KnowIT Component Library is a library design to be use with KnowIT Generator.
 * Copyright (C) 2019-2021 Ask And Use (Vincent CANDEAU)
 * mailto:vcandeau AT askanduse DOT com
 *
 * This software is under commercial Licenced
 * You not able to use it, reproduce it, modify it without any agreemened of Ask And Use (AAU)
 */

import React, {useState, FC} from 'react';

import {Switch} from "@fluentui/react-components";
import {AauFormField} from "../aauFormField/aauFormField";

export interface AauFormBooleanProps {
    label?: string;
    readonly: boolean;
    key: string;
    itemKey: string;
    name: string;
    value?: true | false | 1 | 0;
    validator?: object;
    parentFunction?: any;
    helper?: string | null;
    required?: boolean | null;
    hint?: string;
}

const defaultProps = {
    label: null,
    readonly: false,
    value: true,
    validator: {
        'required': true
    },
    helper: null,
    parentFunction: null,
    required: false,
    hint: null
} as AauFormBooleanProps;

export const AauFormBoolean: FC<AauFormBooleanProps> = props => {
    const validateField = (validateValue):void => {
        const isValid = true;

        if ( state['isValid'] !== isValid || state['content'] !== validateValue ) {
            if ( props.parentFunction !== null ) {
                props.parentFunction(props.itemKey, validateValue, isValid);
            }
            setState({
                isValid,
                content: validateValue
            });
        }
    };

    const valDict = {
        'int': {
            'false': 0,
            'true': 1
        },
        'bool': {
            'false': false,
            'true': true
        }
    };

    const valType = typeof(props.value) === 'number' ? 'int' : 'bool';

    // const labelTrue = lang.getBool(name, 'true');
    // const labelFalse = lang.getBool(name, 'false');

    const [state, setState] = useState<object>({
        isValid: true,
        content: props.value
    });
    if (state['isValid'] === null && props.readonly === false ) {
        validateField(props.value);
    }

    return <AauFormField
        label={props.label === null ? props.name : props.label}
        key={`${props.itemKey}-field`}
        isValid={state['isValid']}
        readonly={props.readonly}
        required={props.required}
        hint={props.hint}
        content={<Switch
            key={`${props.itemKey}-ctrl`}
            checked={state['content'] === 1 || state['content'] === true}
            // label={state['content'] === 1 || state['content'] === true ? labelTrue : labelFalse}
            onChange={(ev, data) => {
                validateField(state['content'] === valDict[valType]['false'] ? valDict[valType]['true'] : valDict[valType]['false']);
            }}
        />}
    />;
};
AauFormBoolean.defaultProps = defaultProps;
